import * as Yup from 'yup';
import moment from 'moment';

export const initialValues = {
  title: "",
  description: "",
  terms: "",
  availableFor: "",
  applicableTags: [],
  services:[],
  totalDeals: "",
  price: "",
  taxes: [],
  publishOn: "",
  expireOn: "",
  validity: "",
  specificDay:false,
  specificDays:[],
  specificTime:false,
  startTime:"00:00",
  endTime:"00:00",
  limitPurchase:false,
  limitOfPurchase:"",
  isEmpty:"hey",
  salonStartTime:"00:00",
  salonEndTime:"00:00"

}

export const validationSchema = Yup.object({
  isEmpty:Yup.string()
  .required("All fields must be filled"),
  title: Yup.string()
    .max(40, 'Must be 40 characters or less')
    .required('Required'),
  description: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  terms: Yup.string()
    .min(10, 'Must be 10 characters or more')
    .max(50, "Must be 50 characters or less")
    .required('Required'),
  availableFor: Yup.string()
    .required('Required'),
  applicableTags: Yup.array()
    .required('Required'),
  totalDeals: Yup.number()
    .required('Required'),
  price: Yup.number()
  .positive("Must be positive")
    .required('Required'),
  publishOn: Yup.string()
    .required('Required'),
  expireOn: Yup.string()
    .required('Required')
    .test(
      "greater than publish on",
      "Must be after Publish on",
      function() {
        const { expireOn, publishOn } = this.parent;
        return moment(expireOn).isAfter(moment(publishOn));
      }
    ),
  validity: Yup.number()
    .required('Required'),
  startTime:Yup.string()
  .test(
    "multiple of 5",
    "Must be in multiples of 5",
    function() {
      const { startTime,specificTime} = this.parent;
      if(specificTime && startTime ){
        return parseInt(startTime.slice(3,5)) % 5 === 0
      }
      return true
    }
  )
  .test(
    "is-greater",
    "start time must be greater than saloon opening time",
    function() {
      const { startTime, salonStartTime,specificTime } = this.parent;
      if(!startTime || !specificTime){
        return true
      }
      const hrsGreater = (parseInt(startTime.slice(0,2)) > parseInt(salonStartTime.slice(0,2)));
      const hrsEqual = (parseInt(startTime.slice(0,2)) === parseInt(salonStartTime.slice(0,2)));
      const minsGreater = (parseInt(startTime.slice(3,5)) >= parseInt(salonStartTime.slice(3,5)));
      return (hrsGreater || (hrsEqual && minsGreater) );
    }
  ),
  endTime:Yup.string()
  .test(
    "multiple of 5",
    "Must be in multiples of 5",
    function() {
      const { endTime,specificTime} = this.parent;
      if(specificTime && endTime){
        return parseInt(endTime.slice(3,5)) % 5 === 0
      }
      return true
    }
  )
  .test(
    "is-greater-start",
    "end time must be greater than start time",
    function() {
      const { endTime, startTime,specificTime } = this.parent;

      if(!specificTime){
        return true
      }
      const hrsGreater = (parseInt(endTime.slice(0,2)) > parseInt(startTime.slice(0,2)));
      const hrsEqual = (parseInt(endTime.slice(0,2)) === parseInt(startTime.slice(0,2)));
      const minsGreater = (parseInt(endTime.slice(3,5)) > parseInt(startTime.slice(3,5)));
      return (hrsGreater || (hrsEqual && minsGreater) );
    }
  )
  .test(
    "is-lesser",
    "end time must be smaller than saloon closing time",
    function() {
      const { endTime, salonEndTime,specificTime } = this.parent;
      if(!specificTime){
        return true
      }
      const hrsGreater = (parseInt(salonEndTime.slice(0,2)) > parseInt(endTime.slice(0,2)));
      const hrsEqual = (parseInt(salonEndTime.slice(0,2)) === parseInt(endTime.slice(0,2)));
      const minsGreater = (parseInt(salonEndTime.slice(3,5)) >= parseInt(endTime.slice(3,5)));
      return (hrsGreater || (hrsEqual && minsGreater) );
    }
  ),

  

})