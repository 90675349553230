import { nameFormatter, typeFormatter, statusFormatter, actionFormatter,emailFormatter,sortCaret } from './helpers/formatters';


export const columns = [
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: sortCaret,
    formatter: nameFormatter,
    headerAlign: "center",
    headerClasses: () => "UserListing-name-width"
  },
  {
    dataField: "phone",
    text: "Phone",
    headerClasses: () => "UserListing-phone-width"
  },
  {
    dataField: "email",
    text: "Email",
    formatter: emailFormatter,
    headerClasses: () => "UserListing-email-width"
  },
  {
    dataField: "appInstalled",
    text: "App Installed",
    formatter: statusFormatter,
  },
  {
    dataField:"customerSince",
    text:"Customer Since"
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: actionFormatter,
  },
];