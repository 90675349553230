import * as Yup from 'yup';


export const wizardStepsData = [
  { number: 1, title: "Personal Details", subTitle: "Create Custom Account", },
  { number: 2, title: "Shift Details", subTitle: "Create Custom Account" },
  { number: 3, title: "Services", subTitle: "Create Custom Account" },
  { number: 4, title: "Permissions", subTitle: "Create Custom Account" },
];
export const days = [
  { value: "MON", label: "Monday" },
  { value: "TUE", label: "Tuesday" },
  { value: "WED", label: "Wednesday" },
  { value: "THU", label: "Thursday" },
  { value: "FRI", label: "Friday" },
  { value: "SAT", label: "Saturday" },
  { value: "SUN", label: "Sunday" },
];


export const initialServices = [
  {
    type: "Hair Styling",
    checked: false,
    services: [
      { name: "Hair Cu (Men)", checked: true },
      { name: "Hair Ct (Men)", checked: false },
      { name: "Hair ut (Men)", checked: false },
      { name: "Hai Cut (Men)", checked: false },
      { name: "Hr Cut (Men)", checked: false },
      { name: "air Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "r Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut ", checked: false },
    ],
  },
  {
    type: "Spa",
    checked: false,
    services: [
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
    ],
  },
  {
    type: "Facial",
    checked: false,
    services: [
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
    ],
  },
  {
    type: "Makeup",
    checked: false,
    services: [
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
      { name: "Hair Cut (Men)", checked: false },
    ],
  },
];


export const validate1 = Yup.object({
  fname: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  lname: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  phone: Yup.string()
  .matches(/^[0-9]{10}$/, "Must be 10 digit number")
  .required("Must be 10 digit number"),
  gender: Yup.string()
    .required('Required'),
  empstartdate: Yup.string()
    .required('Required')
});



export const validate2 = Yup.object({
  category: Yup.string()
    .required('Required'),
  rank: Yup.string()
    .required('Required'),
  shiftstarttime: Yup.string()
  .required("Required")
  .test(
    "multiple of 5",
    "Must be in multiples of 5",
    function() {
      const { shiftstarttime} = this.parent;
      return parseInt(shiftstarttime.slice(3,5)) % 5 === 0
    }
  )
  .test(
    "is-greater",
    "shift start time must be greater than saloon opening time",
    function() {
      const { shiftstarttime, saloonstarttime } = this.parent;
      const hrsGreater = (parseInt(shiftstarttime.slice(0,2)) > parseInt(saloonstarttime.slice(0,2)));
      const hrsEqual = (parseInt(shiftstarttime.slice(0,2)) === parseInt(saloonstarttime.slice(0,2)));
      const minsGreater = (parseInt(shiftstarttime.slice(3,5)) >= parseInt(saloonstarttime.slice(3,5)));
      return (hrsGreater || (hrsEqual && minsGreater) );
    }
  ),
  shiftendtime: Yup.string()
  .required("Required")
  .test(
    "multiple of 5",
    "Must be in multiples of 5",
    function() {
      const { shiftendtime} = this.parent;
      return parseInt(shiftendtime.slice(3,5)) % 5 === 0
    }
  )
  .test(
    "is-greater-start",
    "shift end time must be greater than shift start time",
    function() {
      const { shiftendtime, shiftstarttime } = this.parent;
      const hrsGreater = (parseInt(shiftendtime.slice(0,2)) > parseInt(shiftstarttime.slice(0,2)));
      const hrsEqual = (parseInt(shiftendtime.slice(0,2)) === parseInt(shiftstarttime.slice(0,2)));
      const minsGreater = (parseInt(shiftendtime.slice(3,5)) > parseInt(shiftstarttime.slice(3,5)));
      return (hrsGreater || (hrsEqual && minsGreater) );
    }
  )
  .test(
    "is-lesser",
    "shift end time must be smaller than saloon closing time",
    function() {
      const { shiftendtime, saloonendtime } = this.parent;
      const hrsGreater = (parseInt(saloonendtime.slice(0,2)) > parseInt(shiftendtime.slice(0,2)));
      const hrsEqual = (parseInt(saloonendtime.slice(0,2)) === parseInt(shiftendtime.slice(0,2)));
      const minsGreater = (parseInt(saloonendtime.slice(3,5)) >= parseInt(shiftendtime.slice(3,5)));
      return (hrsGreater || (hrsEqual && minsGreater) );
    }
  ),
  workingdays: Yup.array()
    .required("Please select atleast one day"),
});


export const validate4 = Yup.object({
  productcommission: Yup.number()
    .typeError("That doesn't look like a number"),
  servicescommission: Yup.number()
    .typeError("That doesn't look like a number"),
  dealscommission: Yup.number()
    .typeError("That doesn't look like a number")

});





// export const initialFormState = {
//   fname: "",
//   lname: "",
//   phone: "",
//   email: "",
//   gender:"",
//   empstartdate:"",
//   empenddate:"",
//   currentlyworking:false,
//   category:"",
//   rank:"",
//   shiftstarttime:"",
//   shiftyendtime:"",
//   monday:false,
//   tuesday:false,
//   wednesday:false,
//   thursday:false,
//   friday:false,
//   saturday:false,
//   sunday:false,
//   deals:"",
//   address1: "",
//   address2: "",
//   postcode: "",
//   city: "",
//   state: "",
//   country: "",
//   ccname: "",
//   ccnumber: "",
//   ccmonth: "",
//   ccyear: "",
//   cccvv: "",
// };