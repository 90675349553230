import React, { useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

const StateFilter = ( { handleFilter,options } ) => {
  const [type, setType] = useState("ALL")

  const handleType = (state) => {
    setType(state)
    handleFilter("state",state);
  }

  return (
    <div className="ST-StateFilter ml-4">
      <DropdownButton variant="outline-secondary" id="dropdown-basic-button" title={type}>
        {options.map(state =>
          <Dropdown.Item key={state} onClick={() => handleType(state)}>{state}</Dropdown.Item>
        )}
      </DropdownButton>
    </div>
  )
}

export default StateFilter;