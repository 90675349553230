import React from 'react';
import './QuickStart.css';

import Paper from '@material-ui/core/Paper';

const QuickStart = (props) => {
  return (
    <Paper className="QuickStart">
      <div className="QuickStart-header">
        <h2 className="QuickStart-heading" >
          gobony
          <br />
          Quickstart
        </h2>
      </div>

      <div className="QuickStart-body p-5">

        <div className="QuickStart-list">
          <Paper className="p-3 mt-3">
            Saloon created
          </Paper>
          <Paper className="p-3 mt-3">
            Saloon created
          </Paper>
          <Paper className="p-3 mt-3">
            Saloon created
          </Paper>
          <Paper className="p-3 mt-3">
            Saloon created
          </Paper>
        </div>

        <p className="QuickStart-cancel pb-6 pb-md-0"
          onClick={() => props.setIsQuickStart(false)}>
          Cancel Quickstart
        </p>

      </div>
    </Paper>
  )
}

export default QuickStart;