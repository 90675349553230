export const data1 = {
  "Landscape":[
  {url:"/media/banners/landscape/1.png"},
  {url:"/media/banners/landscape/2.png"},
  {url:"/media/banners/landscape/3.png"},
  {url:"/media/banners/landscape/1.png"},
  {url:"/media/banners/landscape/2.png"},
  {url:"/media/banners/landscape/3.png"},
  {url:"/media/banners/landscape/1.png"},
  {url:"/media/banners/landscape/2.png"},
  {url:"/media/banners/landscape/3.png"},
  {url:"/media/banners/landscape/1.png"},
],
"Portrait":[
  {url:"/media/banners/portrait/1.png"},
  {url:"/media/banners/portrait/2.png"},
  {url:"/media/banners/portrait/3.png"},
  {url:"/media/banners/portrait/4.png"},
  {url:"/media/banners/portrait/5.png"},
  {url:"/media/banners/portrait/1.png"},
  {url:"/media/banners/portrait/2.png"},
  {url:"/media/banners/portrait/3.png"},
  {url:"/media/banners/portrait/4.png"},
  {url:"/media/banners/portrait/5.png"},
  {url:"/media/banners/portrait/1.png"},
  {url:"/media/banners/portrait/2.png"},
  {url:"/media/banners/portrait/3.png"},
  {url:"/media/banners/portrait/4.png"},
  {url:"/media/banners/portrait/5.png"},
]
}
