import React from "react";
import './styles.css';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Paper from '@material-ui/core/Paper';

import TableTopBar from './Components/TableTopBar/TableTopBar'

import { sortCaret } from './helpers/helpers';

import { nameFormatter, typeFormatter, statusFormatter, actionFormatter } from './helpers/formatters';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const columns = [
  {
    dataField: "ref",
    text: "Ref #",
    sort: true,
    sortCaret: sortCaret,
    headerAlign: "center",
    headerClasses: () => "SalesListing-ref",
    style:{
      color:"#4C91E2"
    }
  },
  {
    dataField: "client",
    text: "Client",
    headerClasses: () => "SalesListing-client",
    style:{
      color:"#3799FF"
    }
  },
  {
    dataField: "title",
    text: "Deal title",
  },
  {
    dataField: "date",
    text: "Expiry date",
  },
  {
    dataField: "time",
    text: "Time",
    
  },
  {
    dataField: "duration",
    text: "Duration",
    
  },
  {
    dataField: "services",
    text: "Services",
    headerClasses: () => "SalesListing-services",
    style:{
      color:"#6981FF"
    }
  },
  {
    dataField: "price",
    text: "Price",
    
  },
  {
    dataField: "status",
    text: "Status",
    formatter: statusFormatter,
  },
];

const products = [
  {
    id: 1,
    ref: "232-242-52",
    client: "Ansh Arora",
    title: "Get 3 in pr..",
    date: "22 Dec 2020",
    time: "2:30 pm",
    duration: "30 mins",
    services:"Hair cut(1/1) Hair Spa(1/1)",
    price:"Rs 300",
    status:"Active"
  },
  {
    id: 2,
    ref: "232-252-32",
    client: "Arjun Kapoor",
    title: "Get 3 in pr..",
    date: "22 Dec 2020",
    time: "3 pm",
    duration: "30 mins",
    services:"Hair cut(1/1) Hair Spa(1/1)",
    price:"Rs 300",
    status:"Active"
  },
  {
    id: 3,
    ref: "232-252-67",
    client: "Sameer Raina",
    title: "Get 3 in pr..",
    date: "22 Dec 2020",
    time: "3 pm",
    duration: "45 mins",
    services:"Hair cut(2/2) ",
    price:"Rs 450",
    status:"Active"
  },
  {
    id: 4,
    ref: "232-222-54",
    client: "Smily Singh",
    title: "Get 3 in pr..",
    date: "22 Dec 2020",
    time: "3:15 pm",
    duration: "90 mins",
    services:"Hair cut(2/2) ",
    price:"Rs 700",
    status:"Active"
  },
  {
    id: 5,
    ref: "232-252-326",
    client: "Samay Rana",
    title: "Get 2 in pr..",
    date: "22 Dec 2020",
    time: "3:20 pm",
    duration: "30 mins",
    services:"Hair cut(1/1) Hair Spa(1/1)",
    price:"Rs 300",
    status:"Active"
  },
  {
    id: 6,
    ref: "232-242-52",
    client: "Ansh Arora",
    title: "Get 3 in pr..",
    date: "23 Dec 2020",
    time: "2:30 pm",
    duration: "30 mins",
    services:"Hair cut(1/1) Hair Spa(1/1)",
    price:"Rs 300",
    status:"Active"
  },
  {
    id: 7,
    ref: "232-252-32",
    client: "Arjun Kapoor",
    title: "Get 3 in pr..",
    date: "23 Dec 2020",
    time: "3 pm",
    duration: "30 mins",
    services:"Hair cut(1/1) Hair Spa(1/1)",
    price:"Rs 300",
    status:"Active"
  },
  {
    id: 8,
    ref: "232-252-67",
    client: "Sameer Raina",
    title: "Get 2 in pr..",
    date: "23 Dec 2020",
    time: "3 pm",
    duration: "45 mins",
    services:"Hair cut(2/2) ",
    price:"Rs 450",
    status:"Active"
  },
  {
    id: 9,
    ref: "232-222-54",
    client: "Smily Singh",
    title: "Get 3 in pr..",
    date: "23 Dec 2020",
    time: "3:15 pm",
    duration: "90 mins",
    services:"Hair cut(2/2) ",
    price:"Rs 700",
    status:"Active"
  },
  {
    id: 10,
    ref: "232-252-326",
    client: "Samay Rana",
    title: "Get 2 in pr..",
    date: "23 Dec 2020",
    time: "3:20 pm",
    duration: "30 mins",
    services:"Hair cut(1/1) Hair Spa(1/1)",
    price:"Rs 300",
    status:"Active"
  },
  {
    id: 11,
    ref: "232-252-326",
    client: "Samay Rana",
    title: "Get 2 in pr..",
    date: "23 Dec 2020",
    time: "3:30 pm",
    duration: "30 mins",
    services:"Hair cut(2/2) ",
    price:"Rs 300",
    status:"Active"
  },
];

const paginationOptions = {
  showTotal: true,
  sizePerPageList: [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '25', value: 25 },
    { text: 'All', value: products.length }
  ]
}

const SalesDeals = () => {
  return (
    <div className="SalesListing">
      <Paper className="SalesListing-top-container">
        {/* <TableTopBar /> */}
        <div className="SalesListing-table-container">
          <ToolkitProvider
            keyField="id"
            data={products}
            columns={columns}
            exportCSV
            search
          >
            {
              props => <>
                <div className="px-2">
                  <div className="d-flex justify-content-between">
                  <SearchBar className="SalesListing-search" {...props.searchProps} />
                  <ExportCSVButton { ...props.csvProps }>Export <i className="fas fa-download ml-2"></i></ExportCSVButton>
                  </div>
                  <p className="text-muted"><b>Search</b> in any field</p>
                </div>

                <BootstrapTable {...props.baseProps}
                  classes="SalesTable"
                  bordered={false}
                  bootstrap4={true}
                  selectRow={{
                    mode: 'checkbox',
                  clickToSelect: true
                  }}
                  pagination={paginationFactory(paginationOptions)} />
              </>
            }
          </ToolkitProvider>
        </div>
      </Paper>
    </div>
  );
}

export default SalesDeals;
