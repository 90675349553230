import { nameFormatter, typeFormatter, statusFormatter, actionFormatter,modeFormatter,sortCaret } from './helpers/formatters';

export const columns = [
  {
    dataField: "ref",
    text: "Ref #",
    sort: true,
    sortCaret: sortCaret,
    headerAlign: "center",
    headerClasses: () => "SalesListing-ref",
  },
  {
    dataField: "client",
    text: "Client",
    headerClasses: () => "SalesListing-client",
    style:{
      color:"#3799FF"
    }
  },
  
  {
    dataField: "date",
    text: "Transaction date",

  },
  // {
  //   dataField: "mode",
  //   text: "Mode",
  //   formatter:modeFormatter,
  //   style:{
  //     color:"#657EFF"
  //   }
    
  // },
  {
    dataField: "orderId",
    text: "Order-id",
    style:{
      color:"#4A90E2"
    }
  },
  // {
  //   dataField: "received",
  //   text: "Received by",
  // },
  {
    dataField: "price",
    text: "Price",
    
  },
  {
    dataField: "status",
    text: "Status",
    formatter: statusFormatter,
  },
  
];