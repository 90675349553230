import React, { useState } from 'react';
import './TableTopBar.css';

import { Modal, Button, Form, InputGroup } from 'react-bootstrap';



const TableTopBar = ({ handleNewSubmit }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState("")

  const handleClose = () => {
    setName("");
    setValue("");
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const handleNewTax = (e) => {
    e.preventDefault();
    const payLoad = {
      items: [
        {
          name: name,
          value: value
        }
      ]
    }
    handleNewSubmit(payLoad);
    setName("");
    setValue("");
    setShow(false);
  }

  return (
    <div className="TableTopBar">
      <p className="TableTopBar-heading">Taxes</p>
      <div className="TableTopBar-add" onClick={handleShow}>
        <i className="fas fa-plus fa-xs mr-1  TableTopBar-add-icon"></i>
        Add Tax
      </div>

      {/* new rank Modal */}
      <Modal backdrop="static" centered show={show} onHide={handleClose}>
        <Form onSubmit={handleNewTax}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Tax</Modal.Title>
          </Modal.Header>
          <Modal.Body>




            <Form.Group>
              <Form.Label>Tax Name</Form.Label>
              <Form.Control type="text" value={name} required onChange={(e) => setName(e.target.value)}>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Tax Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control min="1" max="100" type="number" value={value} required onChange={(e) => setValue(e.target.value)}>
                </Form.Control>
              </InputGroup>
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
          </Button>
            <Button className="TableTopBar-submit px-5" type="submit" variant="primary">
              Save
          </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}

export default TableTopBar;