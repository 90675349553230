import React from "react";
import { Button } from "react-bootstrap";
import { Divider, IconButton, makeStyles, Modal } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "348px",
    width: "504px",
    backgroundColor: "#FFFFFF",
    zIndex: 999,
    padding: 20,
    opacity: 1,
  },
  title: {
    color: "#696969",
    fontSize: "16px",
    lineHeight: 1.5,
    fontWeight: 500,
  },
  label: {
    flex: "0.25",
    color: "#646C9B",
    fontSize: "12px",
    letterSpacing: 0,
    lineHeight: "18px",
  },
  value: {
    flex: 1,
    color: "#000000",
    fontSize: "18px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "27px",
  },
  buttonSave: {
    width: "135px",
  },
  buttonSkip: {
    width: "88px",
    marginRight: "5px",
  },
}));

function PaymentConfirmation({
  onConfirmPress,
  payingAmount,
  paymentOption,
  toClose,
  show,
}) {
  const classes = useStyles();

  return (
    <Modal
      open={show}
      className="d-flex align-items-center justify-content-center"
    >
      <div className={`bg-white ${classes.container}`}>
        <div className="d-flex align-items-center justify-content-between">
          <p className={`m-0 ${classes.title}`}>Transaction Reference Number</p>
          <IconButton onClick={toClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <div className="d-flex align-items-center mt-5 mb-5">
          <p className={`m-0 ${classes.label}`}>Amount</p>
          <p className={`m-0 ${classes.value}`}> &#x20B9; {payingAmount}</p>
        </div>
        <div className="d-flex align-items-center mb-5">
          <p className={`m-0 ${classes.label}`}>Mode</p>
          <p className={`m-0 ${classes.value}`}>
            {(paymentOption.basic && paymentOption.basic.name) || ""}
          </p>
        </div>
        <div className="mb-5">
          <p className={`m-0 mb-3 ${classes.label}`}>Enter Reference Number</p>
          <input className={`m-0 w-100 ${classes.value}`} />
        </div>
        <div className="mt-5">
          <Divider className="mb-3" />
          <div className="d-flex justify-content-end">
            <Button variant="outline-secondary" className={classes.buttonSkip}>
              Skip
            </Button>
            <Button
              variant="primary"
              className={classes.buttonSave}
              onClick={onConfirmPress}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PaymentConfirmation;
