import React from 'react';
import './SingleCampaign.css';

import { Row, Col, Media } from 'react-bootstrap';
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from '../../../../_metronic/_helpers'

const SingleCampaign = () => {
  return (
    <div className="SingleCampaign">
    <Row>
      <Col md={5}>
        <Media>
          <img
            width={50}
            height={50}
            className="mr-3 rounded"
            src="/media/img-1.jpg"
            alt="Generic placeholder"
          />
          <Media.Body >
            <p className="mb-1 font-weight-bold">Welcome to all new salon experience</p>
            <p className="text-muted">25 Dec, 2020</p>
          </Media.Body>
        </Media>
      </Col>
      <Col xs={8} md={4} as={Row} className="justify-content-between ml-1 ml-md-0">
        <div>
          <p className="mb-1 font-weight-bold">3000</p>
          <p className="text-muted">Sent</p>
        </div>
        <div>
          <p className="mb-1 font-weight-bold">80%</p>
          <p className="text-muted">Delivered</p>
        </div>
        <div>
          <span className="label label-lg label-light-success gb-label label-inline">
            Delivered
        </span>
        </div>

      </Col>
      <Col xs={4} md={{ span: 2, offset: 1 }}>
      <i className="fas fa-toggle-on text-primary mr-4"></i>
      <i className="fas fa-edit  text-primary mr-4"></i>
      <i className="fas fa-trash-alt text-primary mr-4"></i>
      </Col>
    </Row>
    </div>
  )
}

export default SingleCampaign