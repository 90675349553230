import React, { useState } from 'react';

import { Form, Col, InputGroup } from 'react-bootstrap';


const SearchCustomer = ({ searchCustomer,length}) => {
  const [val, setVal] = useState("")

  const handleChange = (e) => {
    setVal(e.target.value);
    if (e.target.value.length > length) {
      searchCustomer(e.target.value)
    }
    if(e.target.value.length===0){
      searchCustomer()
    }
  }
  
  const handleClick = () =>{
    if(val.length){
      setVal("")
      searchCustomer()
    }
  }


  return (
    <div className="SearchCustomer-Input">
          <InputGroup>
            <Form.Control
              name="searchPhone"
              id="searchPhone"
              placeholder="Search with Phone no."
              value={val}
              onChange={handleChange}
              maxLength="10"/>
            <InputGroup.Append style={{cursor:"pointer"}} onClick={handleClick}>
              <InputGroup.Text><i className="fas fa-times"></i></InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
    </div>

  )
}

export default SearchCustomer;