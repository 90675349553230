import React, { useEffect, useState } from "react";
import moment from "moment";
import OnBoardingForms from "./forms";
import {
  getBasicDetails,
  getOperationDetails,
  getTaxDetails,
  getImageDetails,
  postBasicDetails,
  postOperationDetails,
  postTaxDetails,
  postLogo,
  postSalonImages,
  deleteImage,
} from "./onBoardingCrud";
import { connect } from "react-redux";
import { initialValues as initialEmptyValues } from "./forms/initialvalues";
import {
  generatePayloadBasicDetails,
  generatePayloadOperationDetails,
  generatePayloadTaxDetails,
} from "./forms/generatePayloads";
import CubeGridLoader from "../../commonComponents/cubeGridLoader";
import { QuickUserToggler } from "../../../_metronic/layout/components/extras/QuickUserToggler";

function OnBoarding({ auth }) {
  const [initialValues, setInitialValues] = useState(initialEmptyValues);
  const [possibleValues, setPossibleValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const { currentSalon } = auth;

  const {
    salon: { uuid: salonUUID },
  } = currentSalon;

  useEffect(() => {
    fetchDetails();
  }, []);

  function clockTimeToJSDate(time) {
    const [h, m] = time.split(":").map(Number);
    return moment()
      .hour(h)
      .minute(m)
      .seconds(0)
      .toDate();
  }

  function onDirectionClick(direction, formValues) {
    let newCurrentStep;
    if (direction === "previous") {
      newCurrentStep = currentStep - 1;
    } else if (direction === "next") {
      updateFormDetails(formValues, currentStep);
      newCurrentStep = currentStep + 1;
    }
    if (newCurrentStep === 5 || newCurrentStep === 0) return;
    setCurrentStep(newCurrentStep);
  }

  async function onAddingImage(type, file) {
    setIsLoading(true);
    if (type === "logo") {
      await uploadLogo(file);
    } else if (type === "salonInteriorImages") {
      await uploadSalonImage(file);
    }
    setIsLoading(false);
  }

  function onSubmit() {
    window.location.reload(false);
  }

  async function uploadLogo(file) {
    try {
      const {
        data: { data },
      } = await postLogo({ salonUUID, imageFile: file });
      initialValues[currentStep].businessLogo = [data.logo];
      setInitialValues({ ...initialValues });
    } catch (e) {
      console.log(e, "upload logo");
    }
  }

  async function uploadSalonImage(file) {
    try {
      const {
        data: { data },
      } = await postSalonImages({ salonUUID, imageFile: file });
      const salonInteriorImages = data.images.map((item) => item.url);
      initialValues[currentStep].salonInteriorImages = salonInteriorImages;
      setInitialValues({ ...initialValues });
    } catch (e) {
      console.log(e, "upload salon images");
    }
  }

  async function onClickOfDeleteImage(imageFileUrl, step) {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { logo, images },
        },
      } = await deleteImage({ salonUUID, imageFileUrl });
      const businessLogo = logo;
      const salonInteriorImages = images.map((item) => item.url);
      initialValues[step] = {
        businessLogo: [businessLogo],
        salonInteriorImages,
      };
      setInitialValues({ ...initialValues });
    } catch (e) {}
    setIsLoading(false);
  }

  function getNecessaryBasicData(data) {
    return Object.entries(data).reduce((acc, item) => {
      const key = item[0]
        .split("_")
        .map((item, idx) => {
          if (idx > 0) return `${item[0].toUpperCase()}${item.slice(1)}`;
          return item;
        })
        .join("");
      if (key === "locState" || key === "locCity" || key === "locMicroMarket") {
        acc[key] = item[1].uuid;
      } else {
        acc[key] = item[1];
      }
      return acc;
    }, {});
  }

  function getNecessaryOperationsData(data) {
    return Object.entries(data).reduce((acc, item) => {
      const key = item[0]
        .split("_")
        .map((item, idx) => {
          if (idx > 0) return `${item[0].toUpperCase()}${item.slice(1)}`;
          return item;
        })
        .join("");
      if (key === "operationalDays") {
        acc[key] = Object.entries(item[1])
          .filter((value) => value[1])
          .map((item) => item[0]);
      } else if (key === "amenities") {
        acc[key] = item[1]
          .filter((value) => value.present)
          .map((value) => value.name);
      } else if (key === "timing") {
        acc["openingTime"] = clockTimeToJSDate(item[1].open);
        acc["closingTime"] = clockTimeToJSDate(item[1].close);
      } else {
        acc[key] = item[1];
      }
      return acc;
    }, {});
  }

  function getNecessaryTaxData(data) {
    return Object.entries(data).reduce((acc, item) => {
      const key = item[0]
        .split("_")
        .map((item, idx) => {
          if (item === "rb") return "registeredBusiness";
          if (idx > 0) return `${item[0].toUpperCase()}${item.slice(1)}`;
          return item;
        })
        .join("");
      acc[key] = item[1];
      return acc;
    }, {});
  }

  function getPossibleValues(data) {
    return Object.entries(data).reduce((acc, item) => {
      const key = item[0]
        .split("_")
        .map((item, idx) => {
          if (item === "rb") return "registeredBusiness";
          if (idx > 0) return `${item[0].toUpperCase()}${item.slice(1)}`;
          return item;
        })
        .join("");
      if (key === "businessTypes" || key === "servedGenders") {
        acc[key] = item[1].map((type) => {
          return { value: type.name, name: type.display_name };
        });
      } else if (key === "operationalDays") {
        acc[key] = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
      } else if (key === "amenities") {
        acc[key] = item[1].map((value) => value.name);
      } else {
        acc[key] = item[1];
      }
      return acc;
    }, {});
  }

  async function fetchDetails() {
    setIsLoading(true);
    try {
      const { data: basic } = await getBasicDetails({ salonUUID });
      const { data: operation } = await getOperationDetails({ salonUUID });
      const { data: tax } = await getTaxDetails({ salonUUID });
      const {
        data: {
          data: { logo, images },
        },
      } = await getImageDetails({ salonUUID });
      const businessLogo = logo;
      const salonInteriorImages = images.map((item) => item.url);
      const initialValues = {
        1: getNecessaryBasicData(basic),
        2: getNecessaryOperationsData(operation),
        3: getNecessaryTaxData(tax),
        4: {
          businessLogo: [businessLogo],
          salonInteriorImages,
        },
      };
      setInitialValues({ ...initialEmptyValues, ...initialValues });
      const { loc_countries } = initialValues[1].possibleValues;
      const { loc_states } = loc_countries[0];
      const possibleValues = getPossibleValues({
        ...initialValues[1].possibleValues,
        loc_states,
        amenities: operation.amenities,
        operationalDays: initialValues[2].operationalDays,
        ...initialValues[3].possibleValues,
      });
      setPossibleValues({ ...possibleValues });
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  async function updateFields(step) {
    let values;
    switch (step) {
      case 1:
        let response = await getBasicDetails({ salonUUID });
        values = getNecessaryBasicData(response.data);
        break;
      case 2:
        response = await getOperationDetails({ salonUUID });
        values = getNecessaryOperationsData(response.data);
        break;
      case 3:
        response = await getTaxDetails({ salonUUID });
        values = getNecessaryTaxData(response.data);
        break;
    }
    initialValues[step] = values;
    setInitialValues({ ...initialValues });
  }

  function getPostAPI(step) {
    if (step === 1) {
      return postBasicDetails;
    } else if (step === 2) {
      return postOperationDetails;
    } else if (step === 3) {
      return postTaxDetails;
    }
  }

  function getFunctionToGeneratePayload(step) {
    if (step === 1) {
      return generatePayloadBasicDetails;
    } else if (step === 2) {
      return generatePayloadOperationDetails;
    } else if (step === 3) {
      return generatePayloadTaxDetails;
    }
  }

  async function updateFormDetails(formValues, currentStep) {
    setIsLoading(true);
    const generatePayload = getFunctionToGeneratePayload(currentStep);
    const payload = generatePayload(formValues[currentStep], possibleValues);
    const postAPI = getPostAPI(currentStep);
    try {
      const response = await postAPI({ salonUUID, payload });
      await updateFields(currentStep);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  function Header() {
    return (
      <div className={`header header-fixed d-flex justify-content-end`}>
        <div className="topbar">
          <QuickUserToggler />
        </div>
      </div>
    );
  }

  return (
    <div className="content mt-20">
      <Header />
      {isLoading ? (
        <div className="w-100 h-100 d-flex align-items-center">
          <CubeGridLoader />
        </div>
      ) : (
        <OnBoardingForms
          initialValues={initialValues}
          possibleValues={possibleValues}
          onDirectionClick={onDirectionClick}
          onSubmitClick={onSubmit}
          currentStep={currentStep}
          salonUUID={salonUUID}
          onAddingImage={onAddingImage}
          onClickOfDeleteImage={(fileUrl) => onClickOfDeleteImage(fileUrl, 4)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(OnBoarding);
