import React from 'react';
import './formatters.css';


export function actionFormatter(cell, row, rowIndex, extraData) {
  const handleEditClick = () =>{
    extraData.openEditModal(row.uuid);
  }
  return (
    <div>
      <i className="fas fa-edit mr-2  actionFormatter-action-blue blue" onClick={handleEditClick}></i>
      <i className="fas fa-trash  actionFormatter-action-red red"></i>
    </div>
  )
}

export function valueFormatter(cell,row){
return <div>{`${cell}%`}</div>
}


