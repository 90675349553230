import React from 'react';
import './Mockup.css';

import 'html5-device-mockups/dist/device-mockups.min.css';

const Mockup = ({bgColor,heading,description,image,price,tags}) => {
  return (
    <div className="Mockup-container mt-5 mt-md-0">
      <div className="Mockup" style={{backgroundColor:bgColor}}>
        <i className="speaker"></i>
        <i className="camera"></i>

        <div className="Mockup-top">
          <div className="d-flex">
            <img className="Mockup-image" src={image} />
            <div className="Mockup-logo">
              <img className="rounded" src="/media/img-1.jpg" height="45" />
              <i className="fas fa-share-alt Mockup-share"></i>
            </div>
          </div>
        </div>

        <div className="Mockup-bottom">

          <div className="Mockup-tags mb-5">
            {
              tags.map(tag=><span key={tag.value} className="Mockup-tag">{tag.label}</span>)
            }
          </div>

          <h5 className="Mockup-title">
            {heading}
          </h5>

          <p className=" Mockup-description">{description}</p>

          <p>Rs {price}</p>

          <div className="Mockup-buttons text-center pt-2">
            <span className="Mockup-buy">Buy Now</span>
            <span className="Mockup-details">Details</span>
          </div>


        </div>
      </div>
    </div>
  )
}

export default Mockup;