import React, { useState, useEffect } from "react";
import { Modal, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchBox from "../../../commonComponents/searchBox";

import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    height: "80vh",
    width: "90vw",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
  },
  itemsSection: {
    overflow: "auto",
    padding: 10,
  },
  closeIcon: {
    position: "absolute",
    right: "10px",
    fontSize: "20px",
    border: "none",
    color: "#c6c6c8",
  },
  title: {
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "30px",
    padding: theme.spacing(2),
  },
  serviceItemContainer: {
    height: "75px",
    borderBottom: "1px solid #E7E9F5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: "25px",
  },
  serviceItem: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
  },
  goBack: {
    padding: "15px 20px",
    fontSize: "1.2rem",
    backgroundColor: "#f3f6f9",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  goBackIcon: {
    marginRight: "10px",
    fontSize: "2rem",
    color: "#464E5F",
  },
}));

function SelectItem({ open, handleClose, services = [], addService }) {
  const classes = useStyles();
  const [allServices, setAllServices] = useState([]);
  const [individualServices, setIndividualServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [selectedStylist, setSelectedStylist] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setCurrentStep(1);
    setSelectedService(null);
    setSelectedVariation(null);
    setSelectedStylist(null);
  }, [open]);

  useEffect(() => {
    let temp = services.reduce((acc, service) => {
      acc.push(...service.items);
      return acc;
    }, []);
    setIndividualServices(temp);
    setAllServices(temp);
  }, [services]);

  function onClickofItem(item) {
    if (currentStep === 1) {
      setSelectedService(item);
      item.variations.length ? setCurrentStep(2) : setCurrentStep(3);
    } else if (currentStep === 2) {
      setSelectedVariation(item);
      setCurrentStep(3);
    } else if (currentStep === 3) {
      setSelectedStylist(item);
      addService({
        id: uuidv4(),
        service: selectedService,
        variation: selectedVariation,
        appointment: { personnel: item },
      });
      handleClose();
    }
  }

  function getList() {
    if (currentStep === 1) {
      console.log(individualServices);
      return individualServices;
    } else if (currentStep === 2) {
      return selectedService.variations;
    } else if (currentStep === 3) {
      let stylists = [];
      stylists = selectedService.variations.length
        ? selectedVariation.personnels.STYLIST.concat(
            selectedVariation.personnels.NON_STYLIST
          )
        : selectedService.personnels.STYLIST.concat(
            selectedService.personnels.NON_STYLIST
          );
      return stylists.map((item) => {
        item.name = `${item.basic.name_first} ${item.basic.name_last}`;
        return item;
      });
    }
  }

  function getVariationItem(item) {
    return (
      <>
        <div>
          <p className={classes.serviceItem}>{item.name}</p>
        </div>
        <p className="m-0">Rs {item.price.price_net}</p>
      </>
    );
  }

  function handleGoBack() {
    if (currentStep === 2) {
      setCurrentStep(currentStep - 1);
    } else if (currentStep === 3) {
      selectedService.variations.length
        ? setCurrentStep(currentStep - 1)
        : setCurrentStep(currentStep - 2);
    }
  }

  let heading = "Select service";
  if (currentStep === 2) {
    heading = "Select variation";
  } else if (currentStep === 3) {
    heading = "Select stylist";
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onEscapeKeyDown={handleClose}
      className="d-flex align-items-center justify-content-center"
    >
      <div className={classes.paper}>
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <Typography className={classes.title} align="center">
              Select Item
            </Typography>
            <button
              className={classes.closeIcon}
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="w-100">
            {currentStep === 1 && (
              <SearchBox
                placeholder="Search Service"
                data={allServices}
                setData={setIndividualServices}
                searchField="name"
              />
            )}
            {currentStep !== 1 && (
              <div className={classes.goBack} onClick={handleGoBack}>
                <i className={`fas fa-chevron-left ${classes.goBackIcon}`}></i>
                Go back
              </div>
            )}
          </div>
        </div>
        <div className={classes.itemsSection}>
          <p>{heading}</p>
          {getList().map((item) => (
            <div
              key={item.uuid}
              className={classes.serviceItemContainer}
              onClick={() => onClickofItem(item)}
            >
              {currentStep === 1 && (
                <>
                  <div>
                    <p className={classes.serviceItem}>{item.name}</p>
                    <p>{item.duration_minutes} mins</p>
                  </div>
                  <p className="m-0">Rs {item.price.price_net}</p>
                </>
              )}
              {currentStep === 2 && <>{getVariationItem(item)}</>}
              {currentStep === 3 && (
                <>
                  <p className={classes.serviceItem}>{item.name}</p>
                  <p className="m-0">{item.basic.rank}</p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default SelectItem;
