import React, { useEffect, useState } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import { getAppointmentList } from "./appointmentsCrud";
import Calender from "./calender";
import CreateAppointment from "./createAppointment";
import { actions } from "./_redux";
import CubeGridLoader from "../../commonComponents/cubeGridLoader";
import { Drawer as SwipeableDrawer } from "@material-ui/core";
import AppointmentDetails from "./components/appointmentDetails";

const MOMENT_DATE_FORMAT = "yyyy-MM-DD";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function Appointments({
  auth,
  appointmentsState: { isCreateAppointmentOpen },
  openCreateAppointment,
  closeCreateAppointment,
}) {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(
    moment().format(MOMENT_DATE_FORMAT)
  );

  const [data, setData] = useState({});
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isSalonOpen, setIsSalonOpen] = useState(false);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const {
    user: {
      salons: [salon],
    },
  } = auth;

  const {
    salon: { uuid: salonUUID },
  } = salon;

  const { currentSalon } = auth;

  useEffect(() => {
    // fetchAppoinments(currentDate, salonUUID);
    fetchAppoinments(currentDate, "839bec35-8989-459d-9733-b463c8cc4dba");
  }, [currentDate]);

  useEffect(() => {
    setActiveCategory(categories[0]);
  }, [categories]);

  useEffect(() => {
    if (!Object.keys(data).length) return;
    formatFetchedData(data);
  }, [activeCategory]);

  const onDateChange = (date) => {
    const currentDate = moment(date).format(MOMENT_DATE_FORMAT);
    setCurrentDate(currentDate);
  };

  async function fetchAppoinments(date, salonUUID) {
    setShowLoader(true);
    try {
      const {
        data: {
          categories,
          schedule: { state: salonScheduleState },
        },
      } = await getAppointmentList({ date, salonUUID });
      setIsSalonOpen(salonScheduleState === "OPEN");
      setData(categories);
      setCategories([
        "All STAFF",
        ...Object.keys(categories).map((item) => item.split("_").join("-")),
      ]);
      formatFetchedData(categories);
    } catch (e) {
      console.log(e, fetchAppoinments);
    }
    setShowLoader(false);
  }

  function formatFetchedData(data) {
    let allData = [];
    if (activeCategory === "STYLIST") {
      allData = [...data.STYLIST];
    } else if (activeCategory === "NON-STYLIST") {
      allData = [...data.NON_STYLIST];
    } else {
      allData = [...data.STYLIST, ...data.NON_STYLIST];
    }
    const resources = allData.reduce((acc, item) => {
      acc.push({
        id: item.uuid,
        title: `${item.basic.name_first} ${item.basic.name_last}`,
        item: item,
        businessHours:
          item.schedule.state === "AVAILABLE"
            ? {
                startTime: item.basic.time_in,
                endTime: item.basic.time_out,
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
              }
            : {
                startTime: "00:00",
                endTime: "00:00",
              },
      });
      return acc;
    }, []);
    setResources(resources);

    const events = allData.reduce((acc, item) => {
      const appointments = item.appointments.map((appointment) => {
        const customerName = `${appointment.consumer.basic.name_first} ${appointment.consumer.basic.name_last}`;
        return {
          appointment,
          color: getEventColor(appointment.order.basic.state),
          resourceId: appointment.personnel.uuid,
          title: `${appointment.details.service.name} - ${customerName}`,
          start: moment(appointment.basic.instant.start)
            .local()
            .format(),
          end: moment(appointment.basic.instant.end)
            .local()
            .format(),
        };
      });
      acc.push(...appointments);
      return acc;
    }, []);
    setEvents(events);
  }

  function getEventColor(state) {
    switch (state) {
      case "CONFIRMED":
        return "#E1F0FF";
      case "MISSED":
        return "#EBEBEB";
      case "CANCELLED":
        return "#FFCAD1";
      case "COMPLETED":
        return "#D4F0E4";
      default:
        return "#adadad";
    }
  }

  function onEventClick({
    event: {
      _def: {
        extendedProps: { appointment },
      },
    },
  }) {
    setSelectedOrder(appointment);
    setShowAppointmentDetails(true);
  }

  function onClickCloseAppointmentDetails() {
    setShowAppointmentDetails(false);
  }

  return showLoader ? (
    <Container className="h-100 w-100 d-flex align-items-center">
      <CubeGridLoader />
    </Container>
  ) : (
    <>
      <Calender
        onDateChange={onDateChange}
        resources={resources}
        events={events}
        categories={categories}
        activeCategory={activeCategory}
        onCategorySelect={setActiveCategory}
        currentDate={currentDate}
        openCreateAppointment={openCreateAppointment}
        currentSalon={currentSalon}
        isSalonOpen={isSalonOpen}
        setShowAppointmentDetails={setShowAppointmentDetails}
        onEventClick={onEventClick}
      />
      <CreateAppointment
        isOpen={true}
        closeModal={closeCreateAppointment}
        salonUUID={salonUUID}
      />
      <SwipeableDrawer
        open={showAppointmentDetails}
        anchor="right"
        onClose={() => setShowAppointmentDetails(false)}
        className={`h-100 ${classes.drawer}`}
        classes={{ paper: classes.drawer }}
      >
        <AppointmentDetails
          selectedOrder={selectedOrder}
          onClickClose={onClickCloseAppointmentDetails}
        />
      </SwipeableDrawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  appointmentsState: state.appointments,
});

export default connect(mapStateToProps, actions)(Appointments);
