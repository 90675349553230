import React from "react";
import { HighlightOff as CloseIcon } from "@material-ui/icons";
import { Avatar, Badge } from "@material-ui/core";

function uploadedImageAvatar({ image, onClickOfDeleteImage }) {
  return (
    <span onClick={onClickOfDeleteImage}>
      <Badge badgeContent={<CloseIcon />} className="m-2">
        <Avatar src={image} variant="square" />
      </Badge>
    </span>
  );
}

export default uploadedImageAvatar;
