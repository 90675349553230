export const initialValues = {
  1: {
    name: "",
    businessType: "",
    servedGender: "",
    managerName: "",
    managerPhone: "",
    managerEmail: "",
    address: "",
    locState: "",
    locCity: "",
    locMicroMarket: "",
  },
  2: {
    openingTime: "",
    closingTime: "",
    operationalDays: [],
    amenities: [],
  },
  3: {
    registeredBusinessName: "",
    registeredBusinessAddress: "",
    registeredBusinessLocState: "",
    registeredBusinessLocCity: "",
    pan: "",
    gst: "",
  },
  4: {
    businessLogo: [],
    salonHeroImage: [],
    salonInteriorImages: [],
  },
};
