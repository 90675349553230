import React, { useState,useEffect } from 'react';
import './SingleCategoryItem.css';

import { Collapse } from 'react-bootstrap'
import MainCategory from './MainCategory';
import Services from './Services';


function SingleCategoryItem(props) {
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    setOpen(props.openAll);
  },[props.openAll])

  const singleCategory = props.singleCategoryData;
  const { uuid, basic, items } = singleCategory;

  const handleCollapse = () => {
    setOpen(!open)
  }


  return (
    <div className="CategoryCard">

      <MainCategory handleModal={props.handleModal} categoryuuid={uuid} name={basic.name} handleCollapse={handleCollapse} />

      <Collapse in={open}>
        <div id={`example-collapse-text-${uuid}`}>
          <Services services={items} />
        </div>
      </Collapse>

    </div>

  )
}

export default SingleCategoryItem;