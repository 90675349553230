import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Avatar,
  makeStyles,
  colors,
} from "@material-ui/core";
import { Phone as PhoneIcon, Email as EmailIcon } from "@material-ui/icons";

const { blue } = colors;

const useStyles = makeStyles((theme) => ({
  blueColor: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
}));

function CustomerCard({ firstName, lastName, mobile, email }) {
  const classes = useStyles();
  return (
    <Card variant="outlined" className="bg-light">
      <CardContent className="d-flex">
        <div className="d-flex">
          <Avatar
            variant="rounded"
            className={`mr-5 text-capitalize ${classes.blueColor}`}
          >
            <span>{firstName[0]}</span>
            <span>{lastName[0]}</span>
          </Avatar>
        </div>
        <div className="flex-grow-1 ml-5">
          <div>
            <Typography className="text-capitalize">
              <span>{firstName}</span> <span>{lastName}</span>
            </Typography>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <Typography className="flex-grow-1">
              <PhoneIcon /> {mobile}
            </Typography>
            <Typography className="flex-grow-1">
              <EmailIcon /> {email}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default CustomerCard;
