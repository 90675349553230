import React from 'react';
import './SingleDeal.css';

import { Media } from 'react-bootstrap';


const SingleDeal = (props) => {
  const { image, bgColor, title, expiry, price, payState, totalSession, usedSession, leftSession, dealState } = props.data

  let dealClass = ""

  if (dealState === "FAILED") {
    dealClass = "label label-lg label-light-danger gb-label label-inline"
  } else if (dealState === "CONFIRMED") {
    dealClass = "label label-lg label-light-primary gb-label label-inline"
  } else if (dealState === "PAYMENT_UNDERWAY") {
    dealClass = "label label-lg label-light-warning gb-label label-inline"
  }

  return (
    <div className="SingleDeal py-3">
      <Media className="SingleDeal-left">
        <img
          width={64}
          height={64}
          className="mr-3 rounded"
          src={image}
          alt="Generic placeholder"
          style={{
            backgroundColor: bgColor
          }}
        />
        <Media.Body>
          <h5>{title}</h5>
          <p className="text-muted">
            valid till {expiry}
          </p>
        </Media.Body>
      </Media>

      <div className="SingleDeal-right pt-2 pt-md-0">
        <div className="text-center">
          <h5>Rs {price}</h5>
          <p className="text-muted m-0">{payState}</p>
        </div>

        <div className="text-center">
          <h5>{usedSession ? usedSession : 0}/{totalSession}</h5>
          <p className="text-muted m-0">Sessions Left- {leftSession ? leftSession : totalSession}</p>
        </div>

        <div className="SingleDeal-pay-state">
          <div className={dealClass}>
            {dealState === "PAYMENT_UNDERWAY" ? "PENDING" : dealState}
          </div>
        </div>
      </div>



    </div>
  )
}

export default SingleDeal;