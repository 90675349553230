/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../../_helpers";
import {Link} from 'react-router-dom';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchResult({ data }) {
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted d-none">No record found</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
          {/* begin: Section */}
          {/* <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Documents
          </div>
          <div className="mb-10">

            <div className="d-flex align-items-center flex-grow-1 mb-2">
              <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}></SVG>
              </div>
              <div className="d-flex flex-column ml-3 mt-2 mb-2">
                <a
                  href="#"
                  className="font-weight-bold text-dark text-hover-primary"
                >
                  AirPlus Requirements
                </a>
                <span className="font-size-sm font-weight-bold text-muted">
                  by Grog John
                </span>
              </div>
            </div>

          </div> */}
          {/* end: Section */}

          {/* begin: Section */}
          <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Customers
          </div>

          <div className="mb-10">

            {
              data.map(ob =>
                <div className="d-flex align-items-center flex-grow-1 mb-2" key={ob.uuid}>
                  <div className="symbol symbol-30  flex-shrink-0">
                    <div
                      className="symbol-label"
                    >{ob.basic.name_first.slice(0,1)}{ob.basic.name_last.slice(0,1)}</div>
                  </div>

                  <div className="d-flex flex-column ml-3 mt-2 mb-2">
                    <Link to={`/singleuser/${ob.uuid}`}
                      
                      className="font-weight-bold text-dark text-hover-primary"
                    >
                      {ob.basic.name_first} {ob.basic.name_last}
                </Link>
                    <span className="font-size-sm font-weight-bold text-muted">
                      Phone:{ob.basic.mobile}
                </span>
                  </div>
                </div>
              )
            }



          </div>
          {/* end: Section */}

          {/* begin: Section */}
          {/* <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Files
          </div>
          <div className="mb-10">

            <div className="d-flex align-items-center flex-grow-1 mb-2">
              <div className="symbol symbol-45 symbol-circle flex-shrink-0">
                <div className="symbol-label">
                  <i className="flaticon-psd text-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column ml-3 mt-2 mb-2">
                <a
                  href="#"
                  className="font-weight-bold text-dark text-hover-primary"
                >
                  79 PSD files generated
                </a>
                <span className="font-size-sm font-weight-bold text-muted">
                  by Grog John
                </span>
              </div>
            </div>

          </div> */}
          {/* end: Section */}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
