import axios from 'axios';

// reading from Personnel Rank 
// export function getAllRankTypes(salonuuid){
//   return axios.get('salons/' + salonId + '/personnels/ranks');
// }

// reading from Service add addforinput
export function getAllInputData(saloonuuid){
  return axios.get('salons/' + saloonuuid + '/services/add');
}

// put at Service add
export function addNewService(payLoad,saloonuuid){
  return axios.put('salons/' + saloonuuid + '/services/add',payLoad);
}