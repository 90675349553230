import React, { useState,useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';



const EditRankModal = ({editShow,setEditShow, handleEditSubmit, selecteduuid,allRanks,setSelecteduuid }) => {
  const [rankuuid,setRankuuid] = useState(selecteduuid);
  const [newName,setNewName] = useState("");
  console.log(selecteduuid);
  console.log(rankuuid);

  useEffect(()=>{
    setRankuuid(selecteduuid);
  },[selecteduuid])

  const handleClose = () =>{
    setRankuuid("");
    setNewName("");
    setSelecteduuid("")
    setEditShow(false);
  } 

  const handleEditRank = (e) =>{
    e.preventDefault();
    const payLoad = {
      items:[
        {
          uuid:rankuuid,
          name:newName
        }
      ]
    }
    handleEditSubmit(payLoad);
    setRankuuid("");
    setNewName("");
    setSelecteduuid("");
    setEditShow(false);
  }

  const options = allRanks.map(rank=>(
    <option key={rank.uuid} value={rank.uuid}>{rank.rank}</option>
  ))


  return (

      <Modal backdrop="static" centered show={editShow} onHide={handleClose}>
        <Form onSubmit={handleEditRank}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Rank</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group>
              <Form.Label>Old Rank</Form.Label>
              <Form.Control as="select" value={rankuuid} required onChange={(e)=>setRankuuid(e.target.value)}>
                <option value="" disabled>Select</option>
                {options}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>New Rank Name</Form.Label>
              <Form.Control type="text" value={newName} required onChange={(e)=>setNewName(e.target.value)} />
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
          </Button>
            <Button className="TableTopBar-submit" type="submit" variant="primary">
              Save
          </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    
  )
}

export default EditRankModal;