import React from 'react';
import './NumberCard.css';
import { Row, Col, Button } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';

const NumberCard = ({data}) => {
  const {COMPLETED,CANCELLED,MISSED} = data.appointment_count
  const {collected,outstanding} = data.amount_sale
  const total = COMPLETED + CANCELLED + MISSED
  return (
    <div className="NumberCard">
      <Paper className="NumberCard-top">
        <Row noGutters className="height-100" >
          <Col className="NumberCard-item border-right-2" >
            <h4>Rs {collected}</h4>
            <p className="text-muted">Total Sales</p>
          </Col>

          <Col className="NumberCard-item">
            <h4>Rs {outstanding}</h4>
            <p className="text-muted">Outstanding amount</p>
          </Col>
        </Row>
      </Paper>

      <Paper className="NumberCard-bottom" elevation={0}>
        <Row className="justify-content-around height-100">
          <Col className="NumberCard-item"><h4>{total}</h4>
            <p className="text-muted text-center">All Bookings</p>
          </Col>
          <Col className="NumberCard-item"><h4>{COMPLETED}</h4>
            <p className="text-muted">Completed</p>
          </Col>
          <Col className="NumberCard-item"><h4>{CANCELLED}</h4>
            <p className="text-muted">Cancelled</p>
          </Col>
          <Col className="NumberCard-item"><h4>{MISSED}</h4>
            <p className="text-muted">No shows</p>
          </Col>
        </Row>
      </Paper>

    </div>


  )
}

export default NumberCard;