import React from 'react'
import './BannerPreview.css'

import { useRouteMatch,Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { Row, Col, Button } from 'react-bootstrap';

const BannerPreview = () => {
  let match = useRouteMatch();
  const { type, img: image } = match.params;

  const getClass=(type)=>{
    if(type==="Portrait"){
      return 'por'
    }else{
      return 'rec'
    }
  }

  return (
    <div className="BannerPreview text-dark h-100">
      <Row className="h-100">

        <Col md={7} className="BannerPreview-left mb-5 mb-md-0">
          <div className="text-center">
            <h1>Banner Preview</h1>
            <img className={`BannerPreview-${getClass(type)}`} src={`/media/banners/${type.toLowerCase()}/${image}.png`}></img>
          </div>
        </Col>

        <Col md={5}>
          <Paper elevation={0} className="h-100">
            <div className="BP-right-top">
              <p>Exclusive membership access with GoBony, Inc.</p>
            </div>

            <div className="BP-right">
              <p>Select size</p>
              <h3>Large, 5670 x 3840 pixels <br /> 300 DPI, JPEG</h3>
            </div>

            <div className="BP-right">
              <p>Subscription Plan</p>

              <Radio
                value="a"
                name="radio-button-demo"
                color="secondary"
                size="medium"
              />
              <span>
                <b>Premium</b>
              </span>

              <p className="text-muted w-75">Limited access to banner library with download available in a month</p>
            </div>

            <div className="BP-download">
              <Link to={`/media/banners/${type.toLowerCase()}/${image}.png`} target="_blank" download>
                <Button block>Download</Button>
              </Link>
            </div>



          </Paper>
        </Col>
      </Row>
    </div>
  )
}

export default BannerPreview