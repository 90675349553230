import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, makeStyles, colors, Typography, Box } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { fetchServiceList, fetchStylistList } from "../appointmentsCrud";
import AddAppointment from "./addAppointment";
import { getTimeSlot } from "../appointmentsCrud";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const { lightBlue, orange, blue } = colors;

const useStyles = makeStyles((theme) => ({
  resetCta: {
    height: "35px",
    width: "105px",
    color: lightBlue[600],
    backgroundColor: blue[50],
    "&:hover": {
      backgroundColor: blue[50],
    },
  },
  addCta: {
    color: orange[800],
    backgroundColor: orange[50],
    "&:hover": {
      backgroundColor: orange[50],
    },
  },
  notesField: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
}));

const MOMENT_DATE_FORMAT = "yyyy-MM-DD";

function Schedule({ salonUUID, addToCart, cartAppointments }) {
  const location = useLocation();
  const [servicesList, setServicesList] = useState([]);
  const [stylistList, setStylistList] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    moment().format(MOMENT_DATE_FORMAT)
  );
  const [appointments, setAppointments] = useState([]);
  const [appointmentIndex, setAppointmentIndex] = useState(0);
  const [loadingStylistsFor, setLoadingStylistsFor] = useState(false);
  const [preSelectedStylistCount, setPreSelectedStylistCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    reset();
    getServicesList(currentDate);
  }, [currentDate]);

  useEffect(() => {
    initializeAppointments();
  }, []);

  useEffect(() => {
    if (location.state) {
      const { date } = location.state;
      setCurrentDate(moment(date).format(MOMENT_DATE_FORMAT));
    }
  }, []);

  useEffect(() => {
    if (appointments.length > 1) {
      const newAppointments = [...cartAppointments, appointments.slice(-1)[0]];
      setAppointmentIndex(newAppointments.length - 1);
      setAppointments([...newAppointments]);
    }
  }, [cartAppointments]);

  useEffect(() => {
    if (
      appointments.length &&
      appointments[appointmentIndex].stylist &&
      !appointments[appointmentIndex].service
    ) {
      const servicesList = extractServiceList(
        appointments[appointmentIndex].stylist.data.services
      );
      setServicesList(servicesList);
    }
  }, [appointments]);

  useEffect(() => {
    if (
      appointments.length &&
      appointments[appointmentIndex].stylist &&
      appointments[appointmentIndex].service
    ) {
      const payload = {
        service_uuid: appointments[appointmentIndex].service.value,
        variation_uuid:
          appointments[appointmentIndex].service.variationuuid || null,
        date: appointments[appointmentIndex].appointmentDate,
      };
      getAvailableSlots(
        payload,
        appointments[appointmentIndex].stylist.value,
        salonUUID
      );
    }
  }, [appointments]);

  useEffect(() => {
    if (appointments.length && appointments[appointmentIndex].time) {
      const validAppointments = appointments.filter((item) => item.time);
      addToCart(validAppointments);
      if (!appointments.find((item) => !item.time)) {
        onAddMoreClick();
      }
    }
  }, [appointments]);

  function getDropDownLabelForServices({ name, duration, basePrice }) {
    return (
      <>
        <Box className="d-flex">
          <Box className="d-flex flex-column flex-grow-1">
            <Typography>{name}</Typography>
            <Typography variant="body2">{duration} mins</Typography>
          </Box>
          <Box className="px-2 d-flex align-items-center">
            <Typography variant="body2">
              <span className="ff-helvetica-neue">&#8377;</span> {basePrice}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  function getDropDownLabelForStylist({ name, rank }) {
    return (
      <>
        <Box>
          <Box className="d-flex justify-content-between">
            <Typography>{name}</Typography>
            <Typography variant="body2">{rank}</Typography>
          </Box>
        </Box>
      </>
    );
  }

  function extractServiceList(data) {
    return data.reduce((acc, service) => {
      acc.push(
        ...service.items
          .filter((item) => !item.variations || !item.variations.length)
          .map((item) => {
            return {
              label: getDropDownLabelForServices({
                name: item.name,
                duration: item.duration_minutes,
                basePrice: item.price.price_base,
              }),
              name: item.name,
              value: item.uuid,
              variationuuid: null,
              price: item.price.price_base,
              duration: item.duration_minutes,
            };
          })
      );
      acc.push(
        ...service.items
          .filter((item) => item.variations || item.variations.length)
          .reduce((acc, item) => {
            acc.push(
              ...item.variations.map((variation) => {
                return {
                  label: getDropDownLabelForServices({
                    name: `${item.name} - ${variation.name}`,
                    duration: item.duration_minutes,
                    basePrice: variation.price.price_base,
                  }),
                  name: `${item.name} - ${variation.name}`,
                  value: item.uuid,
                  variationuuid: variation.uuid,
                  price: variation.price.price_base,
                  duration: item.duration_minutes,
                };
              })
            );
            return acc;
          }, [])
      );
      return acc;
    }, []);
  }

  async function getServicesList(currentDate) {
    try {
      const { data } = await fetchServiceList({ date: currentDate, salonUUID });
      const servicesList = extractServiceList(data.services);
      const stylists = [
        ...data.personnels.STYLIST,
        ...data.personnels.NON_STYLIST,
      ].map((item) => {
        return {
          label: getDropDownLabelForStylist({
            name: `${item.basic.name_first} ${item.basic.name_last}`,
            rank: item.basic.rank,
          }),
          name: `${item.basic.name_first} ${item.basic.name_last}`,
          value: item.uuid,
          data: item,
        };
      });
      if (
        location.state &&
        location.state.user &&
        preSelectedStylistCount === 0
      ) {
        const selectedStylist = stylists.find(
          (item) => item.value === location.state.user.uuid
        );
        initializeAppointments({ user: selectedStylist });
        setPreSelectedStylistCount(1);
      }
      setStylistList(stylists);
      setServicesList(servicesList);
    } catch (e) {
      console.log(e);
    }
  }

  async function getStylistList({
    currentDate,
    serviceUUID,
    salonUUID,
    variationUUID,
  }) {
    setLoadingStylistsFor(appointmentIndex);
    try {
      const { data } = await fetchStylistList({
        date: currentDate,
        salonUUID,
        serviceUUID,
        variationUUID,
      });
      const stylists = data.personnels.STYLIST.map((item) => {
        return {
          label: getDropDownLabelForStylist({
            name: `${item.basic.name_first} ${item.basic.name_last}`,
            rank: item.basic.rank,
          }),
          name: `${item.basic.name_first} ${item.basic.name_last}`,
          value: item.uuid,
          slots: Object.values(item.slots).reduce((acc, slots) => {
            const newSlots = slots.map((item) => {
              return {
                label: item.start,
                value: item.start,
                slot: item,
              };
            });
            acc.push(...newSlots);
            return acc;
          }, []),
          data: item,
        };
      });
      setStylistList(stylists);
    } catch (e) {
      setStylistList([]);
    }
    setLoadingStylistsFor(null);
  }

  async function getAvailableSlots(payload, stylistUUID, salonUUID) {
    try {
      const { data } = await getTimeSlot({ payload, stylistUUID, salonUUID });
      const availableSlots = Object.values(data)
        .filter((item) => item.length)
        .map((item) =>
          item.map((slot) => {
            return {
              label: moment()
                .hour(slot.start.split(":")[0])
                .minutes(slot.start.split(":")[1])
                .format("hh:mm a"),
              value: slot.start,
            };
          })
        )
        .reduce((acc, item) => {
          acc.push(...item);
          return acc;
        }, []);
      setAvailableSlots(availableSlots);
    } catch (e) {
      setAvailableSlots([]);
    }
  }

  function onInput(item, fieldType, appointmentIndex) {
    setAppointmentIndex(appointmentIndex);
    appointments[appointmentIndex][fieldType] = item;
    if (fieldType === "service" && !appointments[appointmentIndex].stylist) {
      getStylistList({
        currentDate,
        serviceUUID: item.value,
        salonUUID,
        variationUUID: item.variationuuid,
      });
    }
    setAppointments([...appointments]);
  }

  function initializeAppointments(params = {}) {
    const { user = null } = params;
    const appointments = [
      {
        service: null,
        stylist: user,
        time: null,
        appointmentDate: currentDate,
        timeStamp: moment().format(),
      },
    ];
    setAppointments([...appointments]);
  }

  function onAddMoreClick() {
    appointments.push({
      service: null,
      stylist: null,
      time: null,
      appointmentDate: currentDate,
      timeStamp: moment().format(),
    });
    getServicesList(currentDate);
    setAppointmentIndex(appointmentIndex + 1);
    setAppointments([...appointments]);
  }

  function reset() {
    initializeAppointments();
    setAppointmentIndex(0);
    addToCart([]);
  }

  function onDateChange(date) {
    setCurrentDate(moment(date).format(MOMENT_DATE_FORMAT));
  }

  function removeAppointment(appointmentIndex) {
    if (appointments.length === 1) {
      reset();
      return;
    }
    const newAppointments = appointments.filter(
      (_, index) => index !== appointmentIndex
    );
    setAppointmentIndex(0);
    setAppointments([...newAppointments]);
  }

  return (
    <div className="flex-grow-1 px-7 pb-7 position-relative d-flex flex-column">
      <div className="d-flex justify-content-between">
        <div className="date-picker-container">
          <DatePicker
            showPopperArrow={true}
            selected={new Date(currentDate)}
            minDate={new Date()}
            value={currentDate}
            onChange={(date) => onDateChange(date)}
            customInput={
              <Box className="d-flex align-items-center px-2">
                <Typography className="mr-2">
                  {moment(currentDate).format("dddd D MMMM, YYYY")}
                </Typography>
                <i
                  style={{ cursor: "pointer" }}
                  className="fa fa-angle-down date-picker-icon"
                ></i>
              </Box>
            }
            className="date-picker"
          />
        </div>
        <Button
          variant="contained"
          startIcon={<Refresh />}
          onClick={reset}
          disableElevation
          classes={{ root: classes.resetCta }}
        >
          Reset
        </Button>
      </div>
      <div
        className="flex-grow-1 overflow-auto mt-3"
        style={{ height: "100px" }}
      >
        {appointments.map((item, index) => {
          return (
            <AddAppointment
              key={item.timeStamp}
              servicesList={servicesList}
              stylistList={stylistList}
              availableSlots={availableSlots}
              onChange={(value, fieldType) => onInput(value, fieldType, index)}
              remove={() => removeAppointment(index)}
              appointments={appointments}
              appointmentIndex={index}
              loadingStylistsFor={loadingStylistsFor}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Schedule;
