import React from 'react';
import './TooltipInput.css';
import Tooltip from '@material-ui/core/Tooltip';


const TooltipInput = ({title,placement}) => {
  return (
    <Tooltip title={title} placement={placement} className="ml-1 TooltipInput">
      <i className="fas fa-info-circle"></i>
    </Tooltip>
  )
}

export default TooltipInput;