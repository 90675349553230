import axios from "axios";

export function getAllDeals(salonuuid,nextLink){
  if(nextLink && nextLink!=="initial"){
    return axios.get(nextLink)
  }
  return axios.get("salons/"+salonuuid+"/deals")
}

export function getStateDeals(salonuuid,state,nextLink){
  if(nextLink && nextLink!=="initial"){
    return axios.get(nextLink)
  }
  return axios.get("salons/"+salonuuid+"/deals?state="+state)
}

