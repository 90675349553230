import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useFormik } from "formik";
import { Schedule as ScheduleIcon } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

function Step2Form({
  isCurrent,
  possibleValues,
  initialValues,
  formSchema,
  setFormValidity,
  getFormValues,
}) {
  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    setFormValidity(formik.isValid);
  }, [formik]);

  useEffect(() => {
    getFormValues(formik.values);
  }, [formik.values]);

  function appendTimewithDate(time) {
    const [hour, minute] = time.split(":");
    return moment()
      .hour(hour)
      .minute(minute);
  }

  function onUserInput(value) {
    formik.handleBlur(value);
    formik.handleChange(value);
  }

  return (
    <div
      className="pb-5"
      data-wizard-type="step-content"
      data-wizard-state={isCurrent ? "current" : ""}
    >
      <div className="h4 mb-10 font-weight-bold text-dark">
        Enter Business Operation Details
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label>Opening time</label>
            <div className="d-flex align-items-center rounded-lg border">
              <input
                type="time"
                className="form-control form-control-solid form-control-lg w-100"
                name="openingTime"
                value={moment(formik.values.openingTime).format("HH:mm")}
                onChange={(e) => {
                  onUserInput({
                    target: {
                      name: "openingTime",
                      value: appendTimewithDate(e.target.value),
                    },
                  });
                }}
              />
            </div>
            {formik.errors.openingTime ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.openingTime}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group">
            <label>Closing time</label>
            <div className="d-flex align-items-center rounded-lg border">
              <input
                type="time"
                className="form-control form-control-solid form-control-lg"
                name="closingTime"
                value={moment(formik.values.closingTime).format("HH:mm")}
                onChange={(e) => {
                  onUserInput({
                    target: {
                      name: "closingTime",
                      value: appendTimewithDate(e.target.value),
                    },
                  });
                }}
              />
            </div>
            {!formik.values.openingTime || formik.errors.closingTime ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {!formik.values.openingTime
                    ? "Please select Opening time"
                    : formik.errors.closingTime}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="mb-5 font-weight-bold text-dark">Operation days</div>
      <div className="mb-10">
        {possibleValues.operationalDays.map((item) => {
          return (
            <label key={item} className="checkbox checkbox-outline mr-5 mb-3">
              <input
                type="checkbox"
                name="operationalDays"
                value={item}
                onChange={onUserInput}
                defaultChecked={formik.values.operationalDays.includes(item)}
              />
              <span></span>
              {item}
            </label>
          );
        })}
      </div>

      <div className="mb-5 font-weight-bold text-dark">Available Amenities</div>
      <div className="mb-10">
        {possibleValues.amenities.map((item) => {
          return (
            <label key={item} className="checkbox checkbox-outline mr-5 mb-3">
              <input
                type="checkbox"
                name="amenities"
                value={item}
                onChange={onUserInput}
                defaultChecked={formik.values.amenities.includes(item)}
              />
              <span></span>
              {item}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default Step2Form;
