import React, { useState, useEffect } from "react";
import "./style.css";

import {connect} from 'react-redux';

import WizardNavStep from "./wizardNavStep";
import Step1 from '../FormSteps/Step1';
import Step2 from '../FormSteps/Step2';
import Step3 from '../FormSteps/Step3';
import { wizardStepsData, validate1, validate2, validate4 } from './constants';

import { useFormik } from 'formik';

import { addDraftStylist, getOperationData, addOperationDetails, getAllServicesOfSaloon, addServiceDetails, updateBasicStylist, getBasicData, getPersonnelServices } from '../crud';

import { getDraftPayLoad, getOperationPayLoad, getPersonnelCategories, getServices, getSelectedServices, getOperationDays, fillForm1, fillForm2 } from '../helpers';

// Switches
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";



const StylistOnBoardingForm = (props) => {

    const {currentSalon:{salon:{uuid:currentSalonuuid}}} = props.auth;
    console.log(currentSalonuuid);

    const [currentWizardState, setCurrentWizardState] = useState(1);
    const [services, setServices] = useState([]);
    const [personnelCategoriesData, setPersonnelCategoriesData] = useState({});
    const [personnelCategories, setPersonnelCategories] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [days, setDays] = useState([]);
    const [personnel_uuid, setPersonnel_uuid] = useState("");

    useEffect(() => {
        async function getBasicFormData(personnel_uuid) {
            const response = await getBasicData(personnel_uuid,currentSalonuuid);
            fillForm1(response.data.data, formik1.resetForm);
            console.log(response);
        }
        if (props.location.state) {
            setPersonnel_uuid(props.location.state.personnel_uuid);
            try {
                getBasicFormData(props.location.state.personnel_uuid);
            } catch (err) {
                console.log(err);
            }


            console.log(props.location.state.personnel_uuid);
        }
    }, [])

    const formik1 = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            email: '',
            phone: '',
            gender: '',
            empstartdate: ''
        },
        validationSchema: validate1,
        onSubmit: async (values) => {
            if (currentWizardState < 4) {
                const payLoad = getDraftPayLoad(values);
                let response;
                let operationData;
                try {

                    if (personnel_uuid === "") {
                        response = await addDraftStylist(payLoad,currentSalonuuid);
                        console.log(response);
                        setPersonnel_uuid(response.data.data.uuid);

                        operationData = await getOperationData(response.data.data.uuid,currentSalonuuid);
                        console.log(operationData);

                        const operationalDays = getOperationDays(operationData.data.data_for_input.salon_operational_days);
                        setDays(operationalDays);
                    }
                    else {
                        response = await updateBasicStylist(payLoad, personnel_uuid,currentSalonuuid);
                        console.log(response);
                        operationData = await getOperationData(personnel_uuid,currentSalonuuid);
                        console.log(operationData);


                        // ranks to be populated according to category
                        if (operationData.data.data.personnel_rank) {
                            setRanks(operationData.data.data_for_input.personnel_categories[operationData.data.data.personnel_rank.basic.personnel_category.name].ranks);

                            const operationalDays = getOperationDays(operationData.data.data_for_input.salon_operational_days, operationData.data.data.operational_days);
                            setDays(operationalDays);

                            fillForm2(operationData.data.data, formik2.resetForm);
                        }
                        else{
                            const operationalDays = getOperationDays(operationData.data.data_for_input.salon_operational_days);
                        setDays(operationalDays);
                        }




                        


                    }

                    setCurrentWizardState(currentWizardState + 1);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                } catch (err) {
                    console.log(err);
                }
                formik2.setFieldValue("saloonstarttime", operationData.data.data_for_input.salon_timing.open);
                formik2.setFieldValue("saloonendtime", operationData.data.data_for_input.salon_timing.close);

                setPersonnelCategoriesData(operationData.data.data_for_input.personnel_categories);
                console.log(personnelCategoriesData);

                const categories = getPersonnelCategories(operationData.data.data_for_input.personnel_categories);
                setPersonnelCategories(categories);

            }

        },
    });

    const formik2 = useFormik({
        initialValues: {
            category: '',
            rank: '',
            shiftstarttime: '00:00:00',
            saloonstarttime: '00:00:00',
            shiftendtime: '00:00:00',
            saloonendtime: '00:00:00',
            workingdays: []
        },
        validationSchema: validate2,
        onSubmit: async (values) => {
            if (currentWizardState < 4) {
                const payLoad = getOperationPayLoad(values);
                try {
                    const response = await addOperationDetails(payLoad, personnel_uuid,currentSalonuuid);
                    console.log(response);

                    setCurrentWizardState(currentWizardState + 1);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });

                    let personnelServiceData = await getPersonnelServices(personnel_uuid,currentSalonuuid);
                    console.log(personnelServiceData);
                    personnelServiceData = getServices(personnelServiceData.data.data, values.rank);

                    setServices(personnelServiceData);
                } catch (err) {
                    console.log(err);
                }

            }
        },
    });

    const formik4 = useFormik({
        initialValues: {
            productcommission: 0,
            servicescommission: 0,
            dealscommission: 0,
            onlinebooking: false,
            appaccess: false,
            enableprofile: false
        },
        validationSchema: validate4,
        onSubmit: values => {
            props.history.push('/stylists');
        },
    });

    const handleServiceSubmit = async () => {
        const payLoad = getSelectedServices(services);
        try {
            const response = await addServiceDetails(payLoad, personnel_uuid,currentSalonuuid);
            console.log(response);
            setCurrentWizardState(currentWizardState + 1);
        } catch (err) {
            console.log(err)
        }

    }

    const handleCategoryChange = (e) => {
        formik2.setFieldValue("rank", "");
        formik2.handleChange(e);
        setRanks(personnelCategoriesData[e.target.value].ranks);
    }
    const nextStep = () => {
        if (currentWizardState === 1) {
            formik1.handleSubmit();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else if (currentWizardState === 2) {
            formik2.handleSubmit();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else if (currentWizardState === 3) {
            handleServiceSubmit()
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else {
            formik4.handleSubmit();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }

    };

    const prevStep = () => {
        if (currentWizardState > 1) {
            setCurrentWizardState(currentWizardState - 1);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    };

    const handleService = (typeuuid) => {
        let currServices = [...services];
        let service = currServices.find((service) => service.uuid === typeuuid);

        if (service.checked === true) {
            service.checked = false;
            for (let singleService of service.services) {
                singleService.checked = false;
            }
        } else {
            service.checked = true;
            for (let singleService of service.services) {
                if (singleService.disabled === false) {
                    singleService.checked = true;
                }

            }
        }

        setServices(currServices);
    };

    const handleSingleService = (typeuuid, serviceuuid) => {
        let currServices = [...services];
        let service = currServices.find((service) => service.uuid === typeuuid);
        for (let singleService of service.services) {
            if (singleService.uuid === serviceuuid) {
                singleService.checked = !singleService.checked
            }
        }
        setServices(currServices);
    }

    const handleWorkingDays = (e) => {
        const val = e.target.value
        let newWorkingDays = formik2.values.workingdays
        const i = newWorkingDays.indexOf(val);
        if (i !== -1) {
            newWorkingDays.splice(i, 1)
        } else {
            newWorkingDays.push(val);
        }
        formik2.setFieldValue("workingdays", newWorkingDays);
        const newDays = days.map(day => {
            if (day.value === val) {
                day.checked = !day.checked
            }
            return day;
        })
        setDays(newDays);
    }

    const getInputClassesform1 = (fieldname) => {
        if (formik1.touched[fieldname] && formik1.errors[fieldname]) {
            return "is-invalid";
        }

        return "";
    };

    const getInputClassesform4 = (fieldname) => {
        if (formik4.touched[fieldname] && formik4.errors[fieldname]) {
            return "is-invalid";
        }

        return "";
    };


    {/*logic to display what buttons*/ }
    let currentStep = null;
    if (currentWizardState === 1) {
        currentStep = "first";
    } else if (currentWizardState === 4) {
        currentStep = "last";
    } else {
        currentStep = "between";
    }

    return (
        <div className="Wizard4">
            {/*begin entry*/}
            <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">
                    <div className="card card-custom card-transparent">
                        <div className="card-body p-0">
                            {/*begin: Wizard //currentStep below defines which button is visible*/}
                            <div
                                className="wizard wizard-4"
                                id="kt_wizard"
                                data-wizard-state={currentStep}
                                data-wizard-clickable="false"
                            >
                                {/*begin: Wizard Nav*/}
                                <div className="wizard-nav">
                                    <div className="wizard-steps">
                                        {/*begin::Wizard Step Nav*/}
                                        {wizardStepsData.map((data) => (
                                            <WizardNavStep
                                                key={data.number}
                                                number={data.number}
                                                title={data.title}
                                                subTitle={data.subTitle}
                                                currentState={
                                                    currentWizardState === data.number
                                                        ? "current"
                                                        : "notCurrent"
                                                }
                                            />
                                        ))}
                                        {/*end::Wizard Step Nav*/}
                                    </div>
                                </div>
                                {/*end: Wizard Nav*/}
                                {/*begin: Wizard Body*/}
                                <div className="card card-custom card-shadowless rounded-top-0">
                                    <div className="card-body p-0">
                                        <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                                            <div className="col-xl-12 col-xxl-7">
                                                {/*begin: Wizard Form*/}
                                                <Step1
                                                    currentWizardState={currentWizardState}
                                                    getInputClassesform1={getInputClassesform1}
                                                    formik1={formik1}
                                                />

                                                <Step2
                                                    currentWizardState={currentWizardState}
                                                    formik2={formik2}
                                                    handleCategoryChange={handleCategoryChange}
                                                    personnelCategories={personnelCategories}
                                                    ranks={ranks}
                                                    days={days}
                                                    handleWorkingDays={handleWorkingDays}
                                                />

                                                <Step3
                                                    currentWizardState={currentWizardState}
                                                    services={services}
                                                    handleService={handleService}
                                                    handleSingleService={handleSingleService}
                                                />

                                                <form>
                                                    {/*begin: Wizard Step 4*/}
                                                    <div
                                                        className="pb-5"
                                                        data-wizard-type="step-content"
                                                        data-wizard-state={
                                                            currentWizardState === 4
                                                                ? "current"
                                                                : "notCurrent"
                                                        }
                                                    >
                                                        {/*begin::Section*/}
                                                        <h4 className="mb-10 font-weight-bold text-dark">
                                                            Setup Permissions
                                                            </h4>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                {/*begin::Input*/}
                                                                <div className="form-group">
                                                                    <label>Product Commission</label>
                                                                    <input
                                                                        type="number"
                                                                        className={`form-control form-control-solid form-control-lg ${getInputClassesform4("productcommission")}`}
                                                                        name="productcommission"
                                                                        id="productcommission"
                                                                        value={formik4.values.productcommission}
                                                                        onBlur={formik4.handleBlur}
                                                                        onChange={formik4.handleChange}
                                                                    />
                                                                    {formik4.touched.productcommission && formik4.errors.productcommission ? (
                                                                        <div className="fv-plugins-message-container">
                                                                            <div className="fv-help-block">{formik4.errors.productcommission}</div>
                                                                        </div>
                                                                    ) : null}
                                                                    {/* <span className="form-text text-muted">joining date</span> */}
                                                                </div>
                                                                {/*end::Input*/}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                {/*begin::Input*/}
                                                                <div className="form-group">
                                                                    <label>Services Commission</label>
                                                                    <input
                                                                        type="number"
                                                                        className={`form-control form-control-solid form-control-lg ${getInputClassesform4("servicescommission")}`}
                                                                        name="servicescommission"
                                                                        id="servicescommission"
                                                                        value={formik4.values.servicescommission}
                                                                        onBlur={formik4.handleBlur}
                                                                        onChange={formik4.handleChange}
                                                                    />
                                                                    {formik4.touched.servicescommission && formik4.errors.servicescommission ? (
                                                                        <div className="fv-plugins-message-container">
                                                                            <div className="fv-help-block">{formik4.errors.servicescommission}</div>
                                                                        </div>
                                                                    ) : null}
                                                                    {/* <span className="form-text text-muted">joining date</span> */}
                                                                </div>
                                                                {/*end::Input*/}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                {/*begin::Input*/}
                                                                <div className="form-group">
                                                                    <label>Deals Commission</label>
                                                                    <input
                                                                        type="number"
                                                                        className={`form-control form-control-solid form-control-lg ${getInputClassesform4("dealscommission")}`}
                                                                        name="dealscommission"
                                                                        id="dealscommission"
                                                                        value={formik4.values.dealscommission}
                                                                        onBlur={formik4.handleBlur}
                                                                        onChange={formik4.handleChange}
                                                                    />
                                                                    {formik4.touched.dealscommission && formik4.errors.dealscommission ? (
                                                                        <div className="fv-plugins-message-container">
                                                                            <div className="fv-help-block">{formik4.errors.dealscommission}</div>
                                                                        </div>
                                                                    ) : null}
                                                                    {/* <span className="form-text text-muted">joining date</span> */}
                                                                </div>
                                                                {/*end::Input*/}
                                                            </div>
                                                        </div>
                                                        <h4 className="mb-10 font-weight-bold text-dark">
                                                            Setup Permissions
                                                            </h4>
                                                        <FormControlLabel
                                                            className="d-block"
                                                            control={<Switch name="onlinebooking"
                                                                id="onlinebooking" checked={formik4.values.onlinebooking} onChange={formik4.handleChange} />}
                                                            label="Online Booking"
                                                        />
                                                        <FormControlLabel
                                                            className="d-block"
                                                            control={<Switch name="appaccess"
                                                                id="appaccess" checked={formik4.values.appaccess} onChange={formik4.handleChange} />}
                                                            label="App Access"
                                                        />
                                                        <FormControlLabel
                                                            className="d-block"
                                                            control={<Switch name="enableprofile"
                                                                id="enableprofile" checked={formik4.values.enableprofile} onChange={formik4.handleChange} />}
                                                            label="Enable Profile"
                                                        />

                                                        {/*end::Section*/}
                                                    </div>
                                                    {/*end: Wizard Step 4*/}
                                                    {/*begin: Wizard Actions*/}
                                                    <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                                        <div className="mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                                                data-wizard-type="action-prev"
                                                                onClick={prevStep}
                                                            >
                                                                Previous
                                                                </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                                                                data-wizard-type="action-submit"
                                                                onClick={nextStep}
                                                            >
                                                                Submit
                                                                </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                                                data-wizard-type="action-next"
                                                                onClick={nextStep}
                                                            >
                                                                Next
                                                                </button>
                                                        </div>
                                                    </div>
                                                    {/*end: Wizard Actions*/}
                                                </form>
                                                {/*end: Wizard Form*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*end: Wizard Bpdy*/}
                            </div>
                            {/*end: Wizard*/}
                        </div>
                    </div>
                </div>
                {/*end::Container*/}
            </div>
            {/*end entry */}
        </div>
    );
}

const mapStateToProps = (state) =>({
    auth:state.auth
})

export default connect(mapStateToProps)(StylistOnBoardingForm);
