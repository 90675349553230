import React, { useState } from 'react';
import SingleDeal from './SingleDeal';

import moment from "moment";

const UserDeals = ({ deals, dealsNext, getDeals }) => {
  console.log(deals, "deals from deals page")
  const [loading, setLoading] = useState(false)


  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log("hit hit")
    if (bottom && dealsNext) {
      console.log(bottom)
      setLoading(true)
      getDeals()
      setLoading(false)
    }
  }

  return (
    <div className="UserAppointments p-5 ">

      {
        deals.length === 0 &&
        <h5 className="text-center">No deals yet</h5>
      }
      <div
      style={{
        maxHeight:"480px",
        overflow:"auto"
      }}
      onScroll={handleScroll}>
        {deals.map(deal => <SingleDeal key={deal.uuid}
          data={{
            image: deal.items[0].deal.basic.main_image.basic.url_full,
            bgColor: deal.items[0].deal.basic.main_color_style.basic.hex_code,
            title: deal.items[0].deal.basic.name,
            expiry: moment(deal.items[0].deal.basic.expires_at.date).format('DD MMM, YYYY'),
            price: deal.basic.price.price_net,
            payState: deal.basic.payment_state,
            totalSession: deal.items[0].items[0].session.total,
            usedSession: deal.items[0].items[0].session.used,
            leftSession: deal.items[0].items[0].session.left,
            dealState: deal.basic.state,
          }} />)}

        {<h1>{loading ? "Loading" : ""}</h1>}
      </div>
    </div>

  );
}

export default UserDeals;