import React from 'react';

const Search = ({searchConsumer,onSearchClient,selectedServices}) => {
  return (
    <div
      id="kt_quick_search_dropdown"
      className="quick-search quick-search-dropdown quickR searchContainer w-100"
    >
      <form onSubmit={(e) => e.preventDefault()} className="quick-search-form quickSearchForm">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <span className="svg-icon svg-icon-lg">
                <i
                  className="fa fa-search"
                  style={{ paddingLeft: "20px" }}
                  aria-hidden="true"
                ></i>
              </span>
            </span>
          </div>
          <input
            type="text"
            onChange={(event) => onSearchClient(event)}
            value={searchConsumer}
            placeholder="Search customer by mobile number..."
            className="form-control"
            disabled={selectedServices.length === 0}
          />

          {searchConsumer.length > 0 ? (
            <div className="searchCross">
              <span
                className="input-group-text"
                style={{
                  border: "1px solid white",
                  backgroundColor: "white",
                }}
              >
                <i
                  className="quick-search-close ki ki-close icon-sm text-muted"
                  onClick={() => onSearchClient("")}
                  style={{ display: "flex" }}
                ></i>
              </span>
            </div>
          ) : (
              ""
            )}
        </div>
      </form>

    </div>
  )
}

export default Search;