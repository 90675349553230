import React from 'react';
import './formatters.css';
import { Avatar } from "@material-ui/core";

import {Link} from 'react-router-dom';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const sortCaret = (order, column) => {
  if (!order) return (
    <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")}/>
    </span>
  );
  else if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")}/>
      </span>
    );
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")}/>
      </span>
    );
  return null;
};


export function nameFormatter(cell, row) {
  return (
    <Link to={`users/${row.id}`}>
    <div className="d-flex align-items-center justify-content-center ST-nameFormatter">
      <Avatar className="ST-nameInitials">{cell.slice(0,1)}</Avatar>
      <div className="d-flex flex-column ml-3">
        <span style={{color:"black"}}>{cell}</span>
      </div>
    </div>
    </Link>
    
  );
};

export function typeFormatter(cell, row) {
  if (cell === "Retail") {
    return (
      <>
      <span className="ST-typeFormatter-r"></span>
      <span className="">{cell}</span>
      </>
    )
  } else {
    return(
      <>
      <span className="ST-typeFormatter-c"></span>
      <span className="">{cell}</span>
      </>
    )
  }
}

export function statusFormatter(cell, row) {
  const cl = getStatusClass(cell)
  return (
    <span className={`label label-lg label-light-${cl} gb-label label-inline`}>
      {cell}
    </span>
  );
};

export function actionFormatter(cell, row) {
  return (
    <div>
      <i className="fas fa-edit mr-2  actionFormatter-action-blue blue"></i>
      <i className="fas fa-trash  actionFormatter-action-red red"></i>
    </div>
  )
}

export function modeFormatter(cell, row) {
  return (
    <>
    <span className="ST-mode-formatter"></span>
    <span className="">{cell}</span>
    </>
  )
}

const getStatusClass=(type)=>{
  if(type==="COMPLETED") return "success"
  if(type==="PAYMENT_UNDERWAY") return "warning"
  if(type==="CONFIRMED") return "primary"
  if(type==="FAILED") return "danger"
}