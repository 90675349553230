import React from "react";
import { Form } from "react-bootstrap";

export default function SelectInput({
  placeHolder,
  data = [],
  onChange,
  name,
  value,
  disabled,
}) {
  return (
    <div>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Control
          as="select"
          value={value || ""}
          name={name}
          onChange={onChange}
          disabled={disabled}
        >
          <option value={""} disabled>
            {placeHolder}
          </option>
          {data.map((item, index) => {
            return (
              <option key={String(index)} value={item.value}>
                {item.name}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    </div>
  );
}
