import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const useStyles = makeStyles(() => ({
  bannerTxtContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  submitBtn: {
    width: "193px",
    height: "43px",
    fontSize: "14px",
    lineHeight: "1.5",
    "&:focus": {
      outline: "none",
    },
  },
}));

function SalonOnboarding() {
  const classes = useStyles();
  const [enteredSalonUUID, setEnteredSalonUUID] = useState("");
  const history = useHistory();

  function onSubmitClick() {
    history.push("/salon-onboarding/forms", { salonUUID: enteredSalonUUID });
  }

  return (
    <Container className="h-100 d-flex flex-column align-items-center">
      <Box className="position-relative mb-20">
        <Box>
          <img
            className="w-100"
            src={toAbsoluteUrl("/media/blue-planets-desktop-2x.png")}
          />
        </Box>
        <Box className={classes.bannerTxtContainer}>
          <Typography variant="h4" className="text-white font-weight-bold mb-2">
            Salon Onboarding
          </Typography>
          <Typography variant="body1" className="text-white">
            Lets’s help Salons to grow their business
          </Typography>
        </Box>
      </Box>
      <Box className="w-50">
        <Typography variant="h6" className="mb-3">
          Enter Salon ID
        </Typography>
        <Typography className="mb-2">Salon UUID</Typography>
        <input
          autoFocus
          type="text"
          className="form-control form-control-lg mb-1"
          name="Salon UUID"
          onChange={(e) => setEnteredSalonUUID(e.target.value)}
          value={enteredSalonUUID}
        />
        <Typography>E.g. 50d9ac97-a214-4c56-9559-0a52cde270ad</Typography>
        <Box className="d-flex justify-content-center mt-15">
          <Button
            className="btn btn-primary p-3"
            classes={{ root: classes.submitBtn }}
            variant="contained"
            disableElevation
            disabled={!enteredSalonUUID.length}
            onClick={onSubmitClick}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default SalonOnboarding;
