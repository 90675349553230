import React from 'react';
import './TopServices.css';

import Paper from '@material-ui/core/Paper';

const TopServices = (props) => {
  const col1 = props.services ? "Service" : "Staff";
  return (
    <Paper className="TopServices pt-3 pb-5 px-5">
      <h5 className="pt-5">{props.label}</h5>

      <div className="TopServices-header">
        <p className="text-muted m-0">{col1}</p>
        <p className="text-muted m-0">This month</p>
        <p className="text-muted m-0">Last month</p>
      </div>
      {props.services ?
        <ul className="TopServices-list">
          <li className="TopServices-list-item">
            <p>Blow Dry</p>
            <p>1</p>
            <p>3</p>
          </li>
          <li className="TopServices-list-item">
            <p>Haircut</p>
            <p>2</p>
            <p>2</p>
          </li>
        </ul> :
        <ul className="TopServices-list">
          <li className="TopServices-list-item">
            <p>Sharukh</p>
            <p>1</p>
            <p>3</p>
          </li>
          <li className="TopServices-list-item">
            <p>Vinay</p>
            <p>2</p>
            <p>1</p>
          </li>
        </ul>
      }
    </Paper>
  )
}

export default TopServices;