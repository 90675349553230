import React from 'react';

import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';

import UserAppointments from './UserAppointments';
import UserDeals from './UserDeals';


const DetailCard = (props) => {
  return (
    <div className="DetailCard">
      <Paper className="p-4">
        <Tabs defaultActiveKey="Appointments">
          <Tab eventKey="Appointments" title="Appointments">
            <UserAppointments upcoming={props.upcoming} upcomingNext={props.upcomingNext}
              getUpAppointments={props.getUpAppointments} past={props.past}
              pastNext={props.pastNext}
              getPaAppointments={props.getPaAppointments}
            />
          </Tab>
          <Tab eventKey="Deals" title="Deals">
            <UserDeals
              deals={props.deals}
              dealsNext={props.dealsNext}
              getDeals={props.getDeals}
            />
          </Tab>
          <Tab eventKey="Invoices" title="Invoices (coming soon)" disabled>
          </Tab>
        </Tabs>
      </Paper>
    </div>
  )
}
export default DetailCard;