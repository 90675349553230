import React from "react";

import {
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { Close as CloseIcon, Add as AddIcon, Ru } from "@material-ui/icons";

import { Form, Col, InputGroup, FormControl } from "react-bootstrap";

import moment from "moment";
import { convertToRupee } from "../../../../../_metronic/_helpers";

const SelectedServices = ({ items, removeService }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        overflow: "auto",
        marginTop:"10px"
      }}
    >
      {items.map((item) => {
        return (
          <div
            className="p-3"
            key={item.id?item.id:item.appointment.uuid}
            style={{
              width: "100%",
              border: "1px solid #E7E9F5",
              borderRadius: "4px",
              backgroundColor: "#FFFFFF",
              boxShadow: "0 2px 4px 0 #E7E9F5 !important",
              marginBottom: "15px",
            }}
          >
            <CardContent className="p-0">
              <div className="d-flex align-items-center justify-content-between mb-6">
                <div>
                  <Typography className="font-weight-bold" variant="h6">
                    {item.service.name}
                  </Typography>
                  <Typography className="font-weight-light">
                    {`${moment
                      .duration(item.service.duration_minutes, "minutes")
                      .humanize()} with ${
                      item.appointment?.personnel.basic.name_first
                    } ${item.appointment?.personnel.basic.name_last}`}
                  </Typography>
                </div>
                <div className="d-flex">
                  <Typography className="mr-3 font-weight-bold">
                    <span>{convertToRupee(item.service.price.base)}</span>
                  </Typography>
                  <Button
                    onClick={() => removeService(item)}
                    className={`p-0 w-0`}
                    style={{ minWidth: "auto" }}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </div>
              {/* display elements */}
              <div>
                <Form.Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label htmlFor="inlineFormInputGroup">
                      Price
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Rs</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={item.service.price.base}
                        disabled
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="auto">
                    <Form.Label htmlFor="inlineFormInput">Stylist</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder={`${item.appointment.personnel.basic.name_first} ${item.appointment.personnel.basic.name_last}`}
                      disabled
                    />
                  </Col>

                  <Col xs="auto">
                    <Form.Label htmlFor="inlineFormInput">Discount</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="No discount"
                      disabled
                    />
                  </Col>
                </Form.Row>
              </div>
              {/* end display elements */}
            </CardContent>
          </div>
        );
      })}
    </div>
  );
};

export default SelectedServices;
