import React, { useState } from 'react';
import './TableTopBar.css';

import { Modal, Button, Form } from 'react-bootstrap';



const TableTopBar = ({ categories, handleNewSubmit }) => {
  const [show, setShow] = useState(false);
  const [category,setCategory] = useState("");
  const [rank,setRank] = useState("")

  const handleClose = () =>{
    setRank("");
    setCategory("");
    setShow(false);
  } 
  const handleShow = () => setShow(true);

  const handleNewRank = (e) =>{
    e.preventDefault();
    const payLoad = {
      items:[
        {
          personnel_category_name:category,
          name:rank
        }
      ]
    }
    handleNewSubmit(payLoad);
    setRank("");
    setCategory("");
    setShow(false);
  }

  const options = categories.map(category => (
    <option key={category.name} value={category.name}>{category.display_name}</option>
  ))

  return (
    <div className="TableTopBar">
      <p className="TableTopBar-heading">Available Ranks</p>
      <div className="TableTopBar-add" onClick={handleShow}>
        <i className="fas fa-plus fa-xs mr-1  TableTopBar-add-icon"></i>
        Add Rank
      </div>

      {/* new rank Modal */}
      <Modal backdrop="static" centered show={show} onHide={handleClose}>
        <Form onSubmit={handleNewRank}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Rank</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control as="select" value={category} required onChange={(e)=>setCategory(e.target.value)}>
                <option value="" disabled>Select</option>
                {options}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Rank Name</Form.Label>
              <Form.Control type="text" value={rank} required onChange={(e)=>setRank(e.target.value)} />
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
          </Button>
            <Button className="TableTopBar-submit px-5" type="submit" variant="primary">
              Save
          </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}

export default TableTopBar;