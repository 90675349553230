import moment from 'moment';

export function getTableRowData(data){
  const tableData = data.map(ob=>({
    uuid:ob.uuid,
    ref:ob.order.basic.display_id,
    client:ob.creator.basic.name_first+" "+ob.creator.basic.name_last,
    date:moment(ob.order.basic.checkout_at.date).format('D MMM, YYYY'),
    orderId:ob.order.uuid,
    price:ob.order.basic.price.price_net,
    status:ob.order.basic.state,
  }))

  return tableData
}


export function getDateLink(date){
  const startDate = moment(date.startDate).format("YYYY-MM-DD")
    const startTime = moment(date.startDate).format("HH:mm")
    const endDate = moment(date.endDate).format("YYYY-MM-DD")
    const endTime = moment(date.endDate).format("HH:mm")
    
    const link = `&start_date=${startDate}&&start_time=${startTime}&end_date=${endDate}&end_time=${endTime}`

    return link;
}
