import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Toolbar,
  Box,
  Modal,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Close as CloseIcon } from "@material-ui/icons";
import Schedule from "./schedule";
import Cart from "./cart";
import {
  searchConsumer,
  generateIMDToken,
  saveOrder,
} from "../appointmentsCrud";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

function CreateAppointment({ auth }) {
  const location = useLocation();
  const history = useHistory();
  const [customerSearchQuery, setCustomerSearchQuery] = useState("");
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [savingAppointment, setSavingAppointment] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { currentSalon } = auth;

  const {
    salon: { uuid: salonUUID },
  } = currentSalon;

  useEffect(() => {
    onClickingSearch();
  }, [customerSearchQuery]);

  function onEnteringSearch(query) {
    if (!Number.isInteger(Number(query))) return;
    setCustomerSearchQuery(query);
  }

  async function onClickingSearch() {
    if (customerSearchQuery.length === 0) setSearchedCustomers([]);
    if (customerSearchQuery.length < 5) return;
    try {
      const {
        data: { value },
      } = await searchConsumer({ query: customerSearchQuery });
      setSearchedCustomers(
        value.filter((item) => item.basic.name_first && item.basic.name_last)
      );
    } catch (e) {
      setSearchedCustomers([]);
    }
  }

  function addAppointmentsToCart(appointments) {
    setAppointments([...appointments]);
  }

  async function getIMDToken() {
    try {
      const {
        data: { imd_token },
      } = await generateIMDToken();
      return imd_token;
    } catch (e) {
      return null;
    }
  }

  function getServicesPayload() {
    return appointments.map((item) => {
      return {
        service_uuid: item.service.value,
        variation_uuid: item.service.variationuuid,
        personnel_uuid: item.stylist.value,
        on_date: item.appointmentDate,
        at_time: item.time.value,
      };
    });
  }

  async function saveAppointment() {
    setSavingAppointment(true);
    const payload = {
      salon_uuid: salonUUID,
      consumer_uuid: selectedCustomer.uuid,
      services: getServicesPayload(),
    };
    try {
      const imd_token = await getIMDToken();
      payload.imd_token = imd_token;
      await saveOrder({ payload });
      enqueueSnackbar("Appointment created succesfully!", {
        anchorOrigin: { vertical: "top", horizontal: "right" },
        variant: "success",
        autoHideDuration: 3000,
      });
      history.goBack();
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Appointment creation failed!", {
        anchorOrigin: { vertical: "top", horizontal: "right" },
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setSavingAppointment(false);
  }

  function onCreatingCustomer(data) {
    const customerData = {
      uuid: data.uuid,
      basic: {
        name_first: data.name_first,
        name_last: data.name_last,
        mobile: data.mobile,
        email: data.email,
      },
    };
    setSelectedCustomer(customerData);
  }

  function deleteAppointment(appointmentIndex) {
    setAppointments(appointments.filter((_, idx) => idx !== appointmentIndex));
  }

  return (
    <Modal open={location.pathname.includes("create-appointment")}>
      <Box className="bg-white h-100 d-flex flex-column">
        <Toolbar className="d-flex justify-content-between border-bottom mb-3">
          <Typography variant="h6" className="flex-grow-1 text-center">
            New Appointment
          </Typography>
          <IconButton onClick={() => history.goBack()}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Row className="flex-grow-1">
          <Col sm={7} className="d-flex flex-row flex-grow-1 mt-10">
            <Schedule
              cartAppointments={appointments}
              salonUUID={salonUUID}
              addToCart={addAppointmentsToCart}
            />
          </Col>
          <Col sm={5} className="flex-grow-1 border-left p-0 d-flex">
            <Cart
              appointments={appointments}
              onEnteringSearch={onEnteringSearch}
              onClickingSearch={onClickingSearch}
              searchQuery={customerSearchQuery}
              searchedCustomers={searchedCustomers}
              saveAppointment={saveAppointment}
              savingAppointment={savingAppointment}
              onCreatingCustomer={onCreatingCustomer}
              deleteAppointment={deleteAppointment}
              setSelectedCustomer={setSelectedCustomer}
              selectedCustomer={selectedCustomer}
            />
          </Col>
        </Row>
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(CreateAppointment);
