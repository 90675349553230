import React, { useState, useEffect } from 'react';
import '../styles.css';
import { v4 as uuidv4 } from 'uuid';
import AvailableStylist from '../AvailableStylist/AvailableStylist';
import { Form, Button, InputGroup, Row, Col, Dropdown, Badge } from 'react-bootstrap';
import closeIcon from '../assets/img/cancel.svg';
import SVG from "react-inlinesvg";
import Select from 'react-select';
import { getRanksFromData, getTaxesFromData, getServicesFromData, getAllStandardServices, getGenderServed, getAllStaff, getAllStaffArray } from '../helperFunctions/helpers';



let allStandardServices = {}
let allStaff = {}
let allStaffArray = [];
const NewServiceForm = (
  { values, touched, errors, setFieldValue,
    handleChange, handleSubmit, handleBlur, handleRanks, handleTaxes,
    features, setFeatures, handleFeatureChange, handleCloseForm,getAllInputData }
) => {


  
  const [loading, setLoading] = useState(true);
  const [pricingOption, setPricingOption] = useState(false);


  const [ranksAvailable, setRanksAvailable] = useState(null);
  const [remaingTaxes, setRemaingTaxes] = useState(null);
  const [inheritedTaxes, setInheritedTaxes] = useState(null);
  const [serviceTypes, setServiceTypes] = useState(null);
  const [servedGenders, setServedGenders] = useState([]);
  const [selectedStandardServices, setSelectedStandardServices] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);



  useEffect(() => {
    getAllInputData()
      .then(response => {
        console.log(response.data.data);
        const data = { ...response.data.data };

        // setting available ranks [{name:name_of_rank, uuid:rank_uuid}]
        let ranks = getRanksFromData(data);
        console.log(ranks);
        setRanksAvailable(ranks);

        //setting taxes [{label:name_of_tax+%value, value:whole value object received from backend}]
        const [itax, rtax] = getTaxesFromData(data.taxes);
        console.log(itax);
        console.log(rtax);
        setInheritedTaxes(itax);
        setRemaingTaxes(rtax);

        // setting serviceTypes [{name:serviceType_name, uuid:serviceType_uuid}]
        let services = getServicesFromData(data);
        console.log(services);
        setServiceTypes(services);

        //setting allStandardServices {serviceType_uuid:[{name:standardService_name,
        //  uuid:standardService_uuid}]}
        allStandardServices = getAllStandardServices(data);
        console.log(allStandardServices);

        //setting gender served [{name:displayname,value:gendervalue}]
        let genderServed = getGenderServed(data.served_genders);
        console.log(genderServed);
        setServedGenders(genderServed);

        //setting allstaff {rank_uuid:[{designaion,name,uuid}]}
        allStaff = getAllStaff(data.personnel_categories);
        allStaffArray = getAllStaffArray(allStaff);
        setSelectedStaff(allStaffArray);
        console.log(allStaff);

        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [])

  const handleServiceChange = (e) => {
    values.standardService = "";
    handleChange(e);
    setSelectedStandardServices(allStandardServices[e.target.value]);
  }

  const getStaffForRanks = () => {
    console.log(values.staff);
    values.staff = []
    let ranksSelected = { ...values.rankVariation }
    let staff = []
    for (let rank in ranksSelected) {
      staff = staff.concat(allStaff[rank]);
    }
    setSelectedStaff(staff);
  }

  const handleOpenRanks = () => {
    setPricingOption("rank-basis");
    values.serviceVariationName = "RANK";
    values.price = "";
    setSelectedStaff([]);
  }

  const handleCloseRanks = () => {
    setPricingOption(false);
    values.rankVariation = {};
    values.staff = [];
    setFieldValue("serviceVariationName", null)
    setSelectedStaff(allStaffArray);
    console.log(values.rankVariation);
  }

  const handleOpenFeatures = () => {
    values.serviceVariationName = "PRODUCT";
    console.log(values.serviceVariationName)
    values.price = "";
    setFeatures([{
      name: "",
      price_base: 0,
      personnel_rank_uuid: null,
      id: uuidv4()
    }]);
    setPricingOption('feature-basis')

  }

  const handleCloseFeatures = () => {
    setPricingOption(false);
    values.serviceVariationName = null;
    setFeatures([]);
  }

  const handleFeatureDelete = (id) => {
    console.log("ran");
    let data = [...features];
    const i = features.findIndex(f => f.id === id);
    console.log(i)
    data.splice(i, 1);
    setFeatures(data);
  }




  const renderRanks = () => {

    return (
      <div>
        <div id="ranks-container" className="col-md-10 col-lg-10">
          <div className="close-icon" onClick={handleCloseRanks}>
            <SVG src={closeIcon} ></SVG>
          </div>
          <div className="section-title">
            Available rank
            <p className="text-muted">Note- Provide Price exclusive of taxes</p>
          </div>
          {
            ranksAvailable.map(rank => {
              let isDisabled = true;
              if (values.rankVariation.hasOwnProperty(rank.uuid)) {
                isDisabled = false;
              }
              return (
                <div key={rank.uuid} className="d-flex flex-row stylist-card align-items-center mb-4">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label={rank.name} value={rank.uuid}
                      onChange={handleRanks} />
                  </Col>
                  <Col>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>&#8377;</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="text"
                          disabled={isDisabled}
                          name={`rankVariation.${rank.uuid}.price_base`}
                          value={isDisabled ? "" : values.rankVariation[rank.uuid].price_base}
                          onChange={handleChange} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </div>
              )
            }
            )
          }
          <div className="text-right">
            <Button variant="primary" onClick={getStaffForRanks}>Get staff</Button>
          </div>

        </div>
      </div>
    )
  }


  const renderFeatures = () => {
    return (
      <div>
        <div id="feature-container" className="col-md-11">
          <div className="close-icon" onClick={handleCloseFeatures}>
            <SVG src={closeIcon} />
          </div>
          <div className="section-title">
            Feature varied prices
                </div>
          {
            features.map((feature, i) => {
              return (
                <div className="row feature-list-item" key={feature.id}>
                  <div className="col-md-5">
                    <Form.Group>
                      <Form.Label className="form-label">Feature Name</Form.Label>
                      <Form.Control type="text" value={feature.name} onChange={(e) => handleFeatureChange(e, feature.id, "name")} />
                    </Form.Group>
                  </div>
                  <div className="col-md-5 offset-md-1">
                    <Form.Group>
                      <Form.Label className="form-label">Price</Form.Label>

                      <InputGroup>

                        <InputGroup.Prepend>
                          <InputGroup.Text>&#8377;</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="number" value={feature.price_base} onChange={(e) => { handleFeatureChange(e, feature.id, "price") }} />
                      </InputGroup>
                    </Form.Group>
                    <i
                      onClick={() => { handleFeatureDelete(feature.id) }}
                      className="fa fa-trash" aria-hidden="true"></i>
                  </div>
                </div>
              )
            })
          }
          <Button
            id="add-more"
            onClick={() => {
              let data = [...features];
              data.push({
                name: "",
                price_base: 0,
                personnel_rank_uuid: null,
                id: uuidv4()
              });
              setFeatures(data);
            }}
          >
            + Add More
          </Button>
        </div>
      </div>
    )
  }

  if (loading) return <h1>Loading</h1>

  return (

    <Form>
      <div>
        <Row noGutters>
          <Col md={8} id="new-service-form-container">
            <div className="NewServicePage-heading">
              <h2>Basic Details</h2>
            </div>
            <div className="mt-5" id="new-service-form">

              <Row>
                <Col md={7}>
                  <Form.Group>
                    <Form.Label className="form-label">Service Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="serviceName"
                      value={values.serviceName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.serviceName && errors.serviceName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.serviceName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={7}>
                  <Form.Group>
                    <Form.Label>Service Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="serviceType"
                      value={values.serviceType}
                      onChange={handleServiceChange}
                      onBlur={handleBlur}
                      isInvalid={touched.serviceType && errors.serviceType}
                    >
                      <option value="" disabled>Select</option>

                      {serviceTypes.map((service => (
                        <option key={service.value} value={service.value}>{service.label}</option>
                      )))}

                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.serviceType}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      Select the category which is applicable to this service
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={7}>
                  <Form.Group>
                    <Form.Label>Standard service</Form.Label>
                    <Form.Control
                      as="select"
                      name="standardService"
                      value={values.standardService}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.standardService && errors.standardService}
                    >
                      <option value="" disabled>Select</option>
                      {selectedStandardServices.map(standardService => (
                        <option
                          key={standardService.value}
                          value={standardService.value}>
                          {standardService.label}
                        </option>
                      ))}

                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.standardService}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={8} sm={6} md={5}>
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.gender && errors.gender}
                    >
                      <option value="" disabled>Select</option>
                      {servedGenders.map(gender => (
                        <option
                          key={gender.value}
                          value={gender.value}>
                          {gender.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <div className="mt-5">
                <h2>Pricing details</h2>
              </div>

              <Row className="mt-5">
                <Col md={5}>
                  <Form.Group>
                    <Form.Label>Duration (in minutes)</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text><i className="fas fa-clock"></i></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        name="duration"
                        value={values.duration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.duration && errors.duration}
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.duration}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      Duration should be in multiples of 5.
                    </Form.Text>

                  </Form.Group>
                </Col>
                {
                  pricingOption === false &&
                  <Col md={{ span: 5, offset: 1 }}>
                    <Form.Group>
                      <Form.Label className="form-label">Price</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text><i className="fas fa-rupee-sign"></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          name="price"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={values.serviceVariationName === null && touched.price && errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </InputGroup>
                      <Form.Text className="text-muted">
                        Provide Price exclusive of taxes
                    </Form.Text>
                    </Form.Group>

                  </Col>
                }


              </Row>
              {
                !pricingOption &&
                <Dropdown>
                  <Dropdown.Toggle className="gobony-success">
                    Add pricing options
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors" >
                    <Dropdown.Item
                      onClick={handleOpenRanks}>
                      On Rank basis
                            </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={handleOpenFeatures}>
                      On Feature basis
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              }
              {
                pricingOption === 'rank-basis' && renderRanks()
              }
              {
                pricingOption === 'feature-basis' && renderFeatures()
              }
            </div>
          </Col>
          <Col md={4} className="NewServicePage-right-side">

            <div className="NewServicePage-heading-staff">
              <h2>Staff</h2>
            </div>
            <div className="NewServicePage-staff-content">

              <p>Select Staff who can perform this service</p>

              <Row>
                {selectedStaff.map(staff => (
                  <Col key={staff.uuid} sm={6} md={12} xl={6}>
                    <AvailableStylist
                      handleChange={handleChange}
                      value={staff.uuid}
                      name={staff.name}
                      designation={staff.designation}
                      selectedPersonnels={values.staff}
                    />
                  </Col>
                ))}
                {touched.staff && errors.staff ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.staff}</div>
                  </div>
                ) : null}

              </Row>
            </div>

            <div className="NewServicePage-heading-staff">
              <h2>Sales Settings</h2>
            </div>

            <div className="NewServicePage-sales-content">
              {inheritedTaxes.length > 0 && <div>
                <p>Default Taxes</p>

                {inheritedTaxes.map(tax => (
                  <div key={tax.value} className="mr-2 mb-2 NewService-form-default-tax">
                    {tax.label} ({tax.percentageValue})
                  </div>
                ))}
              </div>

              }


              <Form.Group className="mt-3">
                <Form.Label>Additional Taxes</Form.Label>
                <Select
                  isMulti
                  options={remaingTaxes}
                  onChange={handleTaxes}
                />
                <Form.Text className="text-muted">
                  Select additional taxes if applicable
                </Form.Text>
              </Form.Group>

            </div>
          </Col>
        </Row>

      </div>
      <div className="fixed-bottom NewServicePage-footer">
        <div className="NewServicePage-button-group">
          <Button size="lg" className=" mr-5" variant="secondary" onClick={handleCloseForm}>
            CANCEL
          </Button>
          <Button size="lg" className="" type="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default NewServiceForm;