import React, { useState, useEffect } from 'react';
import './Editor.css';

import { useHistory } from "react-router-dom";

import Select from 'react-select';

import SolidColors from '../SolidColors';
import Mockup from '../Mockup/Mockup';
import Images from '../Images/Images';

import { Row, Col, Tabs, Tab, Form, Button } from 'react-bootstrap';

import { getColors, getImages, updateBG, finalizeDeal,getTagImages } from '../../crud';

import swal from 'sweetalert';

const Editor = ({ heading, description, price, tags, handleStep,dealuuid }) => {
  let history = useHistory();
  const [colors, setColors] = useState([])
  const [bgColor, setbgColor] = useState("");
  const [images, setImages] = useState([])
  const [image, setImage] = useState("/media/deals/1.png");
  const [imagesPageData, setImagesPageData] = useState(null);
  const [imagesNext, setImagesNext] = useState("")
  const [loading, setLoading] = useState(true)
  const [save, setSave] = useState(false)
  const [imageTags,setImageTags] = useState([])
  const [selectedTags,setSelectedTags] = useState([])

  async function GetColors() {
    try {
      const response = await getColors(dealuuid);
      // console.log(response)
      setbgColor(response.data.data_for_input.color_styles[0])
      setColors(response.data.data_for_input.color_styles)
      setImageTags(response.data.data_for_input.image_tags)
    } catch (err) {
      console.log(err)
    }
  }

  async function GetImages(nextLink) {
    try {
      const response = await getImages(nextLink);
      // console.log(response);
      if (nextLink === "initial") {
        setImagesPageData({
          pageSize: response.data.page_size,
          totalPages: response.data.total_pages,
          totalRecords: response.data.total_records
        })
        setImages([...response.data.value])
        setImage(response.data.value[0])
      } else {
        setImages([...images, ...response.data.value])
      }
      setImagesNext(response.data.next_link)
    } catch (err) {
      console.log(err)
    }
  }

  async function GetTagImages(nextLink,tags) {
    let response;
    try {
      // console.log(response);
      if (nextLink === "initial") {
        let tagLink = ""
        tags.forEach((tag)=>{
          tagLink = tagLink+`&tags=${tag.mini_id}`
        })
        console.log(tagLink);
        response = await getTagImages(nextLink,tagLink);
        setImagesPageData({
          pageSize: response.data.page_size,
          totalPages: response.data.total_pages,
          totalRecords: response.data.total_records
        })
        setImages([...response.data.value])
        // setImage(response.data.value[0])
      } else {
        response = await getTagImages(nextLink);
        setImages([...images, ...response.data.value])
      }
      setImagesNext(response.data.next_link)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true)
        await GetColors();
        await GetImages("initial")
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    getData()
  }, [])

  const handleScrollUp = (e) => {
    // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    // console.log("hit hit")
    if (selectedTags) {
      console.log(imagesNext, "next link of iages")
      GetTagImages(imagesNext)
    }else{
      GetImages(imagesNext)
    }
  }

  const handleSave = async () => {
    console.log(bgColor, image, "saved button clicked")
    const payload = {
      main_image_uuid: image.uuid,
      main_color_style_uuid: bgColor.uuid
    }
    try {
      await updateBG(dealuuid, payload);
      setSave(true)
      swal("Success!", "Deal image and color saved!", "success");
    } catch (err) {
      swal("Error!", "Please try again!", "error");
    }
  }

  const handlePublish = async () => {
    try {
      await finalizeDeal(dealuuid)
      swal({
        title: "Good job!",
        text: "Deal Published!",
        icon: "success",
        button: "Aww yess!",
      });
      history.push("/deals");
    } catch (err) {
      swal("Error!", "Please try again!", "error");
    }
  }

  const handleSearch=(val)=>{
    setSelectedTags(val)
    if(val){
      GetTagImages("initial",val)
    }else{
      GetImages("initial")
    }
  }

  if (loading) return (<h1>loading</h1>)
  return (
    <>
      <Row className="Editor height-100" noGutters>
        <Col md={5} className="NewDeal-Editor">
          <div className="pl-5 pt-5 SolidColors-container">
            <Tabs defaultActiveKey="SolidColors">
              <Tab eventKey="SolidColors" title="Solid Colors">
                <SolidColors setbgColor={setbgColor} colors={colors} />
              </Tab>
              {/* <Tab eventKey="GradientColors" title="Gradient Colors">
                <SolidColors setbgColor={setbgColor} colors={colors} />
              </Tab> */}
            </Tabs>
          </div>

          <div className="pl-5 pt-5 Editor-images-container">
            <p className="text-muted pt-5">Select Image</p>
            <Form>
              <Form.Group className="pr-5" controlId="formBasicEmail">
                <Select
                  value={selectedTags}
                  isMulti
                  options={imageTags}
                  getOptionLabel={(option)=>option.basic.name}
                  getOptionValue={(option)=>option.mini_id}
                  onChange={handleSearch}
                />
              </Form.Group>
            </Form>
            <Images
              setImage={setImage}
              bgColor={bgColor.basic.hex_code}
              images={images}
              handleScroll={handleScrollUp}
              imagesNext={imagesNext}
            />
          </div>
        </Col>

        <Col xs={12} md={7} className="NewDeal-Mockup">
          <Mockup bgColor={bgColor.basic.hex_code} heading={heading} description={description} image={image.basic.url_full}
            price={price} tags={tags} />
        </Col>
      </Row>
      <div className="NewDeal-footer">

        {
          save ?
            <Button onClick={handlePublish}>Publish</Button>
            :
            <>
              <Button
                className="mr-5"
                variant="outline-secondary"
                onClick={() => handleStep("update")}
              >
                Go back
            </Button>
              <Button onClick={handleSave}>Save</Button>
            </>
        }
      </div>
    </>
  )
}

export default Editor;