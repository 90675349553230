import React, { useState,useEffect } from 'react';
import './DateFilter.css';
import { Button, Modal } from 'react-bootstrap';

import { DateRange, DefinedRange } from 'react-date-range';

import { startOfDay } from 'date-fns';

import { defaultStaticRanges } from './RangeOptions'

const DateFilter = ({ handleFilter }) => {
  const [show, setShow] = useState(false);

  const [date, setDate] = useState([
    {
      startDate: startOfDay(new Date),
      endDate: new Date(),
      key: 'selection',
      label: "All time"
    }
  ]);

  const [newDate, setNewDate] = useState([
    {
      startDate: startOfDay(new Date),
      endDate: new Date(),
      key: 'selection',
      label: "All time"
    }
  ]);

  const handleApply = () => {
    setDate(newDate);
    handleFilter("date", newDate[0])
    handleClose();
  }

  const handleCancel = () => {
    setNewDate(date);
    handleClose();
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="Date-filter">

      <Button variant="outline-secondary" onClick={handleShow}>
        {date[0].label} <i className="fas fa-calendar-alt ml-2"></i>
      </Button>

      <Modal show={show} onHide={handleCancel}
        backdrop="static"
        centered
        scrollable
        dialogClassName="ST-DateFilter-Modal"

      >
        <Modal.Header closeButton>
          <Modal.Title>Date Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body >

          <div className="ST-DateFilter-dates">
            <DefinedRange
              onChange={item => {
                setNewDate([item.selection])
              }}
              ranges={newDate}
              staticRanges={defaultStaticRanges}
              inputRanges={[]}
            />

            <DateRange
              onChange={item => {
                setNewDate([{ ...item.selection, label: "Custom" }])
              }}
              months={2}
              direction={window.screen.availWidth>780?"horizontal":"vertical"}
              moveRangeOnFirstSelection={false}
              ranges={newDate}
              maxDate={new Date()}
            />
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default DateFilter;