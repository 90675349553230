import React, { useState, useEffect } from "react";
import './styles.css';

import { connect } from 'react-redux';
import { Button } from 'react-bootstrap'

import moment from 'moment';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Paper from '@material-ui/core/Paper';

import TableTopBar from './Components/TableTopBar/TableTopBar'

import { getTransactions, getNextPageData } from './crud';

import { columns } from './tableOptions';
import { getTableRowData, getDateLink } from './helpers/helpers';

import DateFilter from './Components/DateFilter/DateFilter';
import StateFilter from './Components/StateFilter/StateFilter'
import SearchConsumer from '../../commonComponents/SearchCustomer/SearchCustomer';

// const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


// const paginationOptions = {
//   sizePerPage: 10,
//   hideSizePerPage: true,
//   hidePageListOnlyOnePage: true
// }

const SalesTransactions = (props) => {
  const { currentSalon: { salon: { uuid: currentSalonuuid } } } = props.auth;

  const [loading, setLoading] = useState(true);
  const [tableRowData, setTableRowData] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [nextLink, setNextLink] = useState("")
  const [dateLink, setDateLink] = useState("")
  const [stateLink, setStateLink] = useState("")
  const [consumerLink, setConsumerLink] = useState("")

  async function getData(params) {
    try {
      const response = await getTransactions(currentSalonuuid, params);
      console.log(response)
      const transactions = getTableRowData(response.data.value);

      setPaginationData({
        pageSize: response.data.page_size,
        totalPages: response.data.total_pages,
        totalRecords: response.data.total_records
      })

      setNextLink(response.data.next_link)

      setTableRowData(transactions);
      // if (transactions.length > 0) {
      //   setTouched(true)
      // } else {
      //   setTouched(false)
      // }
      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  }

  async function handleLoadMore() {
    if (nextLink) {
      try {
        setNextLink(null);
        const response = await getNextPageData(nextLink)
        const transactions = getTableRowData(response.data.value);
        setNextLink(response.data.next_link)
        setTableRowData(tableRowData.concat(transactions))
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    getData()
  }, [currentSalonuuid])


  const handleFilter = (type, data) => {
    let link = ""

    if (type === "date") {
      if(data === "All time"){
        setDateLink("")
        getData(consumerLink+stateLink)
      }
      link = getDateLink(data);
      setDateLink(link)
      getData(consumerLink + link + stateLink)
    }

    else if (type === "state") {
      if (data === "ALL") {
        getData(consumerLink + dateLink)
        setStateLink("")
      }
      else {
        link = `&states=${data}`
        setStateLink(link);
        getData(consumerLink + dateLink + link)
      }
    }

    else if (type === "search") {
      if (data) {
        link = `&consumer_mobile=${data}`
        setConsumerLink(link);
        getData(link + dateLink + stateLink)
      }
      else {
        setConsumerLink("")
        getData(dateLink + stateLink)
      }
    }
  }


  if (loading) return <h1>Loading</h1>
  return (
    <div className="SalesListing">
      <Paper className="SalesListing-top-container">
        {/* <TableTopBar /> */}
        <div className="SalesListing-table-container">
          <ToolkitProvider
            keyField="uuid"
            data={tableRowData}
            columns={columns}
            exportCSV
            search
          >
            {
              props => <>
                <div className="SalesListing-filter-container">
                  <div className="d-flex justify-content-between">
                    <div className="SalesListing-filters">
                      <div className="d-flex mr-4 mb-4 mb-sm-0">
                        <DateFilter handleFilter={handleFilter} />
                        <StateFilter
                          handleFilter={handleFilter}
                          options={["ALL", "CREATED", "FAILED","AUTHORIZED","CAPTURED","REFUNDED"]}
                        />
                      </div>
                      <div>
                        <SearchConsumer searchCustomer={(data) => handleFilter("search", data)} length={9} />
                      </div>
                    </div>
                    {/* <SearchBar className="SalesListing-search" {...props.searchProps} /> */}
                    <div>
                      <ExportCSVButton {...props.csvProps}>Export <i className="fas fa-download ml-2"></i></ExportCSVButton>
                    </div>

                  </div>
                  {/* <p className="text-muted"><b>Search</b> in any field</p> */}
                </div>

                <BootstrapTable {...props.baseProps}
                  classes="SalesTable"
                  bordered={false}
                  bootstrap4={true}
                  noDataIndication={() => <h1>No data</h1>}

                // pagination={paginationFactory(paginationOptions)}
                // selectRow={{
                //   mode: 'checkbox',
                // clickToSelect: true
                // }}

                />
                <p className="text-muted text-right">Showing {tableRowData.length} of {paginationData.totalRecords}</p>
                <div className="text-center">
                  <Button className="rounded-pill"
                    variant="outline-primary"
                    onClick={handleLoadMore}
                    disabled={nextLink === null}
                  >Load more</Button>

                </div>
              </>
            }
          </ToolkitProvider>
        </div>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(SalesTransactions);

