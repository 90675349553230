import React from 'react';

const Step3 = (props) => {
  const { currentWizardState,services, handleService, handleSingleService} = props;
  return (
    <form>
      {/*begin: Wizard Step 3*/}
      <div
        className="pb-5"
        data-wizard-type="step-content"
        data-wizard-state={
          currentWizardState === 3
            ? "current"
            : "notCurrent"
        }
      >
        <h4 className="mb-10 font-weight-bold text-dark">
          Services
        </h4>
        {/* Services Start */}
        {services.map((service) => (
          <div
            key={service.uuid}
            className="wizard4-serviceGroup"
          >
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={service.checked}
                onChange={() =>
                  handleService(service.uuid)
                }
              />
              <label
                className="form-check-label ml-3 font-weight-bold"
                htmlFor="exampleCheck1"
              >
                {service.type}
              </label>
            </div>
            <hr />
            {service.services.map((singleService) => (
              <div
                key={singleService.uuid}
                className="form-check form-check-inline"
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  checked={singleService.checked}
                  disabled={singleService.disabled}
                  onChange={() => handleSingleService(service.uuid, singleService.uuid)}
                />
                <label
                  className="form-check-label ml-3 mr-5"
                  htmlFor="exampleCheck1"
                >
                  {singleService.name}
                </label>
              </div>
            ))}
          </div>
        ))}

        {/* Services End */}
      </div>
      {/*end: Wizard Step 3*/}
    </form>

  )
}

export default Step3;
