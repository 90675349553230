import React from 'react';

import Paper from '@material-ui/core/Paper';
import SingleAppointment from '../../../commonComponents/SingleAppointment';

const AppointmentsCard = ({label,data}) => {

  return (
    <Paper className="AppointmentsCard pt-3 pb-5 px-5 h-100">
      <h5 className="pt-5">{label}</h5>
      <div className="AppointmentsCard-alist">
        {data.map(ob=><SingleAppointment key={ob.id} data={ob}/>)}
      </div>
    </Paper>
  );
}

export default AppointmentsCard;