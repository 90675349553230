export function getRanksFromData(data) {
  let ranks = [];
  for (let stylistType in data.personnel_categories) {
    let arr = data.personnel_categories[stylistType].ranks;
    arr.forEach(element => {
      let rank = { name: element.basic.name, uuid: element.uuid }
      ranks.push(rank);
    });
  }
  return ranks;
}


export function getTaxesFromData(data) {
  let inheritedTaxes = []
  let remainingTaxes = []
  if(data.hasOwnProperty("inherited")){
    data.inherited.forEach(element => {
      let singleTax = {
        label: element.basic.name,
        value: element.uuid,
        percentageValue:`${element.basic.value}%`
      }
      inheritedTaxes.push(singleTax);
    })

  }
    
    data.remaining.forEach(element => {
      let singleTax = {
        label: `${element.basic.name} ${element.basic.value}%`,
        value: element.uuid,
      }
      remainingTaxes.push(singleTax);
    })

  return [inheritedTaxes,remainingTaxes];
}

export function getServicesFromData(data) {
  let services = data.service_types.map(service => ({
    label: service.basic.name,
    value: service.uuid
  }))

  return services;
}

export function getAllStandardServices(data) {
  let standardServices = {}
  for (let service of data.service_types) {
    standardServices[service.uuid] = []
    for (let standardService of service.standard_services) {
      standardServices[service.uuid].push({
        value: standardService.uuid,
        label: standardService.basic.name
      })
    }
  }

  return standardServices;
}

export function getGenderServed(genderData) {
  let servedGender = genderData.map(el => ({
    name: el.display_name,
    value: el.name
  }))

  return servedGender;
}

export function getAllStaff(personnelData) {
  let allStaff = {}
  for (let stylistType in personnelData) {
    for (let rank of personnelData[stylistType].ranks) {
      console.log(rank)
      allStaff[rank.uuid] = rank.personnels.map(person => ({
        uuid: person.uuid,
        name: `${person.basic.name_first} ${person.basic.name_last}`,
        designation: rank.basic.name
      }))
    }
  }
  return allStaff;
}


export function getAllStaffArray(allStaffObject) {
  let staff = [];
  for (let rank in allStaffObject) {
    staff = staff.concat(allStaffObject[rank]);
  }
  return staff;
}

export function getPayLoad(values, features) {
  let payLoad = {}
  payLoad.name = values.serviceName;
  payLoad.service_type_uuid = values.serviceType;
  payLoad.standard_service_uuid = values.standardService;
  payLoad.served_gender_name = values.gender;
  payLoad.duration_minutes = values.duration;
  payLoad.price_base = values.price ? values.price : null;
  payLoad.taxes = [];
  payLoad.service_variation_type_name = values.serviceVariationName ? values.serviceVariationName : null;
  let variations = [];
  if (payLoad.service_variation_type_name === "RANK") {
    for (let variation in values.rankVariation) {
      variations = variations.concat(values.rankVariation[variation]);
    }
    payLoad.variations = variations;

  } else if (payLoad.service_variation_type_name === "PRODUCT") {
    features.forEach(feature => {
      let newFeature = {...feature}
      delete newFeature.id;
      variations.push(newFeature);
    })
    payLoad.variations = variations;
  }
  else {
    payLoad.variations = []
  }
  payLoad.personnels = values.staff;
  payLoad.taxes = values.taxes?values.taxes:[]

  return payLoad;

}