import React from 'react';

const WizardNavStep = (props) => (
    <div className="wizard-step" data-wizard-type="step" data-wizard-state={props.currentState}>
        <div className="wizard-wrapper">
            <div className="wizard-number">{props.number}</div>
            <div className="wizard-label">
                <div className="wizard-title">{props.title}</div>
                {/* <div className="wizard-desc">{props.subTitle}</div> */}
            </div>
        </div>
    </div>
);

export default WizardNavStep;