import React, {useState} from 'react';
import './Banners.css'

import {data1} from './dummy';

import BannerSlider from './BannerSlider/BannerSlider';

const Banners = () => {
  const [current,setCurrent] = useState(0)
  return (
    <div className="Banners">
      
      <div className="Banners-top" style={{ backgroundImage: "url(/media/banner-heading.png)" }}>
        <h1>Welcome, Rohit</h1>
        <p><q>Design adds value faster than it adds cost.</q> -- Joel Spolsky</p>
      </div>
      <div className="Banners-bottom">   
        <BannerSlider title="Landscape" data={data1.Landscape}/>
        <BannerSlider title="Portrait" data={data1.Portrait}/>
      </div>

    </div>
  )
}

export default Banners;
