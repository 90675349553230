import React, { useState, useEffect, createRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { isMobileOnly } from "react-device-detect";
import { Filter } from "./toolbar";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import customViewPlugin from "./salonClosedNoitce";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function AppointmentsCalendar({
  resources,
  events,
  categories,
  activeCategory,
  onDateChange,
  onCategorySelect,
  currentDate,
  openCreateAppointment,
  currentSalon,
  isSalonOpen,
  onEventClick,
}) {
  const [activeResource, setActiveResource] = useState(null);
  const [currentResourcesRange, setCurrentResourcesRange] = useState(
    isMobileOnly ? [1, 2] : [0]
  );
  const history = useHistory();
  const {
    salon: {
      basic: { timing: businessHours },
    },
  } = currentSalon;

  useEffect(() => {
    if (resources.length) {
      setActiveResource(resources[0].title);
    }
  }, [resources]);

  function onDateClick({ resource }) {
    history.push("/appointments/create-appointments", {
      date: resource._context.currentDate,
      user: resource._resource.extendedProps.item,
    });
  }

  return (
    <div className="container-fluid h-100 w-100 d-flex flex-column">
      <div className="d-flex non-fc-toolbar justify-content-between mb-5">
        <Dropdown>
          <Dropdown.Toggle variant="light" className="bg-white">
            {activeCategory}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {categories.map((item) => (
              <Dropdown.Item
                key={item}
                onClick={() => onCategorySelect(item)}
                active={item === activeCategory}
              >
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {isMobileOnly ? (
          <div className="d-lg-none">
            <Filter
              activeCategory={activeResource}
              categories={resources.map((resource) => resource.title)}
              onCategorySelect={setActiveResource}
            />
          </div>
        ) : null}
        <Dropdown>
          <Dropdown.Toggle variant="primary" className="ml-5">
            Add New
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as="button" onClick={() => openCreateAppointment()}>
              <Link to="/appointments/create-appointments">
                <Typography className="text-dark">
                  Create an Appointment
                </Typography>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="button">
              <Link to="/appointments/create-sale">
                <Typography className="text-dark">Create a Sale</Typography>
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="flex-grow-1">
        <FullCalendar
          eventClick={onEventClick}
          themeSystem="bootstrap"
          initialView="resourceTimeGridDay"
          eventTextColor="#4A4A4A"
          initialDate={currentDate}
          plugins={[
            interactionPlugin,
            resourceTimeGridPlugin,
            bootstrapPlugin,
            customViewPlugin,
          ]}
          resources={resources.slice(...currentResourcesRange)}
          dayMaxEvents={true}
          dayMaxEventRows={true}
          events={events}
          slotDuration="00:15:00"
          slotLabelInterval="01:00:00"
          slotMinTime="08:00"
          slotMaxTime="22:00"
          nowIndicator={true}
          scrollTime={moment().format("HH:mm")}
          slotLaneClassNames="fc-slot-lane"
          slotLaneContent={({ date }) => moment(date).format("hh:mm A")}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: true,
          }}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: true,
          }}
          datesSet={({ startStr: date }) => onDateChange(date)}
          dateClick={onDateClick}
          headerToolbar={{
            left: "",
            center: "prev today title next",
            right: "",
          }}
          titleFormat={{
            month: "long",
            year: "numeric",
            day: "numeric",
            weekday: "long",
          }}
        />
      </div>
    </div>
  );
}

export default AppointmentsCalendar;
