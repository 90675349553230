import axios from 'axios';

export function getTransactions(salonuuid,params){
  if(params){
    console.log(params," params heer")
    return axios.get("/transactions?salon_uuid="+salonuuid+params)
  }
  return axios.get("/transactions?salon_uuid="+salonuuid)
}

export function getNextPageData(link){
  return axios.get(link);
}