import React, { useState } from "react";
import { InputBase, Paper } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

function SearchBox({ placeholder, data, setData, searchField }) {
  const [val, setVal] = useState("");

  function handleChange(e) {
    e.persist()
    setVal(e.target.value.toLowerCase());
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      if (!val) {
        setData(data);
      }
      else {
        let newData = [];
        for (let item of data) {
          if (item[searchField].toLowerCase().includes(val)) {
            newData.push(item);
          }
        }
        setData(newData);
      }

    }
  }

  return (
    <div className="quick-search quick-search-dropdown bg-light">
      <Paper
        elevation={0}
        variant="outlined"
        className="d-flex justify-content-between align-items-center px-5 flex-grow-1 quick-search-form"
      >
        <SearchIcon className="mr-3" />
        <InputBase
          placeholder={placeholder || "Search"}
          inputProps={{
            "aria-label": placeholder || "Search",
          }}
          value={val}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          className="flex-grow-1 py-2"
        />
      </Paper>
    </div>
  );
}

export default SearchBox;
