import moment from "moment";

export function generatePayloadBasicDetails(values, possibleValues) {
  return {
    read: true,
    name: values.name,
    business_type: values.businessType,
    served_gender: values.servedGender,
    manager_name: values.managerName,
    manager_phone: values.managerPhone,
    manager_email: values.managerEmail,
    address: values.address,
    loc_micro_market_uuid: values.locMicroMarket,
    loc_longitude: 0,
    loc_latitude: 0,
  };
}

export function generatePayloadOperationDetails(values, possibleValues) {
  return {
    read: true,
    time_open: moment(values.openingTime).format("HH:mm"),
    time_close: moment(values.closingTime).format("HH:mm"),
    operational_days: possibleValues.operationalDays.reduce((acc, item) => {
      acc[item] = values.operationalDays.includes(item);
      return acc;
    }, {}),
    amenities: values.amenities.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {}),
  };
}

export function generatePayloadTaxDetails(values, possibleValues) {
  return {
    read: true,
    rb_name: values.registeredBusinessName,
    rb_address: values.registeredBusinessAddress,
    rb_loc_city_uuid: values.registeredBusinessLocCity.uuid,
    pan: values.pan,
    gst: values.gst,
  };
}

export function generatePayloadToUploadImages(values) {
  return {};
}
