import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { Form, Dropdown } from "react-bootstrap";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";

const useStyles = makeStyles({
  selectInputRoot: {
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
  },
});

export default function SelectInput({
  placeHolder,
  data = [],
  onChange,
  disable = false,
  value,
  loading,
}) {
  const classes = useStyles();
  return (
    <Form.Group>
      <Select
        styles={{
          singleValue: (styles) => ({
            ...styles,
            width: "100%",
            padding: "2px",
            paddingRight: "4px",
          }),
        }}
        isLoading={loading}
        value={value}
        isDisabled={disable}
        options={data}
        onChange={(item) => onChange(item)}
        placeholder={placeHolder}
      />
    </Form.Group>
  );
}
