import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import UploadedImageAvatar from "../components/uploadedImageAvatar";
import SubmissionAck from "../components/SubmissionAcknowledgement";

function Step4Form({
  isCurrent,
  initialValues,
  formSchema,
  setFormValidity,
  getFormValues,
  onAddingImage,
  onClickOfDeleteImage,
}) {
  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  const [showSubmissionAck, setShowSubmissionAck] = useState(false);

  useEffect(() => {
    setFormValidity(formik.isValid);
  }, [formik]);

  useEffect(() => {
    getFormValues(formik.values);
    setShowSubmissionAck(formik.isValid);
  }, [formik.isValid]);

  const businessLogoDropZone = useDropzone({
    onDrop: async (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        onAddingImage("logo", file);
      });
    },
  });

  const salonInteriorImagesDropZone = useDropzone({
    onDrop: async (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        onAddingImage("salonInteriorImages", file);
      });
    },
  });

  return (
    <div
      className="pb-5"
      data-wizard-type="step-content"
      data-wizard-state={isCurrent ? "current" : ""}
    >
      <div className="h4 mb-10 font-weight-bold text-dark">
        Upload Salon Images
      </div>
      <div className="mb-10 d-flex justify-content-between">
        <p className="font-weight-bold text-dark">Business logo</p>
        <section className="drag-drop-container d-flex flex-column justify-content-between">
          <div className="p-1">
            {formik.values.businessLogo?.map((item, idx) => (
              <UploadedImageAvatar
                key={item.slice(0, 20)}
                image={item}
                onClickOfDeleteImage={() => onClickOfDeleteImage(item)}
              />
            ))}
          </div>
          {formik.values.businessLogo.length ? null : (
            <div
              {...businessLogoDropZone.getRootProps({
                className: "dropzone",
              })}
              className="d-flex flex-column justify-content-center align-items-center flex-grow-1"
            >
              <input {...businessLogoDropZone.getInputProps()} />
              {businessLogoDropZone.isDragActive ? (
                <p className="font-weight-bold">Drop the files here ...</p>
              ) : (
                <>
                  <p className="font-weight-bold">
                    Drag file here, or click to upload
                  </p>
                  <p>upload image of size 200px x 200px</p>
                </>
              )}
            </div>
          )}
        </section>
      </div>
      {formik.values.businessLogo.length ? (
        <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 mb-10">
          <p className="text-center">
            To replace, remove the image and upload new one.
          </p>
        </div>
      ) : null}
      <div className="mb-10 d-flex justify-content-between">
        <p className="font-weight-bold text-dark">Salon images</p>
        <section className="drag-drop-container d-flex flex-column justify-content-between">
          <div className="p-1">
            {formik.values.salonInteriorImages?.map((item) => (
              <UploadedImageAvatar
                key={String(Math.random())}
                image={item}
                onClickOfDeleteImage={() => onClickOfDeleteImage(item)}
              />
            ))}
          </div>
          {formik.values.salonInteriorImages.length === 10 ? null : (
            <div
              {...salonInteriorImagesDropZone.getRootProps({
                className: "dropzone",
              })}
              className="d-flex flex-column justify-content-center align-items-center flex-grow-1"
            >
              <input {...salonInteriorImagesDropZone.getInputProps()} />
              {salonInteriorImagesDropZone.isDragActive ? (
                <p className="font-weight-bold">Drop the files here ...</p>
              ) : (
                <>
                  <p className="font-weight-bold">
                    Drag file here, or click to upload
                  </p>
                  {formik.values.salonInteriorImages?.length ? (
                    <p>
                      {10 - formik.values.salonInteriorImages?.length} / 10
                      images of size 200px x 200px remaining
                    </p>
                  ) : (
                    <p>upload upto 10 images of size 200px x 200px</p>
                  )}
                </>
              )}
            </div>
          )}
        </section>
      </div>
      {formik.values.salonInteriorImages.length === 10 ? (
        <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 mb-10">
          <p className="text-center">
            To replace, remove the image and upload new one.
          </p>
        </div>
      ) : null}
      {showSubmissionAck ? <SubmissionAck /> : null}
    </div>
  );
}

export default Step4Form;
