/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import Tooltip from "@material-ui/core/Tooltip";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {/*begin:: section-1 operations*/}

        <li className="menu-section ">
          <h4 className="menu-text">Operations</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/appointments")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/appointments">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/Layers.svg")} />
            </span>
            <span className="menu-text">Appointments</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/sales",
            true
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/sales">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
            <span className="menu-text">Sales</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Sales</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/sales/sappointments"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sales/sappointments">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Appointments</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/sales/sdeals")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sales/sdeals">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Deals</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/sales/transactions")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sales/transactions">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Transactions</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/details",
            true
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/details">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/Clipboard-list.svg")} />
            </span>
            <span className="menu-text">Details</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Details</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/details/services"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/details/services">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Services</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/details/ranks")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/details/ranks">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Ranks</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/details/taxes")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/details/taxes">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Taxes</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("stylists")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/stylists">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Scissors.svg")}
              />
            </span>
            <span className="menu-text">Stylists</span>
          </NavLink>
        </li>
        {/*end:: section-1 operations*/}

        {/*begin:: section-2 Users*/}

        <li className="menu-section ">
          <h4 className="menu-text">CRM</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/users")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
              />
            </span>
            <span className="menu-text">Customers</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/analytics")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/analytics">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-top-panel-4.svg"
                )}
              />
            </span>
            <span className="menu-text">App Analytics</span>
          </NavLink>
        </li>

        {/*end:: section-2 Users*/}

        {/*begin:: section-3 offers */}
          
        <li className="menu-section ">
          <h4 className="menu-text">Offers</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/deals")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/deals">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Deals</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/discount")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/discount">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
              />
            </span>
            <span className="menu-text">Discount</span>
          </NavLink>
        </li>

        

        {/*end:: section-3 offers */}

        {/*begin:: section-4 marketing */}
        
        <li className="menu-section ">
          <h4 className="menu-text">Marketing</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/banners")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/banners">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/Layers.svg")}
              />
            </span>
            <span className="menu-text">Banners</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/sms",
            true
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/sms">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat2.svg")} />
            </span>
            <span className="menu-text">SMS</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">SMS</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/sms/overview"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms/overview">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Overview</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/sms/campaigns")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms/campaigns">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Campaigns</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/sms/templates")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms/templates">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Templates</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/pushNotifications")}`}
          aria-haspopup="true"
        >
          <Tooltip title="Coming soon" placement="top">
            <div className="menu-link">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Sendingmail.svg"
                  )}
                />
              </span>
              <span className="menu-text">Push Notifications</span>
            </div>
          </Tooltip>
        </li>

        {/*end:: section-4 marketing */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
