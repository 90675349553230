import axios from 'axios';

// reading from Service add addforinput
export function getAllInputData(salonuuid){
  return axios.get('salons/' + salonuuid + '/personnels/ranks?for_input=true');
}


export function createNewRank(payLoad,salonuuid){
  return axios.put('salons/' + salonuuid + '/personnels/ranks',payLoad);
}

export function updateRank(payLoad,salonuuid){
  return axios.post('salons/' + salonuuid + '/personnels/ranks',payLoad);
}