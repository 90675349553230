import axios from "axios";

export const LOGIN_URL = "auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_OTP = "auth/otp";
export const REQUEST_PASSWORD_URL = "auth/password/forgot";

export const ME_URL = "api/me";

export function login(email, password) {
  const payload = {
    type: "SALON_HANDLER",
    remember_me: true,
    code: 1,
    user_credential: password,
    user_identifier: email,
  };
  return axios.post(LOGIN_URL, payload);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestOTP(email) {
  return axios.post("auth/otp", {
    user_identifier: email,
    type: "SALON_HANDLER",
  });
}

export function resetPassword(values) {
  const payload = {
    type: "SALON_HANDLER",
    user_identifier: values.email,
    otp: values.otp,
    password_new: values.password,
    password_confirm: values.confirmPassword,
  };
  return axios.post(REQUEST_PASSWORD_URL, payload);
}

export function authCheck() {
  return axios.post("/auth/check");
}
