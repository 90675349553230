import React from 'react';
import { Row, Col } from 'react-bootstrap';

import SingleTemplate from './singleTemplate';

const Templates = () => {
  return (
    <div className="smsTemplates">
      <Row>
        <Col sm={6} md={4} className="mb-5">
          <SingleTemplate/>
        </Col>
        <Col sm={6} md={4} className="mb-5">
          <SingleTemplate/>
        </Col>
        <Col sm={6} md={4} className="mb-5">
          <SingleTemplate/>
        </Col>
        <Col sm={6} md={4} className="mb-5">
          <SingleTemplate/>
        </Col>
        <Col sm={6} md={4} className="mb-5">
          <SingleTemplate/>
        </Col>
        <Col sm={6} md={4} className="mb-5">
          <SingleTemplate/>
        </Col>
      </Row>
    </div>
  )
}

export default Templates