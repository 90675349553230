import React from 'react';
import './MainCategory.css';

import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";
import { Dropdown } from 'react-bootstrap';
const MainCategory = (props) => {
  const { categoryuuid, name } = props;

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));

  return (
    <div className="d-flex p-5 justify-content-between">
      <div onClick={props.handleCollapse}
        aria-controls={`example-collapse-text-${categoryuuid}`}
      >
        <p className="m-0">
          <i className="ki ki-bold-menu icon-xl"></i>
          <span className="CategoryCard-category-name">{name}</span>
        </p>
      </div>
      <div className="mr-4">
        <span className="svg-icon svg-icon-success svg-icon-3x">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")} />
        </span>
        <Dropdown className="d-inline">
          <Dropdown.Toggle as={CustomToggle}>
            <i className="ki ki-more-hor icon-xl ml-3 CategoryCard-edit-icon"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="super-colors" >
            <Dropdown.Item
              onClick={() => props.handleModal(name,"edit",categoryuuid)}>Edit Category
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={()=>alert('delete')} >Remove Category</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <i className="ki ki-more-hor icon-xl ml-3 CategoryCard-edit-icon" onClick={() => props.handleModal(name, name)}></i> */}
      </div>
    </div>
  )
}

export default MainCategory;
