import React from 'react';
import './formatters.css';
import { Avatar } from "@material-ui/core";

import {Link} from 'react-router-dom';

export function nameFormatter(cell, row) {
  return (
    <Link to={`users/${row.id}`}>
    <div className="d-flex align-items-center justify-content-center S-nameFormatter">
      <Avatar className="S-nameInitials">{cell.slice(0,1)}</Avatar>
      <div className="d-flex flex-column ml-3">
        <span style={{color:"black"}}>{cell}</span>
      </div>
    </div>
    </Link>
    
  );
};

export function typeFormatter(cell, row) {
  if (cell === "Retail") {
    return (
      <>
      <span className="S-typeFormatter-r"></span>
      <span className="">{cell}</span>
      </>
    )
  } else {
    return(
      <>
      <span className="S-typeFormatter-c"></span>
      <span className="">{cell}</span>
      </>
    )
  }
}

export function statusFormatter(cell, row) {
  return (
    <span className="label label-lg label-light-primary gb-label label-inline">
      {cell}
    </span>
  );
};

export function actionFormatter(cell, row) {
  return (
    <div>
      <i className="fas fa-edit mr-2  actionFormatter-action-blue blue"></i>
      <i className="fas fa-trash  actionFormatter-action-red red"></i>
    </div>
  )
}