import React from 'react';
import { Link } from 'react-router-dom';

const BannerSlider = ({ title, data }) => {
  return (
    <div className="BannerSlider mt-5">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="m-0">Banners {title}</h2>
        <Link to={`/banners/${title}`}>
          <p className="pr-5">See all</p>
        </Link>
      </div>

      <div className="Banners-images py-5 px-4">
        {
          data.map((ob, i) => (
            <Link key={i} to={`/banners/${title}/${ob.url.slice(-5,-4)}`}>
              <img className="SingleBanner-image Banners-image" src={ob.url} height="220"
              style={{width:"auto"}}></img>
            </Link>
          ))
        }
      </div>
    </div>
  )
}

export default BannerSlider