import React from 'react';
import './Campaigns.css'

import Paper from '@material-ui/core/Paper';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import SingleCampaign from './SingleCampaign';


const Campaigns = () => {
  return (
    <div className="smsCampaigns">
      <Paper className="py-4">
        <div className="smsCampaigns-top px-6">
          <DropdownButton variant="light" id="dropdown-basic-button" title="Delivered">
            <Dropdown.Item>Delivered</Dropdown.Item>
          </DropdownButton>
        </div>

        <div className="smsCampaigns-body px-6">
          <SingleCampaign/>
          <SingleCampaign/>
          <SingleCampaign/>
          <SingleCampaign/>
        </div>
      </Paper>
    </div>
  )
}

export default Campaigns;