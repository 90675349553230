export function getTableRowsData(personnelCategories) {
  const TableRowData = []
  let row = null;
  for (let category in personnelCategories) {
    let categoryName = personnelCategories[category].display_name;
    let rankName = null;
    let id = null;
    for (let rank of personnelCategories[category].ranks) {
      id = rank.uuid;
      rankName = rank.basic.name
      row = {
        rank: rankName,
        category:categoryName,
        uuid:id
      }
      TableRowData.push(row);
    }
  }
  return TableRowData;
}

export function getRankCategories(categories){
  return categories.personnel_categories;
}