export function getTableRowsData(taxes) {
  const TableRowData = []
  let row = null;
  for(let tax of taxes){
    row = {
      tax:tax.basic.name,
      value:tax.basic.value,
      uuid:tax.uuid
    }
    TableRowData.push(row);
  }
  return TableRowData;
}
