import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Input,
  InputAdornment,
  FormControl,
  TextField,
} from "@material-ui/core";
import { Close as CloseIcon, Add as AddIcon, Ru } from "@material-ui/icons";
import SelectItem from "./selectItem";
import SelectInput from "../../../commonComponents/selectInput";

const useStyles = makeStyles({
  cardContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflow: "auto",
  },
  card: {
    width: "100%",
    border: "1px solid #E7E9F5",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 4px 0 #E7E9F5 !important",
    marginBottom: "15px",
  },
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "10px",
  },
  addCTA: {
    borderRadius: "4px",
    backgroundColor: "#FDE4CE",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "15px",
    color: "#EE750F",
    width: "155px",
  },
  closeButton: {
    minWidth: "auto",
  },
  rupeeSymbolInput: {
    boxSizing: "border-box",
    height: "39px",
    width: "39px",
    border: "1px solid #E7E9F5",
    borderRadius: "4px",
    backgroundColor: "#F7F8FA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  priceTableRow: {
    display: "flex",
  },
});

function CheckoutCart() {
  const [items, setItems] = useState([{ id: 1 }]);
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();

  function onClickOfAdd() {
    setOpenModal(true);
  }

  function closeSelectItemModal() {
    setOpenModal(false);
  }

  function onClickOfRemove(cardItem) {
    const toBeDeletedIndex = items.findIndex((item) => item.id === cardItem.id);
    setItems([
      ...items.slice(0, toBeDeletedIndex),
      ...items.slice(toBeDeletedIndex + 1),
    ]);
  }

  return (
    <div className="w-100 p-8 d-flex align-items-center flex-column h-100">
      <div className={classes.cardContainer}>
        {items.map((item) => {
          return (
            <Card className={`p-3 ${classes.card}`}>
              <CardContent className="p-0">
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <div>
                    <Typography className="font-weight-bold" variant="h6">
                      Hair cut - men
                    </Typography>
                    <Typography className="font-weight-light">
                      1 hour 30 mins with Karan Singh
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography className="mr-3 font-weight-bold">
                      <span>&#x20B9;</span> <span>350</span>
                    </Typography>
                    <Button
                      onClick={() => onClickOfRemove(item)}
                      className={`p-0 w-0 ${classes.closeButton}`}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <FormControl className="mr-5 col">
                    <Typography className="mb-2">Price</Typography>
                    <div className="d-flex align-items-center border rounded">
                      <div className={classes.rupeeSymbolInput}>
                        <span>&#x20B9;</span>
                      </div>
                      <input
                        type="text"
                        className="form-control border-0"
                        name="price"
                        defaultValue="350"
                      />
                    </div>
                  </FormControl>
                  <FormControl className="flex-grow-1 mr-5 col">
                    <Typography className="mb-2">Stylist</Typography>
                    <SelectInput value="Karan Singh" />
                  </FormControl>
                  <FormControl className="flex-grow-1 col">
                    <Typography className="mb-2">Discount</Typography>
                    <SelectInput value="No Discount" />
                  </FormControl>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
      <div className={classes.footer}>
        <div
          className={`col p-0 ${
            items.length === 0 ? "d-flex justify-content-center" : ""
          }`}
        >
          <Button
            onClick={onClickOfAdd}
            startIcon={<AddIcon />}
            className={classes.addCTA}
          >
            <Typography variant="button">
              {items.length ? "Add more services" : "Add services"}
            </Typography>
          </Button>
        </div>
        {items.length ? (
          <div className="col">
            <Typography className="mb-5 d-flex justify-content-between priceTableRow">
              <span className="col d-flex justify-content-end">Subtotal:</span>{" "}
              <span className="col d-flex justify-content-end">
                &#x20B9; 350
              </span>
            </Typography>
            <Typography className="mb-5 d-flex justify-content-between priceTableRow">
              <span className="col d-flex justify-content-end">CSGT:</span>{" "}
              <span className="col d-flex justify-content-end">
                &#x20B9; 350
              </span>
            </Typography>
            <Typography className="mb-5 d-flex justify-content-between priceTableRow">
              <span className="col d-flex justify-content-end">SGST:</span>{" "}
              <span className="col d-flex justify-content-end">
                &#x20B9; 350
              </span>
            </Typography>
            <Divider className="mb-5" />
            <Typography className="mb-5 d-flex justify-content-between priceTableRow">
              <span className="col d-flex justify-content-end">Total:</span>{" "}
              <span className="col d-flex justify-content-end">
                &#x20B9; 350
              </span>
            </Typography>
            <Typography className="mb-5 d-flex justify-content-between font-weight-bold priceTableRow">
              <span className="col d-flex justify-content-end">Balance:</span>{" "}
              <span className="col d-flex justify-content-end">
                &#x20B9; 350
              </span>
            </Typography>
            <Divider className="mb-5" />
          </div>
        ) : null}
      </div>
      <SelectItem open={openModal} handleClose={closeSelectItemModal} />
    </div>
  );
}

export default CheckoutCart;
