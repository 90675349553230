export function getOrderPayload(token, cuuid, suuid, selectedServices) {
  const payload = {
    imd_token: token,
    salon_uuid: suuid,
    consumer_uuid: cuuid,
    platform: "WEB_DASHBOARD",
    read: true,
  };
  payload.services = selectedServices.map((ob) => ({
    service_uuid: ob.service.uuid,
    variation_uuid: ob.variation ? ob.variation.uuid : null,
    personnel_uuid: ob.appointment.personnel.uuid,
    on_date: null,
    at_time: null,
  }));

  console.log(payload);
  return payload;
}
