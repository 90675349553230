import React from "react";
import "./Images.css";

import {Button} from 'react-bootstrap';


const Images = ({ setImage, bgColor,images,handleScroll,imagesNext }) => {
  return (
    <div className="Images" >
      {images.map((image) => (
        <img
          key={image.uuid}
          className="Images-single"
          onClick={() => setImage(image)}
          src={image.basic.url_full}
          height="105"
          style={{ backgroundColor: bgColor }}
          alt="demo"
        />
      ))}
      {
        imagesNext && 
          <Button onClick={handleScroll}>Get More</Button>
      }
    </div>
  );
};

export default Images;
