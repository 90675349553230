import React, { useState, useEffect } from 'react';
import ProfileCard from './Components/ProfileCard';
import NumberCard from './Components/NumberCard';
import DetailCard from './Components/DetailCard/DetailCard';
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux';

import { data,data1 } from './dummy'

import { getConsumer, getUpcomingAppointments, getPastAppointments, getDeals } from "./crud";

const UserProfile = (props) => {
  console.log(props)
  const { currentSalon: { salon: { uuid: currentSalonuuid } } } = props.auth;
  const { userId } = props.match.params

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [upcomingPageData, setUpcomingPageData] = useState(null)
  const [upcomingNext, setUpcomingNext] = useState("")
  const [upcomingValues, setUpcomingValues] = useState([])
  const [pastNext, setPastNext] = useState("")
  const [pastPageData, setPastPageData] = useState(null)
  const [pastValues, setPastValues] = useState([])
  const [dealsPageData,setDealsPageData] = useState(null)
  const [dealsNext, setDealsNext] = useState("")
  const [dealsValues, setDealsValues] = useState([])


  async function getData() {
    try {
      const response = await getConsumer(currentSalonuuid, userId)
      setUser(response.data.data);
    } catch (err) {
      console.log(err)
    }
  }

  async function getUpAppointments(nextLink) {
    try {
      const response =  await getUpcomingAppointments(currentSalonuuid,userId,nextLink);
      console.log(response,"hererhererer");
      // const response = data
      if(nextLink==="initial"){
        setUpcomingPageData({
          pageSize: response.data.page_size,
          totalPages: response.data.total_pages,
          totalRecords: response.data.total_records
        })
      }
      setUpcomingNext(response.data.next_link)
      setUpcomingValues([...upcomingValues,...response.data.value])
    } catch (err) {
      console.log(err)
    }
  }

  async function getPaAppointments(nextLink) {
    try {
      const response =  await getPastAppointments(currentSalonuuid,userId,nextLink);
      console.log(response,"hererhererer");
      // const response = data
      if(nextLink==="initial"){
        setPastPageData({
          pageSize: response.data.page_size,
          totalPages: response.data.total_pages,
          totalRecords: response.data.total_records
        })
      }
      setPastNext(response.data.next_link)
      setPastValues([...pastValues,...response.data.value])
    } catch (err) {
      console.log(err)
    }
  }

  async function getUserDeals(nextLink) {
    try {
      const response =  await getDeals(currentSalonuuid,userId,nextLink);
      if(nextLink==="initial"){
        setDealsPageData({
          pageSize: response.data.page_size,
          totalPages: response.data.total_pages,
          totalRecords: response.data.total_records
        })
      }
      setDealsNext(response.data.next_link)
      setDealsValues([...dealsValues,...response.data.value])
      console.log(response.data.value,"deals value")
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    async function getRequiredData() {
      try {
        await getData()
        await getUpAppointments("initial")
        await getPaAppointments("initial")
        await getUserDeals("initial")
        setLoading(false)
      }catch(err){
        console.log(err)
      }
    }

    getRequiredData()


  }, [])

  if (loading) return <h1>Loading</h1>
  return (
    <div className="UserProfile">
      <Row>
        <Col md={4} lg={5} xl={4}>
          <ProfileCard
            user={user.basic}
            customerSince={user.salon_consumer.basic.first_booking_at}
            app={user.salon_consumer.basic.app_installed}
          />
        </Col>
        <Col className="mt-5 mt-md-0">
          <NumberCard 
          data={user.salon_consumer.basic} />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xl={{ span: 8, offset: 4 }}>
          <DetailCard 
          upcoming={upcomingValues} 
          upcomingNext={upcomingNext}
          getUpAppointments={()=>getUpAppointments(upcomingNext)}
          past={pastValues} 
          pastNext={pastNext}
          getPaAppointments={()=>getPaAppointments(pastNext)}
          deals={dealsValues}
          dealsNext={dealsNext}
          getDeals={()=>{getUserDeals(dealsNext)}}/>
        </Col>
      </Row>
    </div>
  )
}


const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(UserProfile);