import React, { useState, useEffect } from 'react';
import './styles.css';

import { connect } from 'react-redux';

import { Button, Dropdown, Modal, Form, DropdownButton } from 'react-bootstrap';
import Select from 'react-select';
import CubeGridLoader from '../../commonComponents/cubeGridLoader';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';


import CategoryList from './CategoryList/CategoryList';
import { getAllServicesOfSaloon, getAllCategoriesOfSaloon, postNewCategory, UpdateCategory } from './index.service';


const Services = (props) => {
    const { currentSalon: { salon: { uuid: currentSalonuuid } } } = props.auth;

    const [snackBar, setSnackBar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openAll, setOpenAll] = useState(false);

    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [categoryData, setCategoryData] = useState(null);

    const [serviceTypes, setServiceTypes] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const [newCategoryName, setNewCategoryName] = useState('');
    const [selectCategoryName, setSelectCategoryName] = useState([]);

    useEffect(() => {
        getAllData();
    }, [currentSalonuuid])

    const getAllData = () =>{
        setLoading(true);
        getAllServicesOfSaloon(currentSalonuuid)
            .then(response => {
                console.log('hiiiii')
                console.log(response);
                setCategoryData(response.data.data);
                return getAllCategoriesOfSaloon(currentSalonuuid)
            })
            .then(response => {
                const ServiceTypes = response.data.data.map(service => ({
                    label: service.basic.name,
                    value: service.uuid
                }))
                console.log(ServiceTypes);
                setServiceTypes(ServiceTypes);
                setSelectCategoryName(ServiceTypes[0])
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }
    


    const openModal = (nameVal, mode, uuid) => {
        if (nameVal) {
            setNewCategoryName(nameVal);
        }
        if (mode) {
            setIsEdit(true);
            setSelectCategoryName(uuid);
        }
        setShowCategoryModal(true);
    }

    const closeModal = () => {
        setNewCategoryName("");
        setSelectCategoryName(serviceTypes[0]);
        setIsEdit(false);
        setShowCategoryModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEdit) {
            const newCategory = { items: [{ uuid: selectCategoryName, name: newCategoryName }] };
            UpdateCategory(newCategory,currentSalonuuid)
                .then(response => {
                    console.log(response);
                    getAllData();
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else {
            const newCategory = { items: [{ standard_service_type_uuid: selectCategoryName.value, name: newCategoryName }] };
            postNewCategory(newCategory, currentSalonuuid)
                .then(response => {
                    setSnackBar(true);
                    console.log(response);
                })
                .catch(err => {
                    console.log(err);
                })
        }


        closeModal();
    }

    if (loading) return <CubeGridLoader />

    const { history } = props;
    return (
        <div className="padding-large-screen page-container" id="service-page">

            {/* action buttons */}
            <div className="services-topbar">
                <DropdownButton id="dropdown-toggle-services"
                    title={openAll ? "Collapse all" : "Show all"}
                    variant="light">
                    <Dropdown.Item onClick={() => setOpenAll(true)}>
                        Show all
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenAll(false)}>
                        Collapse all
                    </Dropdown.Item>
                </DropdownButton>

                <Dropdown>
                    <Dropdown.Toggle className="action-button">
                        Add New
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors" >
                        <Dropdown.Item onClick={() => { history.push("/new-service") }}>Add Service</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => openModal("")}>Add Category</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {/* action buttons */}

            <div id="service-list-container">
                <CategoryList handleModal={openModal} categoryData={categoryData}
                    openAll={openAll} />
            </div>

            <Modal
                show={showCategoryModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={closeModal}
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton style={{ justifyContent: 'space-between' }} >
                        <Modal.Title  >
                            Add New Category
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body id="add-service-modal">

                        <Form.Group controlId="add-service">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={newCategoryName}
                                onChange={(e) => {
                                    setNewCategoryName(e.target.value);
                                }}
                            />
                        </Form.Group>

                        {!isEdit &&
                            <Form.Group>
                                <Form.Label>Select Category</Form.Label>
                                <Select
                                    options={serviceTypes}
                                    value={selectCategoryName}
                                    onChange={(val) => setSelectCategoryName(val)}
                                />

                                <Form.Text className="text-muted">
                                    Please select the default category
                            </Form.Text>
                            </Form.Group>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                            </Button>
                        <Button className="abc" type="submit" variant="primary">
                            Save
                            </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            <Snackbar open={snackBar} autoHideDuration={4000} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }} onClose={() => setSnackBar(false)}>
                <Alert severity="success">
                    New Category Created
                </Alert>
            </Snackbar>
        </div>

    )

}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Services);