import React, { useState, useEffect } from 'react';
import './styles.css';

import { Row, Col, Form, InputGroup, Popover, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select'
import TooltipInput from '../../../../commonComponents/TooltipInput';
import TooltipLabel from '../../../../commonComponents/TooltipLabel';
import PopoverTooltip from '../../../../commonComponents/PopoverTooltip';

import { v4 as uuidv4 } from 'uuid';

import { formatOptionLabel } from '../../helpers';


const DealForm = ({ values, errors, touched, handleChange, handleBlur, setFieldValue, services, setServices, requiredData }) => {

  useEffect(() => {
    setFieldValue("salonStartTime", requiredData.startTime);
    setFieldValue("salonEndTime", requiredData.endTime);
    setFieldValue("startTime",requiredData.startTime);
    setFieldValue("endTime",requiredData.endTime);
  }, [])
  const handleAddService = () => {
    let data = [...services];
    data.push({
      id: uuidv4(),
      service: "",
      variation: "",
      sessions: "",
      fullVal: ""
    });
    setServices(data);
  }

  const handleDeleteService = (id) => {
    let data = services.filter(service => service.id !== id);
    setServices(data);
  }

  const handleServiceChange = (val, id) => {
    let data = services.map(s => {
      if (s.id === id) {
        s.service = val ? val.service_uuid : "";
        s.fullVal = val
        if (val.variation_uuid) {
          s.variation = val.variation_uuid
        }
        return s;
      }
      else {
        return s;
      }
    })

    setServices(data);
    let isEmpty = data.some(service => (service.service === "" || service.sessions === ""));
    isEmpty = data.length === 0 || isEmpty;
    if (isEmpty) {
      setFieldValue("isEmpty", "");
    }
    else {
      setFieldValue("isEmpty", "hey");
    }
    console.log(data)
  }

  const handleSessionChange = (e, id) => {
    e.persist();
    let data = services.map(s => {
      if (s.id === id) {
        s.sessions = e.target.value;
        return s;
      }
      else {
        return s;
      }
    })
    setServices(data);
    let isEmpty = data.some(service => (service.service === "" || service.sessions === ""));
    isEmpty = data.length === 0 || isEmpty;
    if (isEmpty) {
      setFieldValue("isEmpty", "");
    }
    else {
      setFieldValue("isEmpty", "hey");
    }
    console.log(data)
  }

  return (
    <Form className="DealForm height-100">
      <Row noGutters className="height-100" >
        <Col md={8} className="DealForm-left-container">
          <div className="DealForm-left">
            <h2>1. Basic Details</h2>
            <Form.Row>
              <Col md={8}>
                <Form.Label>Deal Title</Form.Label>
                <Form.Control
                  className="tooltip-input"
                  type="text"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.title && errors.title}
                />
                <PopoverTooltip title="something">
                  <img className="rounded" src="/media/stock-600x400/img-1.jpg" height="45" />
                </PopoverTooltip>
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="pt-5">
              <Col md={8}>
                <Form.Label>Deal Description</Form.Label>
                <Form.Control className="tooltip-input" as="textarea" rows={2}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.description && errors.description}
                />
                <TooltipInput title="something" placement="top" />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="pt-5">
              <Col md={8}>
                <Form.Label>Terms</Form.Label>
                <Form.Control className="tooltip-input" as="textarea" rows={2}
                  value={values.terms}
                  name="terms"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.terms && errors.terms} />
                <TooltipInput title="something" placement="top" />
                <Form.Control.Feedback type="invalid">
                  {errors.terms}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Form.Row className="pt-5">
              <Col md={5}>
                <Form.Label>Service Available for</Form.Label>
                <Select options={requiredData.servedGenders}
                  value={values.availableFor}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#E5EAEE',
                    },
                  })}
                  onChange={(val) => setFieldValue('availableFor', val)}
                />
                {touched.availableFor && errors.availableFor ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.availableFor}</div>
                  </div>
                ) : null}

              </Col>
              <Col md={5} className="pt-5 pt-md-0">
                <Form.Label>Applicable Tags</Form.Label>
                <Select
                  value={values.applicableTags}
                  isMulti
                  options={requiredData.dealTags}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#E5EAEE',
                    },
                  })}
                  onChange={(val) => setFieldValue('applicableTags', val ? val.slice() : [])}
                />
                {touched.applicableTags && errors.applicableTags ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.applicableTags}</div>
                  </div>
                ) : null}
              </Col>
            </Form.Row>

            <h2 className="pt-5 mt-5">2. Services</h2>

            {services.map((service) => {
              return (
                <Form.Row key={service.id} className="mb-2">
                  <Col md={5}>
                    <Form.Label>Services included</Form.Label>
                    <Select
                      value={service.fullVal}
                      className="service-select-container"
                      classNamePrefix="service-select"
                      formatOptionLabel={formatOptionLabel}
                      options={requiredData.services}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#E5EAEE',
                        }
                      })}
                      onChange={val => handleServiceChange(val, service.id)}
                    />
                  </Col>
                  <Col md={4} className="pt-5 pt-md-0 d-flex">
                    <div>
                      <Form.Label>No. of sessions</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text >#</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          aria-describedby="inputGroupPrepend"
                          value={service.sessions}
                          onChange={(e) => handleSessionChange(e, service.id)}
                        />
                      </InputGroup>
                    </div>
                    <i className="fa fa-trash DealForm-trash" aria-hidden="true"
                      onClick={() => handleDeleteService(service.id)} />
                  </Col>
                </Form.Row>
              )
            })}
            {errors.isEmpty ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.isEmpty}</div>
              </div>
            ) : null}

            <div className="DealForm-addmore" onClick={handleAddService}>
              + Add More
            </div>
          </div>


        </Col>
        <Col md={4} className="DealForm-right-container">
          <div className="DealForm-right">
            <h2>3. Pricing Details</h2>

            <Form.Row>
              <Col>
                <TooltipLabel label="Total Deals" title="something" placement="top" />
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text >#</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    aria-describedby="inputGroupPrepend"
                    name="totalDeals"
                    value={values.totalDeals}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.totalDeals && errors.totalDeals}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.totalDeals}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Row>

            <Form.Row className="pt-5">
              <Col>
                <Form.Label>Price</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text >Rs</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    aria-describedby="inputGroupPrepend"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.price && errors.price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.price}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Row>

            {
              requiredData.inheritedTaxes.length > 0 &&
              <div>
                <p className="pt-5">Default Taxes</p>

                {requiredData.inheritedTaxes.map(tax => (
                  <div key={tax.value} className="mr-2 mb-2 NewService-form-default-tax">
                    {tax.label} ({tax.percentageValue})
                  </div>
                ))}
              </div>
            }

            <Form.Row className="pt-5">
              <Col>
                <Form.Label>Select additional tax rate</Form.Label>
                <Select
                  isMulti
                  value={values.taxes}
                  options={requiredData.remainingTaxes}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#E5EAEE',
                    },
                  })}
                  onChange={(val) => setFieldValue('taxes', val ? val.slice() : [])}
                />
              </Col>
            </Form.Row>

            <h2 className="pt-5 mt-5">4. Redemption Limits</h2>
            <div className="form-check pt-2">
              <input className="form-check-input"
                name="specificDay"
                checked={values.specificDay} type="checkbox"
                onChange={handleChange}
              />
              <label className="form-check-label ml-2" >
                Can be redeemed on specific days only
              </label>
              <TooltipLabel label="" placement="top" title="something" />
            </div>

            {
              values.specificDay &&
              <Select
                isMulti
                value={values.specificDays}
                options={requiredData.days}
                isOptionDisabled={(option) => option.disabled === true}
                className="basic-multi-select"
                classNamePrefix="select"
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#E5EAEE',
                  },
                })}
                onChange={(val) => setFieldValue('specificDays', val ? val.slice() : [])}
              />
            }

            <div className="form-check pt-2">
              <input className="form-check-input"
                name="specificTime"
                checked={values.specificTime}
                type="checkbox"
                onChange={handleChange} />
              <label className="form-check-label ml-2" >
                Can be redeemed on specific timings
              </label>
              <TooltipLabel label="" placement="top" title="something" />
            </div>

            {
              values.specificTime &&
              <>
                <Form.Row className="pt-5">
                  <Col>
                    <Form.Label>Start time</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text ><i className="far fa-clock" /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="time"
                        aria-describedby="inputGroupPrepend"
                        name="startTime"
                        value={values.startTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.startTime && errors.startTime}
                      />
                      <Form.Control.Feedback type="invalid">
                      {errors.startTime}
                    </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      Salon opening time {requiredData.startTime}
                    </Form.Text>
                    
                  </Col>
                </Form.Row>
                <Form.Row className="pt-5 pb-5">
                  <Col>
                    <Form.Label>End time</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text ><i className="far fa-clock" /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="time"
                        aria-describedby="inputGroupPrepend"
                        name="endTime"
                        value={values.endTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.endTime && errors.endTime}
                      />
                      <Form.Control.Feedback type="invalid">
                      {errors.endTime}
                    </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      Salon closing time {requiredData.endTime}
                    </Form.Text>
                  </Col>
                </Form.Row>
              </>
            }

            <div className="form-check pt-2">
              <input className="form-check-input"
                checked={values.limitPurchase}
                name="limitPurchase" type="checkbox"
                onChange={handleChange} />
              <label className="form-check-label ml-2" >
                Limit number of times a user can purchase
              </label>
              <TooltipLabel label="" placement="top" title="something" />
            </div>

            {
              values.limitPurchase &&
              <Form.Row className="pt-3 pb-2">
                <Col>
                  <Form.Label>Limit Purchase</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text >#</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      aria-describedby="inputGroupPrepend"
                      name="limitOfPurchase"
                      value={values.limitOfPurchase}
                      onChange={handleChange}
                      onBlur={handleBlur}

                    />
                  </InputGroup>
                </Col>
              </Form.Row>
            }
            <Form.Row className="pt-5">
              <Col>
                <TooltipLabel label="Publish on" title="something" placement="top" />
                <Form.Control
                  type="date"
                  aria-describedby="inputGroupPrepend"
                  name="publishOn"
                  value={values.publishOn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.publishOn && errors.publishOn}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.publishOn}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="pt-5">
              <Col>
                <TooltipLabel label="Expire on" title="something" placement="top" />
                <Form.Control
                  type="date"
                  aria-describedby="inputGroupPrepend"
                  name="expireOn"
                  value={values.expireOn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.expireOn && errors.expireOn}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.expireOn}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="pt-5">
              <Col>
                <TooltipLabel label="Validity" title="something" placement="top" />

                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text ><i className="far fa-clock" /></InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    aria-describedby="inputGroupPrepend"
                    name="validity"
                    value={values.validity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.validity && errors.validity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.validity}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Row>

          </div>


        </Col>
      </Row>
    </Form>

  )
}

export default DealForm;