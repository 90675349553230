import React from 'react';

import {Form} from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';


const TooltipLabel = (props) => {
  const {label, title, placement} = props;
  return (
    <Form.Label>
      {label}
      <Tooltip title={title} placement={placement} className="ml-2">
        <i className="fas fa-info-circle"></i>
      </Tooltip>
    </Form.Label>
  );
}

export default TooltipLabel;