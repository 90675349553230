import axios from "axios";

export function getBasicDetails({ salonUUID }) {
  return axios.get(`/salon/${salonUUID}/details/basic`, {
    params: { for_input: true },
  });
}

export function getOperationDetails({ salonUUID }) {
  return axios.get(`/salon/${salonUUID}/details/operation`);
}

export function getTaxDetails({ salonUUID }) {
  return axios.get(`/salon/${salonUUID}/details/tax`, {
    params: { for_input: true },
  });
}

export function getImageDetails({ salonUUID }) {
  return axios.get(`/salon/${salonUUID}/details/images`);
}

export function postBasicDetails({ salonUUID, payload }) {
  return axios.put(`/salon/${salonUUID}/details/basic`, { ...payload });
}

export function postOperationDetails({ salonUUID, payload }) {
  return axios.put(`/salon/${salonUUID}/details/operation`, { ...payload });
}

export function postTaxDetails({ salonUUID, payload }) {
  return axios.put(`/salon/${salonUUID}/details/tax`, { ...payload });
}

export function postLogo({ salonUUID, imageFile }) {
  const formData = new FormData();
  formData.append("file", imageFile);
  formData.append(
    "data",
    new Blob(
      [
        JSON.stringify({
          read: true,
        }),
      ],
      {
        type: "application/json",
      }
    )
  );
  return axios.post(`/salon/${salonUUID}/details/images/logo`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function postSalonImages({ salonUUID, imageFile }) {
  const formData = new FormData();
  formData.append("file", imageFile);
  formData.append(
    "data",
    new Blob(
      [
        JSON.stringify({
          tags: ["MAIN"],
          read: true,
        }),
      ],
      {
        type: "application/json",
      }
    )
  );
  return axios.post(`/salon/${salonUUID}/details/images`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function deleteImage({ salonUUID, imageFileUrl }) {
  const payload = {
    items: [imageFileUrl],
    read: true,
  };
  return axios.post(`/salon/${salonUUID}/details/images/remove`, {
    ...payload,
  });
}
