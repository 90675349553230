export function getDraftPayLoad(values) {
  const payLoad = {
    name_first: values.fname,
    name_last: values.lname,
    mobile: values.phone,
    email: values.email,
    gender: values.gender,
    job_period_start_on: values.empstartdate || null,
    job_period_end_on: null
  }
  return payLoad;
}

function getOperationalDays(days) {
  const operationalDays = {
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false
  }
  for(let day of days){
    operationalDays[day]=true
  }
  return operationalDays;
}

export function getOperationPayLoad(values) {
  const payLoad = {
    personnel_rank_uuid: values.rank,
    shift_time_start: values.shiftstarttime.slice(0,5),
    shift_time_end: values.shiftendtime.slice(0,5),
    operational_days: getOperationalDays(values.workingdays)
  }
  return payLoad;
}



export function getPersonnelCategories(categories) {

  const personnelCategories = [];
  for (let category in categories) {
    let newCat = categories[category];
    personnelCategories.push({
      value: newCat.name,
      label: newCat.display_name
    })
  }

  return personnelCategories;
}

export function getServices(services,rankuuid){
  console.log(services);
  const serviceData = []
  for(let service of services){
    const serviceItems = service.items.map(item=>{
      let disabled = true;

      if(item.basic.variable && item.variations.length===0){
        disabled=false
      }

      if(item.variations.length > 0 && item.variations[0].type==="RANK"){
        disabled = item.variations.some(ob=>ob.rank_obj.uuid===rankuuid)
      }
      
      const data = {
        name:item.basic.name,
        uuid:item.uuid,
        checked:item.assigned,
        disabled:!disabled
      }
      return data
    });

    serviceData.push({
      type:service.basic.name,
      uuid:service.uuid,
      checked:false,
      services:serviceItems
    })
  }
  return serviceData;
}

export function getSelectedServices(services){
  const selectedServices = {services:[]}
  for (let item of services){
    for (let singleService of item.services){
      if(singleService.checked){
        selectedServices.services.push(singleService.uuid);
      }
    }
  }
  return selectedServices;
}

export function getOperationDays(days,personnalDays=null){
  const d = {
    MON:{ value: "MON", label: "Monday",disabled:false,checked:false},
    TUE:{ value: "TUE", label: "Tuesday",disabled:false ,checked:false},
    WED:{ value: "WED", label: "Wednesday" ,disabled:false,checked:false},
    THU:{ value: "THU", label: "Thursday" ,disabled:false,checked:false},
    FRI:{ value: "FRI", label: "Friday",disabled:false ,checked:false},
    SAT:{ value: "SAT", label: "Saturday" ,disabled:false,checked:false},
    SUN:{ value: "SUN", label: "Sunday" ,disabled:false,checked:false},
  }
    
  ;

  for(let singleday in days){
    if(days[singleday] === false){
      d[singleday].disabled=true;
    }
  }

  if(personnalDays){
    console.log("yessssssssss");
    console.log(personnalDays);
    for(let singleday in personnalDays){
      if(personnalDays[singleday] === true){
        console.log(singleday);
        d[singleday].checked=true;
        console.log(d[singleday].checked)
      }
    }
  }

  return Object.values(d);
}


export function fillForm1(data,resetForm){
  console.log(data);
  resetForm({
    values:{
      fname:data.name_first,
      lname:data.name_last,
      email:data.email,
      phone:data.mobile,
      gender:data.gender?data.gender.name:"",
      empstartdate:data.job_periods[0].basic.start_on
    }
  })
}

export function fillForm2(data,resetForm){
  const days =[]

  for(let day in data.operational_days){
    if(data.operational_days[day]===true){
      days.push(day);
    }
  }
  
  console.log(data);
  resetForm({
    values:{
      category:data.personnel_rank.basic.personnel_category.name,
      rank:data.personnel_rank.uuid,
      shiftstarttime:`${data.shift_time.start}:00`,
      shiftendtime:`${data.shift_time.end}:00`,
      saloonstarttime: '00:00:00',
      saloonendtime: '00:00:00',
      workingdays:[...days],
    }
  })
}