import React from 'react';
import './Services.css'
import SVG from "react-inlinesvg";
import menuSvg from './menu.svg';

const ServiceVariation = ({ variation }) => {
  const name = variation.basic.name;
  const price = variation.basic.price.price_net
  return (
    <div className="CategoryCard-service-variation">
      <div className="d-flex p-5 justify-content-between align-items-center">

        <div className="CategoryCard-service-variation-name">
          <p className="m-0">
            <i className="ki ki-menu icon-xl"></i>
            <span className="CategoryCard-service-name">{name}</span>
          </p>
        </div>
        <p className=" CategoryCard-service-price m-0 mr-5">
          ₹ {price}
        </p>

      </div>
    </div>
  )
}


const Services = (props) => {
  return (
    props.services.map(singleService => {

      const { uuid, variations, basic: { name, duration_minutes } } = singleService;
      const price = singleService.basic.price.price_net;
      const isVariation = variations.length > 0

      return (
        <div key={uuid}>
          <div className="d-flex p-5 justify-content-between align-items-center Services-single-service">
            <div>
              <p className="m-0 d-flex align-items-center">
                <span className="svg-icon svg-icon-2x ">
                  <SVG src={menuSvg} />
                </span>

                <span className="CategoryCard-service-name">{name}</span>
              </p>
            </div>

            <p className="m-0">{duration_minutes} mins</p>

            <p className=" CategoryCard-service-price m-0 mr-5">
              {!isVariation && `₹ ${price}`}
            </p>

          </div>

          {/* render service variations if they exist */}

          { isVariation &&
            variations.map((variation) =>
              <ServiceVariation key={variation.uuid} variation={variation} />
            )}
        </div>


      )
    })
  )
}




export default Services


