import React from 'react';
import { Media, Button } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';

const SingleTemplate = () => {
  return (
    <Paper className="px-8 pt-10 pb-5">
      <Media>
        <img
          width={80}
          height={80}
          className="mr-3 rounded-circle"
          src="/media/img-1.jpg"
          alt="Generic placeholder"
        />
        <Media.Body >
          <h3>Delivery Report</h3>
          <p className="text-success">Engagement</p>
        </Media.Body>
      </Media>
      <div className="mt-4">
        <p>
          Hi Ansh ! We're so excited
          that you've decided to purchase something. It's designed to help you sclist benefits. You can expect to hear from us  times a month with special offers, product updates, and more. Contact us at phone number if you have any questions.
          </p>

        <Button block> Use Template</Button>
      </div>

    </Paper>
  )
}

export default SingleTemplate;