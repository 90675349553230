import axios from 'axios';

export function addDraftStylist(payLoad,saloonuuid){
  console.log(payLoad);
  return axios.put('salons/' + saloonuuid + '/personnels/add',payLoad);
}

export function getOperationData(personnel_uuid,saloonuuid){
  return axios.get('salons/' + saloonuuid + '/personnels/'+ personnel_uuid + '/details/operation?for_input=true');
}

export function addOperationDetails(payLoad,personnel_uuid,saloonuuid){
  console.log(payLoad);
  return axios.put('salons/' + saloonuuid + '/personnels/'+ personnel_uuid + '/details/operation',payLoad);
  
}

// reading from Service readAll.
export function getAllServicesOfSaloon(saloonuuid){
  return axios.get('salons/' + saloonuuid + '/services');
}

export function getPersonnelServices(personnel_uuid,saloonuuid){
  return axios.get('salons/' + saloonuuid + '/personnels/'+ personnel_uuid + '/details/service');
}

export function addServiceDetails(payLoad,personnel_uuid,saloonuuid){
  console.log(payLoad);

  return axios.put('salons/' + saloonuuid + '/personnels/'+ personnel_uuid + '/details/service',payLoad);
}

export function updateBasicStylist(payLoad,personnel_uuid,saloonuuid){
  return axios.put('salons/' + saloonuuid + '/personnels/'+ personnel_uuid + '/details/basic',payLoad);
}

export function getBasicData(personnel_uuid,saloonuuid){
  return axios.get('salons/' + saloonuuid + '/personnels/'+ personnel_uuid + '/details/basic');
}