import axios from "axios";

export function getRequiredData(salonuuid){
  return axios.get("/deals/add?salon_uuid="+salonuuid);
}

export function getToken(){
  return axios.post("/deals/add/tokens")
}

export function createDraftDeal(payload){
  return axios.put("/deals/add",payload)
}

export function getColors(dealuuid){
  return axios.get("/deals/"+dealuuid+"/details/bg?for_input=true")
}

export function getImages(nextLink){
  if(nextLink && nextLink!=="initial"){
    return axios.get(nextLink)
  }
  return axios.get("/usables/images?purpose=SALON_DEAL")
}

export function getTagImages(nextLink,tags){
  if(nextLink && nextLink!=="initial"){
    return axios.get(nextLink)
  }
  return axios.get("/usables/images?purpose=SALON_DEAL"+tags)
}

export function updateDeal(dealuuid,payload){
  return axios.put("/deals/"+dealuuid+"/details/initial",payload)

}

export function updateBG(dealuuid,payload){
  return axios.put("/deals/"+dealuuid+"/details/bg",payload)
}

export function finalizeDeal(dealuuid){
  return axios.post("/deals/"+dealuuid+"/details/final")
}