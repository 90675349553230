import React from 'react';

const Step1 = (props) => {
  const {currentWizardState,getInputClassesform1,formik1} = props;
  return (
    <form className="form mt-0" id="kt_form">
      {/*begin: Wizard Step 1*/}
      <div
        className="pb-5"
        data-wizard-type="step-content"
        data-wizard-state={
          currentWizardState === 1
            ? "current"
            : "notCurrent"
        }
      >
        <h4 className="mb-10 font-weight-bold text-dark">
          Enter Details
        </h4>
        <div className="row">
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                className={`form-control form-control-solid form-control-lg ${getInputClassesform1("fname")}`}
                name="fname"
                id="fname"
                value={formik1.values.fname}
                onBlur={formik1.handleBlur}
                onChange={formik1.handleChange}
              />
              {formik1.touched.fname && formik1.errors.fname ? <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik1.errors.fname}</div>
              </div> : null}
              {/* <span className="form-text text-muted">Please enter your first name.</span> */}
            </div>
            {/*end::Input*/}
          </div>
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                className={`form-control form-control-solid form-control-lg ${getInputClassesform1("lname")}`}
                name="lname"
                id="lname"
                value={formik1.values.lname}
                onBlur={formik1.handleBlur}
                onChange={formik1.handleChange}
              />
              {formik1.touched.lname && formik1.errors.lname ? <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik1.errors.lname}</div>
              </div> : null}
              {/* <span className="form-text text-muted">Please enter your last name.</span> */}
            </div>
            {/*end::Input*/}
          </div>
        </div>

        {/*begin::Input*/}
        <div className="form-group">
          <label>Phone</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fas fa-phone-square-alt"></i>
              </span>
            </div>
            <input
              type="tel"
              className={`form-control form-control-solid form-control-lg ${getInputClassesform1("phone")}`}
              name="phone"
              id="phone"
              value={formik1.values.phone}
              onBlur={formik1.handleBlur}
              onChange={formik1.handleChange}
            />
          </div>
          {formik1.touched.phone && formik1.errors.phone ? <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik1.errors.phone}</div>
          </div> : null}
          {/* <span className="form-text text-muted">Please enter your phone number.</span> */}
        </div>
        {/*end::Input*/}

        {/*begin::Input*/}
        <div className="form-group">
          <label>Email</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fas fa-envelope"></i>
              </span>
            </div>
            <input
              type="email"
              className={`form-control form-control-solid form-control-lg ${getInputClassesform1("email")}`}
              name="email"
              id="email"
              value={formik1.values.email}
              onBlur={formik1.handleBlur}
              onChange={formik1.handleChange}
            />
          </div>
          {formik1.touched.email && formik1.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik1.errors.email}</div>
            </div>
          ) : null}
          {/* <span className="form-text text-muted">Please enter your email address.</span> */}
        </div>
        {/*end::Input*/}
        <div className="row">
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>Gender</label>
              <select className="form-control" name="gender" id="gender"
                value={formik1.values.gender}
                onChange={formik1.handleChange}>
                <option value="" disabled>Select</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </select>
              {formik1.touched.gender && formik1.errors.gender ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik1.errors.gender}</div>
                </div>
              ) : null}
              {/* <span className="form-text text-muted">Please enter your first name.</span> */}
            </div>
            {/*end::Input*/}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>Employment Start Date</label>
              <input
                type="date"
                className="form-control form-control-solid form-control-lg"
                name="empstartdate"
                id="empstartdate"
                value={formik1.values.empstartdate}
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              {formik1.touched.empstartdate && formik1.errors.empstartdate ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik1.errors.empstartdate}</div>
                </div>
              ) : null}
              <span className="form-text text-muted">
                joining date
              </span>
            </div>
            {/*end::Input*/}
          </div>
        </div>
      </div>
      {/*end: Wizard Step 1*/}
    </form>

  )
}

export default Step1;
