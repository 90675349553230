import axios from "axios";
export const salonId = "839bec35-8989-459d-9733-b463c8cc4dba";

export function getServiceList(date) {
  // const salonId = JSON.parse(localStorage.getItem('loginInfo')).salons[0].salon.uuid;
  return axios.get(
    "salons/" + salonId + "/appointments/create/data?date=" + date
  );
}

export function getTimeSlot(data, stylist) {
  // const salonId = JSON.parse(localStorage.getItem('loginInfo')).salons[0].salon.uuid;
  return axios.post(
    "salon/" +
      salonId +
      "/personnel/" +
      stylist +
      "/appointment/slot/available",
    data
  );
}

export function getPersonnelWithSlot(data) {
  // const salonId = JSON.parse(localStorage.getItem('loginInfo')).salons[0].salon.uuid;
  let url = data.variation_uuid
    ? "salon/" +
      salonId +
      "/service/" +
      data.service_uuid +
      "/personnels?variation_uuid=" +
      data.variation_uuid +
      "&mode=APPT_SLOTS&date=" +
      data.date
    : "salon/" +
      salonId +
      "/service/" +
      data.service_uuid +
      "/personnels?mode=APPT_SLOTS&date=" +
      data.date;
  return axios.get(url);
}

export function searchConsumer(mobileNumber) {
  return axios.get("consumers?query=" + mobileNumber);
}

export function saveConsumer(data) {
  return axios.put("consumers", data);
}

export function saveOrder(data) {
  return axios.put("orders/custom", data);
}

export function generateIMDToken() {
  return axios.post("orders", null);
}
