/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {
  Layout,
  getInitLayoutConfig,
  setLayoutConfig,
  ContentRoute,
} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage, AuthCheck } from "./modules/Auth";
import ErrorPage from "./pages/ErrorPage";
import OnBoarding from "./modules/OnBoarding";
import CreateAppointments from "./modules/Appointments/createAppointment";
import Checkout from "./modules/Appointments/checkout/";

export function Routes() {
  const { isAuthorized, isDraft, isAuthCheckDone } = useSelector(({ auth }) => {
    return {
      isAuthorized: auth.isAuthorized,
      // isDraft: auth.currentSalon?.salon.basic.state === "DRAFT",
      isDraft: false,
      isAuthCheckDone: auth.isAuthCheckDone,
    };
  }, shallowEqual);

  useEffect(() => {
    document.body.classList.add(isDraft && "aside-not-shown");
  }, [isDraft]);

  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      {isDraft ? (
        <Route path="/onboarding" component={() => <OnBoarding />} />
      ) : null}

      {!isAuthCheckDone ? (
        <Route>
          <AuthCheck />
        </Route>
      ) : !isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : isDraft ? (
        <Redirect to="/onboarding" />
      ) : (
        <>
          <Layout>
            <BasePage />
          </Layout>
        </>
      )}
    </Switch>
  );
}
