

export const states = (function states() {
    return {
        orderDisplayid: '',
        orderDate: '',
        orderId: '',
        services: [],
        payment_state: '',
        state: '',
        totalAmount: 0,
        amountPaid: 0,
        amountDue: 0,
        consumer: null
    }
})()

