import React, { useState, useEffect } from "react";
import { Button, Divider,Paper, InputBase } from "@material-ui/core";
import CustomerCard from "../../components/customerCard";
import Search from "./search";
import "./styles.css";

import { Search as SearchIcon } from "@material-ui/icons";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

import { searchConsumer as getConsumer } from "../../new-appointment/New-appointment.service";
import { createIMDtoken } from "../../Appointment.service";

function PaymentSection({
  selectedServices,
  paymentOptions,
  createOrder,
  onPayment,
  selectedConsumer,
  setselectedConsumer,
  price,
  onConfirmSale,
  // selectedOrder,
  // onPayment,
  orderInfo,
  // isPaymentComplete,
}) {
  const [amount, setAmount] = useState("0");
  const [amountInputWidth, setAmountInputWidth] = useState();
  const [searchConsumer, setSearchConsumer] = useState("");
  const [consumers, setConsumers] = useState([]);
  const [token, setToken] = useState(null);
  const [userDetails, setUserDetails] = useState({});

  // useEffect(() => {
  //   const { basic } = selectedOrder.consumer;
  //   setUserDetails(basic);
  // }, []);

  useEffect(() => {
    if (orderInfo && orderInfo.basic) {
      setAmount(orderInfo.basic.price.price_net - orderInfo.basic.paid_amount);
    }
  }, [orderInfo]);

  useEffect(() => {
    const amountStr = String(amount);
    setAmountInputWidth((amountStr.length + 1) * 20 + "px");
  }, [amount]);

  // function onSelectingPayment(amount, paymentOption) {
  //   onPayment(amount, paymentOption);
  // }

  const onSelectConsumer = async (data) => {
    if (token === null) {
      try {
        const response = await createIMDtoken();
        setToken(response.data.imd_token, data.uuid);
        createOrder(response.data.imd_token, data.uuid);
      } catch (err) {
        console.log(err);
      }
    } else {
      createOrder(token, data.uuid);
    }
    console.log(data)
    setselectedConsumer(data);
    setConsumers([]);
    setSearchConsumer("");
  };

  const onSearchClient = (event) => {
    let newSearchConsumer = searchConsumer;
    let newConsumers = [...consumers];
    newSearchConsumer = event ? event.target.value : "";

    if (event && event.target.value.length > 4) {
      getConsumer(event.target.value).then((res) => {
        if (res.data.value) {
          newConsumers = res.data.value;
          newConsumers.forEach((list) => {
            if (list.basic.name_first && list.basic.name_first.length > 0) {
              list.basic.FirstNameC = list.basic.name_first.charAt(0);
            }
            if (list.basic.name_last && list.basic.name_last.length > 0) {
              list.basic.LastNameC = list.basic.name_last.charAt(0);
            }
          });
          setConsumers(newConsumers);
        }
      });
    } else {
      setConsumers(newConsumers);
    }
    setSearchConsumer(newSearchConsumer);
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center position-relative">
      {/* <div className="quick-search quick-search-dropdown bg-light">
          <Paper
            elevation={0}
            variant="outlined"
            className="d-flex justify-content-between align-items-center px-5 flex-grow-1 quick-search-form"
          >
            <SearchIcon className="mr-3" />
            <InputBase
              placeholder="Search Customer using Phone Number"
              inputProps={{
                "aria-label": "search customer using phone number",
              }}
              value={searchConsumer}
              onChange={(e) => onEnteringSearch(e.target.value)}
              onClick={() => setShowCustomerSearch(true)}
              className="flex-grow-1 py-2"
            />
          </Paper>
        </div> */}
      {(!selectedConsumer || !orderInfo.uuid) && (
        <Search
          searchConsumer={searchConsumer}
          onSearchClient={onSearchClient}
          selectedServices={selectedServices}
        />
      )}

      {consumers.map((list) => {
        return (
          <div className="w-100" onClick={() => onSelectConsumer(list)} key={list.uuid}>
            <CustomerCard
              firstName={list.basic.name_first || ""}
              lastName={list.basic.name_last || ""}
              email={list.basic.email || ""}
              mobile={list.basic.mobile || ""}
            />
          </div>
        );
      })}

      <div className="w-100">
        {selectedConsumer && (
          <CustomerCard
            firstName={selectedConsumer.basic.name_first || ""}
            lastName={selectedConsumer.basic.name_last || ""}
            email={selectedConsumer.basic.email || ""}
            mobile={selectedConsumer.basic.mobile || ""}
          />
        )}
      </div>

      {orderInfo.uuid && (
        <>
          <div className="mt-10 w-100 d-flex flex-column align-items-center px-3">
            <p align="center" className="amount-label">
              Pay
            </p>
            <div className="amount-container">
              &#x20B9;
              <input
                value={amount}
                className="amount-text ml-2"
                style={{ width: amountInputWidth }}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>

          <div
            className={`mt-5 d-flex flex-wrap justify-content-between paymentCTAcontainer w-100 px-3`}
          >
            {paymentOptions.map((option) => {
              return (
                <div
                  className={`paymentCTA d-flex align-items-center justify-content-center`}
                  key={option.basic.name}
                  role="button"
                  onClick={() => onPayment(amount, option)}
                >
                  <p align="center" className="cardText">
                    {option.basic.name}
                  </p>
                </div>
              );
            })}
          </div>

          <div className="mt-20 w-100 d-flex flex-column align-items-center button-container bg-white">
            <Divider className="w-100 mb-5" />
            <div className="w-100 d-flex justify-content-around px-2">
              <Button
                variant="outlined"
                className="button mb-5 text-capitalize bg-transparent button-more_options"
              >
                More Options
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className="button mb-5 text-capitalize button-confirm_sale"
                onClick={onConfirmSale}
                disabled={orderInfo.basic.payment_state !== "PAID"}
              >
                Confirm Sale
              </Button>
            </div>
          </div>
        </>
      )}

      {/* {
      (!orderInfo.uuid && selectedConsumer) &&
        <Button
        
          variant="contained"
          color="secondary"
          className="button mb-5 text-capitalize button-confirm_sale align-self-end mt-10"
        // onClick={onConfirmSale}

        >
          Create Order
        </Button>
        } */}
    </div>

  );
}

export default PaymentSection;
