import axios from "axios";

export function getInitalCustomers(salonuuid,num){
  console.log(num,"num")
  if(num){
    return axios.get("salons/"+salonuuid+"/consumers?query="+num)
  }
  return axios.get("salons/"+salonuuid+"/consumers")
}

export function getPageWiseCustomers(salonuuid,page){
  return axios.get("salons/"+salonuuid+"/consumers?page="+page)
}

export function saveConsumer({ payload }) {
  return axios.put("consumers", payload);
}