import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { authCheck } from "../_redux/authCrud";
import * as authRedux from "../_redux/authRedux";

function AuthCheck({ salonChange, setIsAuthCheckDone, setIsAuthorized }) {
  useEffect(() => {
    checkUserAuth();
  }, []);

  function getCurrentSalons(salons) {
    const currentSalon =
      salons.find((salon) => {
        return (
          salon.salon.basic.location.micro_market !== null &&
          salon.salon.basic.state === "ENABLED"
        );
      }) || salons[0];
    return currentSalon;
  }

  async function checkUserAuth() {
    try {
      const { data } = await authCheck();
      const currentSalon = getCurrentSalons(data.salons);
      const isDraft = currentSalon.salon.basic.state === "DRAFT";
      salonChange(currentSalon);
      setIsAuthorized(true);
    } catch (e) {
      setIsAuthorized(false);
      console.log(e);
    }
    setIsAuthCheckDone(true);
  }

  return <p>Checking auth...</p>;
}

export default connect(null, authRedux.actions)(AuthCheck);
