import React from 'react';
import './AvailableStylist.css'
import {Form} from 'react-bootstrap'
const Stylist =  (props)=>{
  const {name,designation,value,handleChange,selectedPersonnels} = props;

  return (
    <div className="d-flex flex-row mb-4">
        <Form.Check type="checkbox" className="d-flex align-items-center gb-checkbox" name="staff" onChange={handleChange} value={value} checked={selectedPersonnels.includes(value)}/>
      <div className="d-flex flex-row ml-2" >
        <div className="avatar d-flex align-items-center justify-content-center">N</div>
        <div className="details d-flex align-items-center flex-column">
          <div className="name d-flex align-self-start">
            {name}
          </div>
          <p className="designation d-flex align-self-start text-muted">
            {designation}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Stylist  