import React, { useState, useEffect } from 'react';
import './styles.css';

import moment from "moment";

import { Row, Col, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import DealSingle from './SingleDeal/DealSingle';

import { getAllDeals, getStateDeals } from './crud';

import { connect } from 'react-redux';
import { initial } from 'lodash';

const DealsListing = (props) => {
  const { currentSalon: { salon: { uuid: currentSalonuuid } } } = props.auth

  const [type, setType] = useState('ALL')
  const [loading, setLoading] = useState(true)
  const [dealsPageData, setDealsPageData] = useState(null)
  const [dealsNext, setDealsNext] = useState("")
  const [dealsValues, setDealsValues] = useState([])

  async function getDeals(nextLink) {
    try {
      setLoading(true);
      const response = await getAllDeals(currentSalonuuid, nextLink);
      console.log(response);
      if (nextLink === "initial") {
        setDealsPageData({
          pageSize: response.data.page_size,
          totalPages: response.data.total_pages,
          totalRecords: response.data.total_records
        })
        setDealsValues([...response.data.value])
      } else {
        setDealsValues([...dealsValues, ...response.data.value])
      }
      setDealsNext(response.data.next_link)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  async function getStateWiseDeals(type, nextLink) {
    try {
      setLoading(true);
      const response = await getStateDeals(currentSalonuuid, type, nextLink);
      console.log(response);
      if (nextLink === "initial") {
        setDealsPageData({
          pageSize: response.data.page_size,
          totalPages: response.data.total_pages,
          totalRecords: response.data.total_records
        })
        setDealsValues([...response.data.value])
      } else {
        setDealsValues([...dealsValues, ...response.data.value])
      }
      setDealsNext(response.data.next_link)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getDeals("initial");
  }, [currentSalonuuid])

  const handleType = (type) => {
    if (type === "ALL") {
      getDeals("initial")
    } else {
      getStateWiseDeals(type, "initial")
    }
    setType(type);
  }

  const handleScrollUp = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log("hit hit")
    if (bottom && dealsNext) {
      console.log(bottom)
      if (type === "ALL") {
        getDeals(dealsNext)
      }
      else {
        getStateWiseDeals(type, dealsNext)
      }
    }
  }

  return (
    <div className="DealsListing">
      <div className="DealsListing-topbar pb-5">
        <div className="d-flex align-items-center">

          <h2 className="mr-5 mb-0">Deals</h2>
          <DropdownButton variant="light" id="dropdown-basic-button" title={type}>
            <Dropdown.Item onClick={() => handleType("ALL")}>ALL</Dropdown.Item>
            <Dropdown.Item onClick={() => handleType("DRAFT")} >DRAFT</Dropdown.Item>
            <Dropdown.Item onClick={() => handleType("AVAILABLE")} >AVAILABLE</Dropdown.Item>
            <Dropdown.Item onClick={() => handleType("UNAVAILABLE")} >UNAVAILABLE</Dropdown.Item>
          </DropdownButton>

        </div>

        <Link to='/newDeal'>
          <Button>Create new deal</Button>
        </Link>
      </div>


      {
        dealsValues.length === 0 &&
        <div className="DealsListing-empty">
          <div>
            <img src="/media/empty_screen.svg" alt="no deals" />
            <h2>No {type === "ALL" ? "" : type} deals yet</h2>
          </div>
        </div>
      }


      <Row className="DealsListing-deals" onScroll={handleScrollUp}>
        {dealsValues.map(ob => (
          <Col className="mb-5" md={6} key={ob.uuid}>
            <DealSingle
              data={{
                title: ob.basic.name,
                description: ob.basic.description,
                gender: ob.basic.served_gender.display_name,
                startDate: moment(ob.basic.goes_live_at.date).format('DD MMM, YYYY'),
                endDate: moment(ob.basic.expires_at.date).format('DD MMM, YYYY'),
                price: Math.round(ob.basic.price.price_net),
                total: ob.basic.quantity.total,
                sold: ob.basic.quantity.used,
                image: ob.basic.main_image?ob.basic.main_image.basic.url_full:"/media/noImage.png",
                bg: ob.basic.main_color_style?ob.basic.main_color_style.basic.hex_code:"#D8D8D8",
                state: ob.basic.state,
                services: ob.services
              }}
            />
          </Col>
        ))}
        {<h1>{(loading && dealsValues.length) ? "Loading" : ""}</h1>}
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(DealsListing);