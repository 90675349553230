import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Filter({ categories, activeCategory, onCategorySelect }) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" className="bg-white">
        {activeCategory}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {categories.map((item) => (
          <Dropdown.Item
            key={item}
            onClick={() => onCategorySelect(item)}
            active={item === activeCategory}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Toolbar({
  categories,
  activeCategory,
  onCategorySelect,
  currentDate,
  onDateChange,
}) {
  return (
    <div className="container-fluid d-lg-flex justify-content-between align-items-center navbar navbar-light mb-10 p-3">
      <Filter
        categories={categories}
        activeCategory={activeCategory}
        onCategorySelect={onCategorySelect}
      />
      <button
        type="button"
        className="d-flex align-items-center btn btn-outline-dark"
      >
        <span>Today: </span>
        <DatePicker
          selected={moment(currentDate).toDate()}
          onChange={onDateChange}
          dateFormat="dd/MM/yyyy"
          className="border-0 text-center"
        />
        <i class="flaticon2-calendar-9"></i>
      </button>
      <Button variant="outline-dark">Add</Button>
    </div>
  );
}

export { Filter };

export default Toolbar;
