import React from 'react';
import moment from 'moment';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function getTableRowData(stylists){
  console.log(stylists);
  const TableRowData = []
  let row = null;
  for(let stylist of stylists){
    row = {
      uuid:stylist.uuid,
      name: `${stylist.basic.name_first} ${stylist.basic.name_last}`,
      phone:stylist.basic.mobile,
      status:stylist.basic.active,
      genderServed:stylist.basic.values.salon.served_gender_obj?stylist.basic.values.salon.served_gender_obj.display_name:"N/A",

      rank:stylist.basic.rank?stylist.basic.rank:"N/A",
      
      personnelCategory:stylist.rank_obj?stylist.rank_obj.basic.personnel_category.display_name:"N/A",

      profilePic:stylist.basic.profile_pic,
      avgRating:stylist.basic.values.salon.rating_avg,
      timeIn:stylist.basic.time_in?moment(stylist.basic.time_in,'HHmmss').format('LT'):"N/A",
      timeOut:stylist.basic.time_out?moment(stylist.basic.time_out,'HHmmss').format('LT'):"N/A"
    }
    TableRowData.push(row);
  }
  return TableRowData;
}


export const sortCaret = (order, column) => {
  if (!order) return (
    <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")}/>
    </span>
  );
  else if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")}/>
      </span>
    );
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")}/>
      </span>
    );
  return null;
};
