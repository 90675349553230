import React from 'react';
import './DealSingle.css';

import { Media, ProgressBar } from 'react-bootstrap';

import Paper from '@material-ui/core/Paper';

import LightBlueButton from '../../../commonComponents/LightBlueButton/LightBlueButton';


const DealSingle = ({data}) => {
  const {title,description,gender,
    services,startDate,endDate,price,total,sold,image,bg,state} = data
    const earning = Math.round(price*sold);
    const progress = ((sold/total)*100).toFixed(2)
    const serviceNames = services.map(ob=>ob.service.name).toString()
    
  return (
    <Paper elevation={0} className="DealSingle pt-3 pb-5 px-5">
      <div className="text-right">
        <i className="fas fa-ellipsis-h"></i>
      </div>

      <div className="DealSingle-body">

        <Media>
          <img
          style={{backgroundColor:bg}}
            height={130}
            className="mr-3 rounded"
            src={image}
            alt="Generic placeholder"
          />
          <Media.Body >
            <h5 className="DealSingle-title">{title}</h5>
            <p className="text-muted mb-2">{description}</p>
            <p className="text-primary mb-2"> For {gender}</p>
            <p className="m-0 DS-tc">SERVICES INCLUDED</p>
            <p >{serviceNames}</p>
          </Media.Body>
        </Media>

        <div className="DealSingle-middle">
          <div className="pr-2">
            <p className="DS-tc">START DATE</p>
            <LightBlueButton title={startDate} className="px-2"/>
          </div>
          <div className="pr-2">
            <p className="DS-tc">END DATE</p>
            <div className="DealSingle-unactive">
              {endDate}
        </div >
          </div >
          <div className="DealSingle-progress">
            <p className="DS-tc">PROGRESS</p>
            <ProgressBar now={progress} />
            <p className="pt-1">{progress}%</p>
          </div>
        </div>

        <div className="DealSingle-bottom">
          <div className="pr-2">
            <p className="mb-1 DS-tc">PRICE</p>
            <p className="fontw-600">Rs {price}</p>
          </div>
          <div className="pr-2">
            <p className="mb-1 DS-tc">TOTAL UNITS</p>
            <p className="fontw-600">{total}</p>
          </div>
          <div className="pr-2">
            <p className="mb-1 DS-tc">UNITS SOLD</p>
            <p className="fontw-600">{sold}</p>
          </div>
          <div className="pr-2">
            <p className="mb-1 DS-tc">EARNINGS</p>
            <p className="fontw-600">Rs {earning}</p>
          </div>
          <div>
            <div className="DealSingle-live">
              {state}
            </div>
          </div>
        </div>

      </div>


    </Paper>
  )
}

export default DealSingle;