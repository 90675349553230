import moment from 'moment';


export function getTableRowData(data){
  const tableData = data.map(row=>({
    uuid:row.uuid,
    fname:row.basic.name_first?row.basic.name_first:"dummy",
    lname:row.basic.name_last?row.basic.name_last:"dummy",
    name:row.basic.name_first+" "+row.basic.name_last,
    phone:row.basic.mobile,
    email:row.basic.email?row.basic.email:"dummy",
    appInstalled:row.salon_consumer.basic.app_installed,
    customerSince:row.salon_consumer.basic.first_booking_at?moment(row.salon_consumer.basic.first_booking_at.slice(0,10)).format('D MMM, YYYY'):"dummy",
  }))

  return tableData
}