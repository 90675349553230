const initialAuthState = {
  isCreateAppointmentOpen: false,
};

export const actionTypes = {
  LaunchCreateAppointment: "LaunchCreateAppointment",
  CloseCreateAppointment: "CloseCreateAppointment",
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.LaunchCreateAppointment: {
      const { status } = action.payload;
      return { isCreateAppointmentOpen: status };
    }

    case actionTypes.CloseCreateAppointment: {
      const { status } = action.payload;
      return { isCreateAppointmentOpen: status };
    }

    default:
      return state;
  }
};

export const actions = {
  openCreateAppointment: () => ({
    type: actionTypes.LaunchCreateAppointment,
    payload: { status: true },
  }),
  closeCreateAppointment: () => ({
    type: actionTypes.CloseCreateAppointment,
    payload: { status: false },
  }),
};
