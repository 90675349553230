export const data1 = [
  {
    id: "1",
    time: "12:00 am",
    date: "26 nov,2020",
    service: "Body Scrub-Product B",
    duration: "30",
    stylist: "sharukh",
    price: "300",
    completed: false,
    cancelled: false
  },
  {
    id: "2",
    time: "12:00 am",
    date: "26 nov,2020",
    service: "Pedicure",
    duration: "60",
    stylist: "sameer",
    price: "400",
    completed: false,
    cancelled: false
  },
  {
    id: "3",
    time: "1:15 pm",
    date: "26 nov,2020",
    service: "Spa",
    duration: "30",
    stylist: "sameer",
    price: "700",
    completed: false,
    cancelled: false
  },
  {
    id: "4",
    time: "2:30 pm",
    date: "26 nov,2020",
    service: "Nail Art",
    duration: "90",
    stylist: "sumit",
    price: "350",
    completed: false,
    cancelled: false
  },
  
]

export const data2 = [
  {
    id: "1",
    time: "9:30 am",
    date: "26 nov,2020",
    service: "Haircut",
    duration: "30",
    stylist: "sharukh",
    price: "300",
    completed: true,
    cancelled: false
  },
  {
    id: "2",
    time: "10:00 am",
    date: "26 nov,2020",
    service: "Hair cut",
    duration: "60",
    stylist: "sharukh",
    price: "400",
    completed: false,
    cancelled: true
  },
  {
    id: "3",
    time: "10:00 pm",
    date: "26 nov,2020",
    service: "Spa",
    duration: "30",
    stylist: "sameer",
    price: "700",
    completed: true,
    cancelled: false
  },

  {
    id: "4",
    time: "10:20 pm",
    date: "26 nov,2020",
    service: "Hair treatment",
    duration: "60",
    stylist: "vinay",
    price: "1200",
    completed: true,
    cancelled: false
  },
  
]

export const data3 = [
  {
    id: "1",
    time: "12:00 am",
    date: "26 nov,2020",
    service: "Body Scrub-Product B",
    duration: "30",
    stylist: "sharukh",
    price: "300",
    completed: false,
    cancelled: false
  },
  {
    id: "2",
    time: "12:00 am",
    date: "26 nov,2020",
    service: "Pedicure",
    duration: "60",
    stylist: "sameer",
    price: "400",
    completed: false,
    cancelled: false
  },
  {
    id: "3",
    time: "1:15 pm",
    date: "26 nov,2020",
    service: "Spa",
    duration: "30",
    stylist: "sameer",
    price: "700",
    completed: false,
    cancelled: false
  },
  {
    id: "4",
    time: "2:00 pm",
    date: "26 nov,2020",
    service: "Haircut",
    duration: "30",
    stylist: "sharukh",
    price: "300",
    completed: false,
    cancelled: false
  },
]

