import React, { useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from "react-router";

import { Dropdown, DropdownButton } from "react-bootstrap";
import {actions} from '../../../../app/modules/Auth/_redux/authRedux';
import {checkIsActive} from '../../../_helpers';


const SaloonDropdown = (props) => {
  const location = useLocation();
  const { user: { salons },currentSalon} = props.auth;
  console.log(props.auth)
  console.log(props.auth.currentSalon)

  const availableSalons = salons.filter(salon=>
    salon.salon.basic.location.micro_market!==null 
    // && 
    // salon.salon.basic.state === "ENABLED"
    )

  const [selected, setSelected] = useState(currentSalon.salon.basic.name+"-"+currentSalon.salon.basic.location.micro_market.name);

  const handleChange = (s) =>{
    setSelected(s.salon.basic.name+"-"+s.salon.basic.location.micro_market.name);
    props.salonChange(s);
  }

  const isVisible = (url) => {
    return checkIsActive(location, url) ? true : false;
}

  const display = isVisible('/dashboard') || isVisible('/sales') || isVisible('/details') || isVisible('/deals') || isVisible('/user');
  if(!display){
    return <div></div>
  }
  return (
    <div className="topbar-item mr-2">
      <DropdownButton id="dropdown-salon" title={selected} variant="light">
        {availableSalons.map((s) =>
          <Dropdown.Item
            key={s.salon.uuid}
            onClick={() => handleChange(s)} >
            {s.salon.basic.name+"-"+s.salon.basic.location.micro_market.name}
          </Dropdown.Item>
        )}
      </DropdownButton>
    </div>

  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps,actions)(SaloonDropdown);