import axios from 'axios';

// reading from Service readAll.
export function getAllServicesOfSaloon(salonuuid){
  return axios.get('salons/' + salonuuid + '/services');
}

// reading from ServiceType readAll.
export function getAllCategoriesOfSaloon(salonuuid){
  return axios.get('salons/' + salonuuid + '/services/types');
}

// put at Service Type
export function postNewCategory(newCat,salonuuid){
  console.log(newCat)
  return axios.put('salons/' + salonuuid + '/services/types',newCat);
}

//post at Service Type

export function UpdateCategory(newCat,salonuuid){
  console.log(newCat)
  return axios.post('salons/' + salonuuid + '/services/types',newCat);
}