import React from 'react';
import './SolidColors.css';

const SolidColors = ({setbgColor,colors,loading}) => {
  if (loading) return (<h1>loading</h1>)
  return (
    <div className="SolidColors pt-5">
      {
        colors.map(color =>
          <span
            key={color.uuid}
            className="SolidColors-color"
            style={{ backgroundColor: color.basic.hex_code }}
            onClick={()=>setbgColor(color)} />
        )
      }
    </div>
  );
}

export default SolidColors;