import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { formSchema } from "./formSchema";
import Step1Form from "./step-1";
import Step2Form from "./step-2";
import Step3Form from "./step-3";
import Step4Form from "./step-4";
import "./style.css";

const steps = [
  {
    title: "Basic Details",
    description: "Add basic details of salon",
    number: 1,
  },
  {
    title: "Operation Details",
    description: "Add basic details of salon",
    number: 2,
  },
  {
    title: "Tax and Billing",
    description: "Add basic details of salon",
    number: 3,
  },
  {
    title: "Images",
    description: "Add basic details of salon",
    number: 4,
  },
];

function Forms({
  step,
  intl,
  values,
  formik,
  possibleValues,
  initialValues,
  setFormValidity,
  formSchema,
  getFormValues,
  salonUUID,
  onAddingImage,
  onClickOfDeleteImage,
}) {
  switch (step) {
    case 1:
      return (
        <Step1Form
          isCurrent={step === 1}
          intl={intl}
          values={values}
          formik={formik}
          possibleValues={possibleValues}
          initialValues={initialValues[1]}
          setFormValidity={setFormValidity}
          formSchema={formSchema[1]}
          getFormValues={(values) => getFormValues(1, values)}
        />
      );
    case 2:
      return (
        <Step2Form
          isCurrent={step === 2}
          intl={intl}
          values={values}
          formik={formik}
          possibleValues={possibleValues}
          initialValues={initialValues[2]}
          setFormValidity={setFormValidity}
          formSchema={formSchema[2]}
          getFormValues={(values) => getFormValues(2, values)}
        />
      );
    case 3:
      return (
        <Step3Form
          isCurrent={step === 3}
          intl={intl}
          values={values}
          formik={formik}
          possibleValues={possibleValues}
          initialValues={initialValues[3]}
          setFormValidity={setFormValidity}
          formSchema={formSchema[3]}
          getFormValues={(values) => getFormValues(3, values)}
        />
      );
    case 4:
      return (
        <Step4Form
          isCurrent={step === 4}
          intl={intl}
          values={values}
          formik={formik}
          possibleValues={possibleValues}
          initialValues={initialValues[4]}
          setFormValidity={setFormValidity}
          formSchema={formSchema[4]}
          getFormValues={(values) => getFormValues(4, values)}
          salonUUID={salonUUID}
          onAddingImage={onAddingImage}
          onClickOfDeleteImage={onClickOfDeleteImage}
        />
      );
    default:
      return <div></div>;
  }
}

function FormControls({
  onDirectionClick,
  showSubmit,
  showPrevious,
  onSubmitClick,
  enableNext,
}) {
  return (
    <div className="d-flex justify-content-between border-top mt-5 pt-10">
      <div className="mr-2">
        {showPrevious ? (
          <button
            className="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
            onClick={(e) => {
              e.preventDefault();
              onDirectionClick("previous");
            }}
          >
            Previous
          </button>
        ) : null}
      </div>
      <div>
        {showSubmit ? (
          <button
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
            disabled={!enableNext}
            onClick={(e) => {
              e.preventDefault();
              onSubmitClick();
            }}
          >
            Submit
          </button>
        ) : (
          <button
            className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
            disabled={!enableNext}
            onClick={(e) => {
              e.preventDefault();
              onDirectionClick("next");
            }}
          >
            Next Step
          </button>
        )}
      </div>
    </div>
  );
}

function OnBoardingForms({
  intl,
  initialValues,
  possibleValues,
  onDirectionClick,
  currentStep,
  onSubmitClick,
  salonUUID,
  onAddingImage,
  onClickOfDeleteImage,
}) {
  const [isFormValid, setIsFormValid] = useState(false);
  const [formValues, setFormValues] = useState({ ...initialValues });

  function onDirectionChangeReq(direction) {
    onDirectionClick(direction, formValues);
  }

  function setFormValidity(value) {
    setIsFormValid(value);
  }

  function getFormValues(step, values) {
    formValues[step] = { ...values };
    setFormValues({ ...formValues });
  }

  function onSubmit() {
    onSubmitClick(formValues);
  }

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="card card-custom card-transparent">
          <div className="card-body p-0">
            <div
              className="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <div className="wizard-nav">
                <div className="wizard-steps">
                  {steps.map((step) => {
                    return (
                      <div
                        key={step.title}
                        className="wizard-step"
                        data-wizard-type="step"
                        data-wizard-state={
                          step.number === currentStep ? "current" : ""
                        }
                      >
                        <div className="wizard-wrapper">
                          <div className="wizard-number">{step.number}</div>
                          <div className="wizard-label">
                            <div className="wizard-title">{step.title}</div>
                            <div className="wizard-desc">
                              {step.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                  <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                    <div className="col-xl-12 col-xxl-7">
                      <form className="form mt-0 mt-lg-10" id="kt_form">
                        <Forms
                          step={currentStep}
                          intl={intl}
                          possibleValues={possibleValues}
                          initialValues={formValues}
                          formSchema={formSchema}
                          setFormValidity={setFormValidity}
                          getFormValues={getFormValues}
                          salonUUID={salonUUID}
                          onAddingImage={onAddingImage}
                          onClickOfDeleteImage={onClickOfDeleteImage}
                        />
                        <FormControls
                          onDirectionClick={onDirectionChangeReq}
                          showSubmit={currentStep === 4}
                          showPrevious={currentStep > 1}
                          onSubmitClick={onSubmit}
                          enableNext={isFormValid}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(OnBoardingForms);
