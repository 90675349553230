import axios from "axios";

export function getAppointmentList({ date, salonUUID }) {
  return axios.get(`salons/${salonUUID}/appointments`, { params: { date } });
}

export function getAppointmentDetail(id) {
  return axios.get("orders/" + id);
}

export function fetchServiceList({ date, salonUUID }) {
  return axios.get(`salons/${salonUUID}/appointments/create/data`, {
    params: { date },
  });
}

export function fetchStylistList({
  date,
  salonUUID,
  serviceUUID,
  variationUUID,
}) {
  return axios.get(`salon/${salonUUID}/service/${serviceUUID}/personnels`, {
    params: { mode: "APPT_SLOTS", date, variation_uuid: variationUUID },
  });
}

export function getTimeSlot({ payload, stylistUUID, salonUUID }) {
  return axios.post(
    `salon/${salonUUID}/personnel/${stylistUUID}/appointment/slot/available`,
    payload
  );
}

export function searchConsumer({ query }) {
  return axios.get("consumers", { params: { query } });
}

export function saveConsumer({ payload }) {
  return axios.put("consumers", payload);
}

export function saveOrder({ payload }) {
  return axios.put("orders/custom", payload);
}

export function generateIMDToken() {
  return axios.post("orders", null);
}
