import axios from "axios"

export function getConsumer(salonuuid,consumeruuid){
  return axios.get("salons/"+salonuuid+"/consumers/"+consumeruuid);
}

export function getUpcomingAppointments(salonuuid,consumeruuid,nextLink){
  if(nextLink && nextLink!=="initial"){
    return axios.get(nextLink)
  }
  return axios.get("consumers/"+consumeruuid+"/appointments?salon_uuid="+salonuuid+"&cat=UPCOMING");
}

export function getPastAppointments(salonuuid,consumeruuid,nextLink){
  if(nextLink && nextLink!=="initial"){
    return axios.get(nextLink)
  }
  return axios.get("consumers/"+consumeruuid+"/appointments?salon_uuid="+salonuuid+"&cat=PAST");
}

export function getDeals(salonuuid,consumeruuid,nextLink){
  console.log(nextLink)
  if(nextLink && nextLink!=="initial"){
    return axios.get(nextLink)
  }
  return axios.get("consumers/"+consumeruuid+"/orders?salon_uuid="+salonuuid+"&type=SALON_DEAL");
}
