import React from 'react';
import {Button} from 'react-bootstrap';

const SelectOperations = ({ selectedRows }) => {
  return (
    <div className="StylistSelectOperations">
      <p>Selected Rows Count: <b>{selectedRows.length}</b></p>
      <Button variant="danger">
      <i className="fas fa-trash-alt mr-2"></i>
        Delete All
      </Button>
    </div>
  )
}

export default SelectOperations;