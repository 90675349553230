import React from "react";
import { Button } from "react-bootstrap";

function SubmissionAck() {
  return (
    <div className="card card-custom mb-2 bg-diagonal bg-diagonal-primary bg-diagonal-r-light">
      <div className="card-body d-flex align-items-center justify-content-between p-4">
        <div className="d-flex flex-column mr-5">
          <p className="h4 text-white text-hover-primary mb-5">Under Review</p>
          <p className="text-white-50">
            Our team will review your details and get back shortly
          </p>
        </div>
        <div className="ml-6 flex-shrink-0 bg-primary">
          <a
            className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-3 px-6"
            href="https://gobony.freshdesk.com/support/tickets/new"
            target="_blank"
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  );
}

export default SubmissionAck;
