import React, { useEffect, useState } from "react";
import SelectInput from "./selectInput";
import {
  CardContent,
  IconButton,
  Card,
  makeStyles,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles({
  cardRoot: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
    position: "relative",
    padding: "5px",
  },
  closeIcon: {
    position: "absolute",
    top: "2px",
    right: "2px",
    padding: "0px",
  },
});

function AddAppointment({
  servicesList,
  stylistList,
  availableSlots,
  onChange,
  remove,
  appointments,
  appointmentIndex,
  loadingStylistsFor,
}) {
  const classes = useStyles();
  const [appointment, setAppointment] = useState();

  useEffect(() => {
    setAppointment(appointments[appointmentIndex]);
  }, [appointments]);

  return (
    <Box className="mb-10 p-4" variant="outlined">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
        classes={{ root: classes.cardRoot }}
      >
        <IconButton className={classes.closeIcon} onClick={remove}>
          <CloseIcon />
        </IconButton>
        <Grid item xs={4}>
          <Typography className="mb-2">Select Services</Typography>
          <SelectInput
            value={appointment?.service}
            placeHolder="Select Service"
            data={servicesList}
            onChange={(value) => onChange(value, "service")}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className="mb-2">Select Stylist</Typography>
          <SelectInput
            disable={loadingStylistsFor === appointmentIndex}
            value={appointment?.stylist}
            placeHolder="Select Stylists"
            data={stylistList}
            onChange={(value) => onChange(value, "stylist")}
            loading={loadingStylistsFor === appointmentIndex}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className="mb-2">Select Time</Typography>
          <SelectInput
            value={appointment?.time}
            disable={
              !appointments[appointmentIndex].stylist ||
              !appointments[appointmentIndex].service
            }
            placeHolder="Select Time"
            onChange={(value) => {
              onChange(value, "time");
            }}
            data={availableSlots.filter((slot) => {
              return !appointments.find((item) => {
                return (
                  item.time?.label === slot.label &&
                  item.stylist.value ===
                    appointments[appointmentIndex].stylist?.value
                );
              });
            })}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddAppointment;
