import { createPlugin } from "@fullcalendar/core";

const CustomViewConfig = {
  classNames: ["custom-view"],

  content: function() {
    let html = `<div class="w-100 h-100 d-flex justify-content-center align-items-center bg-white">
            <img src="/media/closed-svgrepo-com.svg"/>
      </div>`;

    return { html: html };
  },
};

export default createPlugin({
  views: {
    custom: CustomViewConfig,
  },
});
