import React from 'react';
import {Popover,OverlayTrigger} from 'react-bootstrap';

const PopoverTooltip = (props) =>{
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{props.title}</Popover.Title>
      <Popover.Content>
        {props.children}
      </Popover.Content>
    </Popover>
  );
  return(
    <OverlayTrigger placement="top" overlay={popover}>
      <i className="fas fa-info-circle ml-1"></i>
    </OverlayTrigger>
  )
}

export default PopoverTooltip;