import React, { useState } from 'react';
import './Dashboard.css';

import RecentSales from './Components/RecentSales/RecentSales';
import AppointmentsCard from './Components/AppointmentsCard';
import TopServices from './Components/TopServices';
import QuickStart from './Components/QuickStart/QuickStart';

import { Row, Col } from 'react-bootstrap';

import {data1, data2, data3} from './dummyData';

const Dashboard = () => {
  const [isQuickStart, setIsQuickStart] = useState(false)
  return (
    <div className="Dashboard">
      <Row>
        <Col>
          <Row>
            <Col md={6} className="RecentSales-container">
              <RecentSales />
            </Col>
            <Col md={6} className="mt-5 mt-md-0">
              <AppointmentsCard label={"Today's Next Appointments"} data={data1}/>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <AppointmentsCard label={"Appointment Activities"} data={data2} />
            </Col>
            <Col md={6} className="mt-5 mt-md-0">
              <AppointmentsCard label={"Upcoming Appointments"} data={data3} />
            </Col>
          </Row>
        </Col>

        {
          isQuickStart &&
          <Col xs={{span:12,order:"first"}} 
          md={{span:3,order:"last"}} 
          xl={{span:4,order:"last"}} className="mb-5 mb-md-0">
            <QuickStart setIsQuickStart={setIsQuickStart} />
          </Col>
        }

      </Row>

      <Row className="mt-5">
        <Col md={6}>
          <TopServices label="Top Services" services />
        </Col>
        <Col md={6} className="mt-5 mt-md-0">
          <TopServices label="Top Staff" />
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard;