import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  startOfYear,
  endOfYear,
  addMonths,
  addQuarters,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfQuarter: startOfQuarter(new Date()),
  startOfYear: startOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date, -1)),
  endOfLastYear: endOfYear(addYears(new Date, -1)),
  startOfLast7Day: startOfDay(addDays(new Date(), -7)),
  startOfLast30Day: startOfDay(addDays(new Date, -30)),
  startOfLast90Day: startOfDay(addDays(new Date, -90)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: new Date(),
      label: 'Today',
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
      label: 'Yesterday',

    }),
  },

  {
    label: 'Last 7 days',
    range: () => ({
      startDate: defineds.startOfLast7Day,
      endDate: defineds.endOfYesterday,
      label: 'Last 7 days',

    }),
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: defineds.startOfLast30Day,
      endDate: defineds.endOfYesterday,
      label: 'Last 30 days',

    }),
  },
  {
    label: 'Last 90 days',
    range: () => ({
      startDate: defineds.startOfLast90Day,
      endDate: defineds.endOfYesterday,
      label: 'Last 90 days',

    }),
  },
  {
    label: 'Last month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      label: 'Last month',

    }),
  },
  {
    label: 'Last year',
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
      label: 'Last year',

    }),
  },
  {
    label: 'Week to date',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfYesterday,
      label: 'Week to date',

    }),
  },
  {
    label: 'Month to date',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfYesterday,
      label: 'Month to date',

    }),
  },
  {
    label: 'Quarter to date',
    range: () => ({
      startDate: defineds.startOfQuarter,
      endDate: defineds.endOfYesterday,
      label: 'Quarter to date',

    }),
  },
  {
    label: 'Year to date',
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYesterday,
      label: 'Year to date',

    }),
  },
  {
    label: 'All time',
    range: () => ({
      startDate: null,
      endDate: new Date(),
      label: 'All time',
    }),
  },
]);

export const defaultInputRanges = [
  {
    label: 'days up to today',
    range(value) {
      return {
        startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
        endDate: defineds.endOfToday,
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
      if (!range.startDate) return '∞';
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
    },
  },
  {
    label: 'days starting today',
    range(value) {
      const today = new Date();
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1),
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
      if (!range.endDate) return '∞';
      return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
    },
  },
];