import React, { useState } from 'react';
import './UserAppointments.css';

import moment from "moment";

import SingleAppointment from '../../../../commonComponents/SingleAppointment';
import Paper from '@material-ui/core/Paper';



const UserAppointments = ({ upcoming, getUpAppointments, upcomingNext, past, pastNext, getPaAppointments }) => {
  const [loading, setLoading] = useState(false)
  const [loadingp, setLoadingp] = useState(false)

  console.log(upcoming)


  const handleScrollUp = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log("hit hit")
    if (bottom && upcomingNext) {
      console.log(bottom)
      setLoading(true)
      getUpAppointments()
      setLoading(false)
    }
  }

  const handleScrollPa = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log("hit hit")
    if (bottom && pastNext) {
      console.log(bottom)
      setLoadingp(true)
      getPaAppointments()
      setLoadingp(false)
    }
  }

  return (
    <div className="UserAppointments p-4 ">
      <h5>
        Upcoming
      </h5>
      {
        upcoming.length === 0 &&
        <h5 className="text-center">No appointments yet</h5>
      }

      <div className="UserAppointments-up" onScroll={handleScrollUp}>
        {upcoming.map(ob => <SingleAppointment key={ob.uuid}
          data={{
            time: moment(ob.basic.time.start, "HH:mm").format('h:mm a'),
            date: moment(ob.basic.date).format('D MMM, YYYY'),
            duration: ob.basic.duration_minutes,
            state: ob.basic.state,
            stylist: ob.personnel.basic.name_first,
            service: ob.details.service.name,
            price: ob.details.service.price.price_net,
            completed: false,
            cancelled: false
          }}
        />)}

        {<h1>{loading ? "Loading" : ""}</h1>}
      </div>

      <h5 className="mt-2">
        Past
      </h5>

      {
        past.length === 0 &&
        <h5 className="text-center">No appointments yet</h5>
      }

      <div className="UserAppointments-pa" onScroll={handleScrollPa}>
        {past.map(ob => <SingleAppointment key={ob.uuid}
          data={{
            time: moment(ob.basic.time.start, "HH:mm").format('h:mm a'),
            date: moment(ob.basic.date).format('D MMM, YYYY'),
            duration: ob.basic.duration_minutes,
            state: ob.basic.state,
            stylist: ob.personnel.basic.name_first,
            service: ob.details.service.name,
            price: ob.details.service.price.price_net,
            completed: false,
            cancelled: false
          }}
        />)}
        {<h1>{loadingp ? "Loading" : ""}</h1>}
      </div>


    </div>

  );
}

export default UserAppointments;