import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { useSnackbar } from "notistack";
import * as auth from "../_redux/authRedux";
import { resetPassword, requestOTP } from "../_redux/authCrud";

const initialValues = {
  email: "",
};

const initialValuesResetForm = {
  otp: "",
  password: "",
  confirmPassword: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const ResetPasswordSchema = Yup.object().shape({
    otp: Yup.string()
      .min(6, "Minimum 6 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(12, "Maximum 12 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirmPassword: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(12, "Maximum 12 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    const context = isRequested ? resetFormik : formik;
    if (context.touched[fieldname] && context.errors[fieldname]) {
      return "is-invalid";
    }

    if (context.touched[fieldname] && !context.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        await requestOTP(values.email);
        setIsRequested(true);
      } catch (e) {
        setIsRequested(false);
        setSubmitting(false);
        setStatus(
          intl.formatMessage(
            { id: "AUTH.VALIDATION.NOT_FOUND" },
            { name: values.email }
          )
        );
      }
    },
  });

  const resetFormik = useFormik({
    initialValues: initialValuesResetForm,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (values.password !== values.confirmPassword) {
        setStatus(
          intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD_MISMATCH" })
        );
        return;
      }
      const {
        values: { email },
      } = formik;
      const payload = {
        email,
        ...values,
      };
      try {
        await resetPassword(payload);
        enqueueSnackbar("Your password has been reset successfully!", {
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "success",
          autoHideDuration: 3000,
        });
        setIsReset(true);
      } catch (e) {
        setIsReset(false);
        setSubmitting(false);
        setStatus(intl.formatMessage({ id: "AUTH.FORGOT.FAIL" }));
      }
    },
  });

  return (
    <>
      {isReset ? (
        <>
          <Redirect to="/auth" />
        </>
      ) : (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              {isRequested
                ? "An OTP is sent to your registered Email ID and Phone number"
                : "Enter your email to reset your password"}
            </div>
          </div>
          <form
            onSubmit={
              isRequested ? resetFormik.handleSubmit : formik.handleSubmit
            }
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {!isRequested ? (
              <>
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    placeholder="You registered Email ID"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>{" "}
              </>
            ) : (
              <>
                {resetFormik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {resetFormik.status}
                    </div>
                  </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="otp"
                    placeholder="Enter OTP"
                    {...resetFormik.getFieldProps("otp")}
                  />
                  {resetFormik.touched.otp && resetFormik.errors.otp ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {resetFormik.errors.otp}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="password"
                    placeholder="Enter your New Password"
                    {...resetFormik.getFieldProps("password")}
                  />
                  {resetFormik.touched.password &&
                  resetFormik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {resetFormik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="confirmPassword"
                    placeholder="Confirm your New Password"
                    {...resetFormik.getFieldProps("confirmPassword")}
                  />
                  {resetFormik.touched.password &&
                  resetFormik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {resetFormik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
