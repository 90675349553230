import React from 'react';
import './SingleBanner.css'
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';

import { data1 } from '../dummy';

const SingleBanner = () => {

  let match = useRouteMatch()
  let history = useHistory()
  let type = match.params.type

  const getClass=(type)=>{
    if(type==="Portrait"){
      return 'por'
    }else{
      return 'rec'
    }
  }
  return (
    <div className="SingleBanner">
      <h1>Banners {type}</h1>
      <div className="SingleBanner-images">
        {
          data1[type].map((ob, i) => (
              <img key={i} className={`SingleBanner-image SingleBanner-${getClass(type)}`} src={ob.url}
              onClick={()=>history.push(`/banners/${type}/${ob.url.slice(-5, -4)}`)}></img>
          ))
        }
      </div>
    </div>
  )
}

export default SingleBanner;