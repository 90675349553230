import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  makeStyles,
  Avatar,
  Button,
  styled,
  Grow,
  Container,
} from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { Close as CloseIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ServiceDetailCard from "./serviceDetailCard";
import { Phone as PhoneIcon, Email as EmailIcon } from "@material-ui/icons";
import { getAppointmentDetail } from "../../appointmentsCrud";
import CubeGridLoader from "../../../../commonComponents/cubeGridLoader";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "541px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  orderStatus: {
    backgroundColor: "#F2F3F7",
  },
  moreOptionsButton: {
    width: "75%",
    height: "43px",
    backgroundColor: "#FFFFFF !important",
    borderColor: "#EAEAEA",
    color: "#000",
  },
}));

const CheckoutButton = styled(Button)({
  width: "75%",
  height: "43px",
  backgroundColor: "#3799FF",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#3799FF",
    color: "#FFF",
  },
});

function statusBgColor(status) {
  switch (status) {
    case "CONFIRMED":
      return "#E1F0FF";
    case "MISSED":
      return "#EBEBEB";
    case "CANCELLED":
      return "#FFCAD1";
    case "COMPLETED":
      return "#D4F0E4";
    default:
      return "#adadad";
  }
}

function AppointmentDetails({ selectedOrder, onClickClose }) {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const {
      order: { uuid },
    } = selectedOrder;
    getOrderDetails(uuid);
  }, [selectedOrder]);

  async function getOrderDetails(id) {
    setShowLoader(true);
    try {
      const { data } = await getAppointmentDetail(id);
      setOrderDetails(data);
    } catch (e) {
      setOrderDetails(null);
    }
    setShowLoader(false);
  }

  function getAppointmentItems(type) {
    if (type === "SALON_DEAL") {
      return orderDetails.items.reduce((acc, item) => {
        const appointments = item.latest_appointments.map((appointment) => {
          return {
            ...appointment,
            service: item.items[0].service,
          };
        });
        acc.push(...appointments);
        return acc;
      }, []);
    }
    if (type === "CUSTOM") {
      return orderDetails.items.map((item) => {
        return { ...item.appointment, ...item };
      });
    }
  }

  return (
    <Box className={`${classes.root}`}>
      {showLoader || !orderDetails ? (
        <Container className="flex-grow-1 d-flex align-items-center">
          <CubeGridLoader />
        </Container>
      ) : (
        <>
          <Grow in={true} direction="left">
            <Box className="flex-grow-1 d-flex flex-column h-100">
              <Box className="d-flex align-items-center px-10 pt-5">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={onClickClose}
                >
                  <CloseIcon className="text-black-70" />
                </IconButton>
                <Typography variant="h6" className="text-center flex-grow-1">
                  Appointment Details
                </Typography>
              </Box>
              <Grid container className={`mt-4 p-5 ${classes.orderStatus}`}>
                <Grid
                  item
                  xs={9}
                  className="d-flex flex-column justify-content-around"
                >
                  <Typography>
                    <span className="font-weight-bold">Order_id:</span>{" "}
                    {orderDetails.basic.display_id}
                  </Typography>
                  <Typography>
                    <span className="font-weight-bold">Order_date:</span>{" "}
                    {moment(orderDetails.basic.checkout_at.date).format(
                      "D MMMM YYYY"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>Status</Typography>
                  <Typography
                    style={{
                      backgroundColor: statusBgColor(orderDetails.basic.state),
                    }}
                    className="p-2 d-inline-block"
                  >
                    {orderDetails.basic.state}
                  </Typography>
                </Grid>
              </Grid>
              <Box className="mt-4 p-5 flex-grow-1 overflow-auto">
                <Box className="d-flex justify-content-between mb-3">
                  <Typography variant="h5" className="font-weight-bold">
                    Service Details
                  </Typography>
                  <Typography variant="h6" className="font-weight-bold">
                    {orderDetails.items.length} items
                  </Typography>
                </Box>
                <Box>
                  {getAppointmentItems(orderDetails.basic.type).map((item) => {
                    return (
                      <Box className="mt-3" key={item.uuid}>
                        <ServiceDetailCard item={item} />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box className="mt-4 p-5">
                {orderDetails.basic.type !== "SALON_DEAL" ? (
                  <>
                    <Typography variant="h5" className="font-weight-bold mb-3">
                      Order Summary{" "}
                    </Typography>{" "}
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <Box className="d-flex justify-content-between mb-2">
                          <Typography>Total Amount</Typography>
                          <Typography>
                            &#x20B9; {orderDetails.basic.price.net}
                          </Typography>
                        </Box>
                        <Box className="d-flex justify-content-between mb-2">
                          <Typography>Amount Paid</Typography>
                          <Typography>
                            &#x20B9; {orderDetails.basic.paid_amount}
                          </Typography>
                        </Box>
                        <Box className="d-flex justify-content-between">
                          <Typography>Amount Due</Typography>
                          <Typography>
                            &#x20B9;{" "}
                            {orderDetails.basic.price.net -
                              orderDetails.basic.paid_amount}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Box>
              <Grid container className={`mt-4 p-5 ${classes.orderStatus}`}>
                <Grid item xs={2}>
                  <Avatar
                    variant="rounded"
                    className={`mr-5 text-capitalize bg-primary h-100 w-50`}
                  >
                    <span>{orderDetails.consumer.basic.name_first[0]}</span>
                    <span>{orderDetails.consumer.basic.name_last[0]}</span>
                  </Avatar>
                </Grid>
                <Grid item xs={10} className="d-flex flex-column mt-2">
                  <Typography variant="h5">{`${orderDetails.consumer.basic.name_first} ${orderDetails.consumer.basic.name_last}`}</Typography>
                  <Box className="d-flex">
                    <Typography variant="body1" className="mr-3">
                      <PhoneIcon className="mr-2" />{" "}
                      <span>{orderDetails.consumer.basic.mobile}</span>
                    </Typography>
                    <Typography variant="body1">
                      <EmailIcon className="mr-2" />{" "}
                      <span>{orderDetails.consumer.basic.email}</span>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mt-3" justify="center">
                <Grid container item xs={6} className="d-flex" justify="center" >
                  <Dropdown className="border border-dark rounded">
                    <Dropdown.Toggle
                      disabled={
                        orderDetails.basic.state.toLowerCase() !== "confirmed"
                      }
                      variant="outline-light"
                      className={classes.moreOptionsButton}
                    >
                      More Options
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Edit Appointment</Dropdown.Item>
                      <Dropdown.Item>Cancel</Dropdown.Item>
                      <Dropdown.Item>No Show</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid>
                <Grid container item xs={6} className="d-flex" justify="center">
                  <CheckoutButton
                    disabled={
                      orderDetails.basic.state.toLowerCase() !== "confirmed"
                    }
                    variant="contained"
                    onClick={() =>
                      history.push("/appointments/checkout", {
                        appointmentDetails: orderDetails,
                      })
                    }
                  >
                    Checkout
                  </CheckoutButton>
                </Grid>
              </Grid>
            </Box>
          </Grow>
        </>
      )}
    </Box>
  );
}

export default AppointmentDetails;
