/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }
    const isVisible = (url) => {
        return checkIsActive(location, url) ? true : false;
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*start custom code */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

            {/* stylists page */}

            {
                isVisible('/e-commerce/customers') &&
                <>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/e-commerce/customers')}`}>
                        <NavLink className="menu-link" to="">
                            <span className="menu-text">List</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/rank')}`}>
                        <NavLink className="menu-link" to="">
                            <span className="menu-text">Ranks</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                </>

            }


            {/* users page */}

            {
                isVisible('/users') &&
                <>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/users')}`}>
                        <NavLink className="menu-link" to="">
                            <span className="menu-text">List</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/summary')}`}>
                        <NavLink className="menu-link" to="">
                            <span className="menu-text">Summary</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                </>
            }

            {/* services page */}

            {
                (isVisible('/details/services') || isVisible('/details/ranks') || isVisible('/details/taxes')) &&
                <>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/details/services')}`}>
                        <NavLink className="menu-link" to="/details/services">
                            <span className="menu-text">Services</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/details/ranks')}`}>
                        <NavLink className="menu-link" to="/details/ranks">
                            <span className="menu-text">Ranks</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/details/taxes')}`}>
                        <NavLink className="menu-link" to="/details/taxes">
                            <span className="menu-text">Taxes</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                </>
            }

            {/* Deals page */}

            {
                isVisible('/deals') &&
                <>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/deals')}`}>
                        <NavLink className="menu-link" to="">
                            <span className="menu-text">Listing</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/analytics')}`}>
                        <NavLink className="menu-link" to="/analytics">
                            <span className="menu-text">Analytics</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                </>
            }

            {/* appointments page */}
            {
                isVisible('/appointments') &&
                <>
                    <h5 className="text-dark font-weight-bold my-2 mr-5 align-self-center">
                        Appointments
                    </h5>
                </>
            }
            {/* DashBoard page */}
            {
                isVisible('/dashboard') &&
                <>
                    <h5 className="text-dark font-weight-bold my-2 mr-5 align-self-center">
                        Dashboard
                    </h5>
                </>
            }

            {/* Sales Page */}

            {
                (isVisible('/sales/sappointments') || isVisible('/sales/sdeals') ||
                    isVisible('/sales/transactions')) &&
                <>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/sales/sappointments')}`}>
                        <NavLink className="menu-link" to="/sales/sappointments">
                            <span className="menu-text">Appointments</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/sales/sdeals')}`}>
                        <NavLink className="menu-link" to="/sales/sdeals">
                            <span className="menu-text">Deals</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/sales/transactions')}`}>
                        <NavLink className="menu-link" to="/sales/transactions">
                            <span className="menu-text">Transactions</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>
                </>
            }

            {/* sms page */}

            {
                (isVisible('/sms/overview') || isVisible('/sms/templates') ||
                    isVisible('/sms/campaigns')) &&
                <>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/sms/overview')}`}>
                        <NavLink className="menu-link" to="/sms/overview">
                            <span className="menu-text">Overview</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/sms/campaigns')}`}>
                        <NavLink className="menu-link" to="/sms/campaigns">
                            <span className="menu-text">Campaigns</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>

                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/sms/templates')}`}>
                        <NavLink className="menu-link" to="/sms/templates">
                            <span className="menu-text">Templates</span>
                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </NavLink>
                    </li>
                </>
            }

        </ul>
        {/*end custom code */}


    </div>;
}
