import { Subject } from "rxjs";

const subject = new Subject();
const subjectToggleCheckout = new Subject();

export const sharedService = {
  sendOrderDetail: (detail) => subject.next({ detail: detail }),
  clearOrder: () => subject.next(),
  getOrderInfo: () => subject.asObservable(),
  showCheckout: (toggleVal = false) => subjectToggleCheckout.next(toggleVal),
  subjectToggleCheckout,
};
