import * as Yup from "yup";

export default Yup.object().shape({
  firstName: Yup.string().required("Please enter first name"),
  lastName: Yup.string().required("Please enter last name"),
  phone: Yup.string()
    .matches(/\+?\d[\d -]{8,12}\d/, "Must be a valid phone number")
    .required("Please enter valid phone number"),
  email: Yup.string()
  .email("Must be a valid email address")
  .required("Please enter valid phone number"),
});