import React from 'react';
import { Link } from 'react-router-dom';
import './formatters.css';


export function categoryFormatter(cell, row) {
  if (row.personnelCategory === "Stylist") {
    return (
      <>
      <span className="categoryFormatter-stylist"></span>
      <span className="">{cell}</span>
      </>
    )
  } else {
    return (
      <>
      <span className="categoryFormatter-non-stylist"></span>
      <span className="">{cell}</span>
      </>
    )
  }
}

export function actionFormatter(cell, row) {
  return (
    <div>
      <Link to={{
        pathname: "/stylistOnBoarding",
        state: { personnel_uuid:row.uuid }
      }}>
        <i className="fas fa-edit mr-2  actionFormatter-action-blue blue"></i>
      </Link>
      <i className="fas fa-trash  actionFormatter-action-red red"></i>
    </div>

  )

}

export function statusFormatter(cell, row) {
  if (cell === true) {
    return <span className="statusFormatter-active">Active</span>
  } else {
    return <span className="statusFormatter-inactive">Inactive</span>
  }
}

export function nameFormatter(cell, row) {
  let image;
  if(row.profilePic){
    image = <img className="rounded-circle mr-2" src={row.profilePic} height="40" width="40"></img>
  }
  else{
    image=<span className="nameFormatter-initials">{cell.slice(0, 1).toUpperCase()}</span>
  }
  return (
    <div className="d-flex align-items-center">
      {image}
      {cell}
    </div>
  )
}
