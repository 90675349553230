/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./Appointment.css";
import { states } from "./Appointment.state";
import { Dropdown, Button } from "react-bootstrap";
import * as appointmentService from "./AppointmentDetail.service";
import { toAbsoluteUrl } from "../../../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import { sharedService } from "./../../../../../app/modules/shared.service";
import "react-toastify/dist/ReactToastify.css";

class AppointmentPanel extends React.Component {
  state = states;
  componentDidMount = () => {
    // this.getAppointmentDetail();
    this.subscription = sharedService.getOrderInfo().subscribe((res) => {
      if (res) {
        this.getAppointmentDetail(
          res.detail.event._def.extendedProps.order.uuid
        );
      }
    });
  };

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  getAppointmentDetail = (id) => {
    let {
      services,
      orderDisplayid,
      orderId,
      orderDate,
      payment_state,
      state,
      consumer,
      totalAmount,
      amountDue,
    } = this.state;
    let ref = this;
    appointmentService.getAppointmentDetail(id).then((res) => {
      if (res.data) {
        services = res.data.items;
        orderDisplayid = res.data.basic.display_id;
        orderId = res.data.uuid;
        orderDate = res.data.basic.checkout_at.date;
        payment_state = res.data.basic.payment_state;
        state = res.data.basic.state;
        consumer = res.data.consumer;
        totalAmount = 0;
        amountDue = 0;
        if (services.length > 0) {
          services.forEach((item) => {
            totalAmount = totalAmount + item.service.price.price_net;
          });
        }
        amountDue = totalAmount;

        ref.setState({
          services,
          orderDisplayid,
          orderId,
          orderDate,
          payment_state,
          state,
          amountDue,
          consumer,
          totalAmount,
        });
      }
    });
  };

  cancelOrder = () => {
    appointmentService.cancelOrder(this.state.orderId).then((res) => {
      if (res) {
        toast.success("Order cancel successfully");
      }
    });
  };

  render() {
    return (
      <div
        id="kt_quick_appointment"
        className="offcanvas offcanvas-right pt-5 "
      >
        <ToastContainer />
        <div className="headerApp">
          Appointment Details
          <div
            className="offcanvas-close mt-n1 pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_appointment_close"
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
        </div>
        <div>
          <div className="orderStatus">
            <div className="form-group row font12 d-flex justify-content-between">
              <div id="orderInfo" className="col-6 col-lg-6">
                <div>
                  <b>Order_id:</b> {this.state.orderDisplayid}
                </div>
                <div style={{ paddingTop: "5px" }}>
                  <b> Order_date:</b>{" "}
                  {moment(this.state.orderDate).format("D MMMM YYYY")}
                </div>
              </div>
              <div id="orderInfoStatus" className="col-3 col-lg-3 mr-4">
                <div>Status</div>
                <div>
                  <Button className="statusBtn">{this.state.state}</Button>
                </div>
              </div>
            </div>
          </div>

          <div className="midHeight">
            <div className="row serviceHeader">
              <div className="col-lg-8 adm-header">Service Details</div>
              <div className="col-lg-4" style={{ textAlign: "right" }}>
                {this.state.services.length} items
              </div>
            </div>

            {this.state.services.length > 0 &&
              this.state.services.map((item, i) => {
                return (
                  <div key={i} className="rightPanelFirsts">
                    <div className="cartDiv">
                      <div className="cartTime">
                        {moment(item.appointment.basic.instant.start).format(
                          "hh:mm A"
                        )}
                      </div>
                      <div className="cartDate">
                        {moment(item.appointment.basic.date).format(
                          "D MMMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="cartDivder ml-1"></div>
                    <div className="rhtPanelContents">
                      <div style={{ padding: "10px" }}>
                        <div className="styleName">{item.service.name}</div>
                        <div className="timing">
                          {item.service.duration_minutes} min
                        </div>
                        <div className="person">
                          with {item.appointment.personnel.basic.name_first}{" "}
                          {item.appointment.personnel.basic.name_last}
                        </div>
                      </div>

                      <div style={{ paddingTop: "10px" }}>
                        <div className="price">
                          ₹ {item.service.price.price_net}
                        </div>
                        <div className="tax">(incl.taxes)</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div
            className="orderSummryDiv"
            style={{ padding: "20px 8px 20px 25px" }}
          >
            <div className="row headingCus pd5 adm-header">Order Summary</div>
            <div className="row">
              <div className="col-8 col-lg-8 row">
                <div className="col-5 col-lg-6">
                  <div className="row pd5 hedingSub">Total Amount</div>
                  <div className="row pd5 hedingSub">Total Paid</div>
                  <div className="row pd5 hedingSub">Total Due</div>
                </div>
                <div className="col-4 col-lg-6">
                  <div
                    className="row pd5 hedingSub"
                    style={{ justifyContent: "center" }}
                  >
                    ₹ {this.state.totalAmount.toLocaleString("en-IN")}
                  </div>
                  <div
                    className="row pd5 hedingSub"
                    style={{ justifyContent: "center" }}
                  >
                    ₹ 0
                  </div>
                  <div
                    className="row pd5 hedingSub"
                    style={{ justifyContent: "center" }}
                  >
                    ₹ {this.state.amountDue.toLocaleString("en-IN")}
                  </div>
                </div>
              </div>
              <div className="col-4 col-lg-4 row">
                <div className="col-2 col-lg-6">Paymode</div>
                <div className="col-2 col-lg-1" style={{ marginLeft: "40px" }}>
                  <b>Cash</b>
                </div>
              </div>
            </div>
          </div>

          <div className="customer-detail">
            <div className="row customerHeading">Customer Details</div>
            <div className="row" style={{ padding: "8px 0px" }}>
              <div className="col-2 col-lg-2 pd0 mr-4">
                <div className="caption">
                  {this.state.consumer &&
                    this.state.consumer.basic.name_first.charAt(0) +
                      " " +
                      this.state.consumer.basic.name_last.charAt(0)}
                </div>
              </div>
              {this.state.consumer ? (
                <div className="col-9 col-lg-8 pd0">
                  <div className="row headingCus">
                    {this.state.consumer.basic.name_first}{" "}
                    {this.state.consumer.basic.name_last}
                  </div>
                  <div className="row">
                    <div className="hedingSub">
                      <img
                        className="icon"
                        src={toAbsoluteUrl("/media/call.svg")}
                        alt="image"
                      />
                      {this.state.consumer.basic.mobile}
                    </div>
                    <div className="hedingSub">
                      <img
                        className="icon"
                        src={toAbsoluteUrl("/media/mail.svg")}
                        alt="image"
                      />
                      {this.state.consumer.basic.email}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="footerApp d-flex justify-content-around">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-more-options"
                className="adm-footer-btn"
              >
                More Options
              </Dropdown.Toggle>
              <Dropdown.Menu className="super-colors">
                <Dropdown.Item>Edit Appointment</Dropdown.Item>
                <Dropdown.Item onClick={() => this.cancelOrder()}>
                  Cancel
                </Dropdown.Item>
                <Dropdown.Item>No Show</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button
              className="adm-footer-btn"
              onClick={() => {
                sharedService.showCheckout(true);
              }}
            >
              Checkout
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AppointmentPanel);
