import * as yup from 'yup';

export const initialFormikValues = {
  serviceName: "",
  serviceType: "",
  standardService: "",
  gender: "",
  duration: "",
  price: "",
  taxes:[],
  staff: [],
  rankVariation:{},
  serviceVariationName:null,
  
}








export const formikFormSchema = yup.object({
  serviceName: yup.string()
    .min(2, 'Must be 2 characters long')
    .required('Please provide a name'),
  serviceType: yup.string()
  .required("Please select a value"),
  standardService: yup.string()
  .required("Please select a value"),
  gender: yup.string()
  .required("Please select a gender"),
  duration: yup.number()
  .required("Please provide a duration")
  .test(
    "multiple of 5",
    "Duration must be in multiples of 5",
    function() {
      const { duration} = this.parent;
      return duration % 5 === 0
    }
  ),
  price: yup.number().when("serviceVariationName", {
    is: value => value !== null,
    then: yup.number(),
    otherwise: yup.number()
    .typeError("That doesn't look like a number")
    .positive("Price can't start with a minus")
    .required(
      "Please provide a price"
  )
}),
taxes:yup.array(),
  staff: yup.array()
  .required("Please select atleast one personnel"),
  rankVariation: yup.object()
});