import React from 'react';
import Chart from "react-apexcharts";

const Graph = () => {
  const chartOptions ={
          
            series: [{
              name: 'Appointments',
              type: 'column',
              data: [5, 3, 5, 15, 25, 20, 10, 15]
            }, {
              name: 'Deals',
              type: 'column',
              data: [2, 6, 1, 4, 10, 7, 2, 7]
            }, {
              name: 'Revenue (thousands)',
              type: 'line',
              data: [3, 4, 4, 9, 15, 12, 8, 9]
            }],
            options: {
              chart: {
                height: 350,
                type: 'line',
                stacked: false
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                width: [1, 1, 4]
              },
              xaxis: {
                categories: ["12/11", "13/11", "14/11", "15/11", "16/11", "17/11", "18/11", "19/11"],
              },
              yaxis: [
                {
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: '#008FFB'
                  },
                  labels: {
                    style: {
                      colors: '#008FFB',
                    }
                  },
                  title: {
                    text: "No. of appointments",
                    style: {
                      color: '#008FFB',
                    }
                  },
                  tooltip: {
                    enabled: true
                  }
                },
                {
                  show:false,
                  seriesName: 'Income',
                  opposite: true,
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: '#00E396'
                  },
                  labels: {
                    style: {
                      colors: '#00E396',
                    }
                  },
                  title: {
                    text: "No. of Deals",
                    style: {
                      color: '#00E396',
                    }
                  },
                },
                {
                  show:false,
                  seriesName: 'Revenue',
                  opposite: true,
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: '#FEB019'
                  },
                  labels: {
                    style: {
                      colors: '#FEB019',
                    },
                  },
                  title: {
                    text: "Revenue (in thousands)",
                    style: {
                      color: '#FEB019',
                    }
                  }
                },
              ],
              tooltip: {
                fixed: {
                  enabled: true,
                  position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                  offsetY: 30,
                  offsetX: 60
                },
              },
              legend: {
                horizontalAlign: 'left',
                offsetX: 40
              }
            },
          
          
          };

  return (
    <Chart
      options={chartOptions.options}
      series={chartOptions.series}
      type="line"
    />
  )
}

export default Graph;