import React, { useState } from 'react';
import './TableTopBar.css';

import { Modal, Button } from 'react-bootstrap';
import customerFormSchema from './CustomerSchema';
import { useFormik } from "formik";


const TableTopBar = ({createCustomer}) => {
  const [modalShow, setModalShow] = useState(false);

  const customerFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    validationSchema: customerFormSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      createCustomer(values);
      setModalShow(false)
    },
  });

  

  function onCustomerDataInput(value, field) {
    customerFormik.handleBlur(value);
    customerFormik.handleChange(value);
  }

  return (
    <div className="TableTopBar mb-5">
      <p className="TableTopBar-heading">All Clients</p>
      <div className="TableTopBar-add" onClick={() => setModalShow(true)}>
        Add new client
      </div>

      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ justifyContent: "space-between" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            Create new customer
              </Modal.Title>
          <div className="customerModelCross">
            <i
              onClick={() => setModalShow(false)}
              className="quick-search-close ki ki-close icon-sm text-muted"
            ></i>
          </div>
        </Modal.Header>
        <Modal.Body className="New-appointment-modal-body">
          <div className="col-lg-12" style={{ padding: "25px" }}>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label className="form-label">First Name</label>
                  <input
                    placeholder="Enter First Name"
                    onChange={onCustomerDataInput}
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    type="text"
                    value={customerFormik.values.firstName}
                  />
                </div>
                <div>
                  {customerFormik.touched.firstName &&
                    customerFormik.errors.firstName ? (
                      <div className="fv-plugins-message-container">
                        <p className="fv-help-block">
                          {customerFormik.errors.firstName}
                        </p>
                      </div>
                    ) : null}
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label className="form-label">Last Name</label>
                  <input
                    placeholder="Enter Last name"
                    onChange={onCustomerDataInput}
                    id="lastname"
                    name="lastName"
                    type="text"
                    className="form-control"
                    value={customerFormik.values.lastName}
                  />
                </div>
                <div>
                  {customerFormik.touched.lastName &&
                    customerFormik.errors.lastName ? (
                      <div className="fv-plugins-message-container">
                        <p className="fv-help-block">
                          {customerFormik.errors.lastName}
                        </p>
                      </div>
                    ) : null}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <div>
                  <label className="form-label">Phone</label>
                  <input
                    placeholder="Enter Phone number"
                    onChange={onCustomerDataInput}
                    id="phone"
                    name="phone"
                    className="form-control"
                    type="tel"
                    value={customerFormik.values.phone}
                  />
                </div>
                <div>
                  {customerFormik.touched.phone && customerFormik.errors.phone ? (
                    <div className="fv-plugins-message-container">
                      <p className="fv-help-block">{customerFormik.errors.phone}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <div>
                  <label className="form-label">Email</label>
                  <input
                    placeholder="Enter email ID"
                    onChange={onCustomerDataInput}
                    id="email"
                    name="email"
                    className="form-control"
                    type="email"
                    value={customerFormik.values.email}
                  />
                </div>
                <div>
                  {customerFormik.touched.email && customerFormik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <p className="fv-help-block">{customerFormik.errors.email}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalShow(false)}
            
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="saveBtnM"
            onClick={customerFormik.handleSubmit}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default TableTopBar;