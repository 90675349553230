import React, { useEffect } from "react";
import { useFormik } from "formik";
import SelectInput from "../../../commonComponents/selectInput";

function Step1Form({
  isCurrent,
  initialValues,
  formSchema,
  possibleValues,
  setFormValidity,
  getFormValues,
}) {
  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    setFormValidity(formik.isValid);
  }, [formik]);

  useEffect(() => {
    getFormValues(formik.values);
  }, [formik.values]);

  function onUserInput(value) {
    formik.handleBlur(value);
    formik.handleChange(value);
  }

  function getCities(stateUUID) {
    return possibleValues.locStates
      ?.find((i) => i.uuid === stateUUID)
      ?.loc_cities.map((i) => {
        return { name: i.name, value: i.uuid };
      });
  }

  function getMicroMarkets(stateUUID, cityUUID) {
    if (stateUUID && cityUUID) {
      return possibleValues.locStates
        ?.find((i) => i.uuid === stateUUID)
        ?.loc_cities?.find((i) => i.uuid === cityUUID)
        .loc_micro_markets?.map((item) => {
          return { value: item.uuid, name: item.name };
        });
    }
    return [];
  }

  return (
    <div
      className="pb-5"
      data-wizard-type="step-content"
      data-wizard-state={isCurrent ? "current" : ""}
    >
      <div className="h4 mb-10 font-weight-bold text-dark">
        Enter Business Details
      </div>

      <div className="form-group">
        <label>Brand Name</label>
        <input
          type="text"
          className="form-control form-control-lg"
          name="name"
          onChange={onUserInput}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              {formik.errors.name} E.g. ABC Beauty Salon
            </div>
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label>Business type</label>
            <SelectInput
              value={formik.values.businessType}
              placeHolder="Select Business type"
              data={possibleValues.businessTypes}
              name="businessType"
              onChange={onUserInput}
            />
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group">
            <label>Gender Served</label>
            <div className="col-9 col-form-label pl-0">
              <div className="radio-inline">
                {possibleValues.servedGenders?.map((item) => {
                  return (
                    <label className="radio radio-outline" key={item.name}>
                      <input
                        type="radio"
                        name="servedGender"
                        value={item.value}
                        onChange={onUserInput}
                        defaultChecked={
                          formik.values.servedGender === item.value
                        }
                      />
                      <span></span>
                      {item.name}
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-10 font-weight-bold text-dark">
        Enter Manager Details
      </div>

      <div className="form-group">
        <label>Manager Name</label>
        <input
          type="text"
          className="form-control form-control-lg"
          name="managerName"
          onChange={onUserInput}
          value={formik.values.managerName}
        />
        {formik.touched.managerName && formik.errors.managerName ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.managerName}</div>
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label>Phone</label>
            <input
              type="tel"
              className="form-control form-control-lg"
              name="managerPhone"
              onChange={onUserInput}
              value={formik.values.managerPhone}
            />
            {formik.touched.managerPhone && formik.errors.managerPhone ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.managerPhone}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control form-control-lg"
              name="managerEmail"
              onChange={onUserInput}
              value={formik.values.managerEmail}
            />
            {formik.touched.managerEmail && formik.errors.managerEmail ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.managerEmail}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="mb-10 font-weight-bold text-dark">
        Enter Address Details
      </div>

      <div className="form-group">
        <label>Address</label>
        <input
          type="text"
          className="form-control form-control-lg"
          name="address"
          onChange={onUserInput}
          value={formik.values.address}
        />
        {formik.touched.address && formik.errors.address ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.address}</div>
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="form-group">
            <label>State</label>
            <SelectInput
              value={formik.values.locState}
              placeHolder="Select State"
              name="locState"
              data={possibleValues.locStates?.map((i) => {
                return { name: i.name, value: i.uuid };
              })}
              onChange={onUserInput}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label>City</label>
            <SelectInput
              value={formik.values.locCity}
              placeHolder="Select City"
              name="locCity"
              data={getCities(formik.values.locState)}
              onChange={({ target: { value, name } }) => {
                onUserInput({ target: { name, value } });
              }}
              disabled={!formik.values.locState}
            />
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group">
            <label>Micromarket</label>
            <SelectInput
              value={formik.values.locMicroMarket}
              placeHolder="Select Micromarket"
              name="locMicroMarket"
              data={getMicroMarkets(
                formik.values.locState,
                formik.values.locCity
              )}
              onChange={onUserInput}
              disabled={!formik.values.locCity}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1Form;
