import React, { useState } from 'react';
import './styles.css'

import NewServiceForm from './form/NewServiceForm';
import {connect} from 'react-redux';

import { useFormik } from "formik";
import { initialFormikValues, formikFormSchema } from './form/formikSchema';

import { Navbar } from 'react-bootstrap'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getPayLoad } from './helperFunctions/helpers';
import { addNewService,getAllInputData } from './index.service';




const NewService = (props) => {
    const [snackBar, setSnackBar] = useState(false);
    const [features, setFeatures] = useState([]);

    const {currentSalon:{salon:{uuid:currentSalonuuid}}} = props.auth;
    console.log(currentSalonuuid);

    const formik = useFormik({
        initialValues: initialFormikValues,
        validationSchema: formikFormSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let payLoad = getPayLoad(values, features);
            alert(JSON.stringify(payLoad, null, 2))
            try{
                const res = await addNewService(payLoad,currentSalonuuid);
                console.log(res);
                setSnackBar(true);
            }catch(err){
                console.log(err);
            }
        },
    })

    const handleRanks = (e) => {
        const ranks = {...formik.values.rankVariation};
        const isRankPresent = ranks.hasOwnProperty(e.target.value);
        console.log(isRankPresent);
        if (isRankPresent) {
            delete ranks[e.target.value];
        } else {
            ranks[e.target.value] = {
                personnel_rank_uuid: e.target.value,
                price_base: 0,
                name: null
            }
        }
        // console.log(ranks)
        formik.setFieldValue("rankVariation", {...ranks})
        console.log(formik.values.rankVariation)
    }

    const handleTaxes = (val) => {
        let newTaxes = [];
        if (val !== null) {
            newTaxes = val.map(tax => tax.value)
        }
        formik.values.taxes = newTaxes;

        console.log(formik.values.taxes);
    }

    const handleFeatureChange = (e, id, type) => {
        const i = features.findIndex(f => f.id === id)
        const feature = { ...features[i] };

        if (type === "name") {
            feature.name = e.target.value;
        } else {
            feature.price_base = e.target.value;
        }

        const newFeatures = [...features];
        newFeatures[i] = feature;
        setFeatures(newFeatures);

    }

    const handleCloseForm = () =>{
        let ranks = formik.values.rankVariation;
        for(let rank in ranks){
            delete ranks[rank];
        }
        formik.setFieldValue("rankVariation", ranks);
        props.history.replace('./details/services');
        
    }
    return (
        <div className="NewServicePage" id="new-service-page">
            <Navbar bg="light" expand="lg" id="new-service-header" fixed="top">
                <Navbar.Text className="title">
                    New Service
                        </Navbar.Text>
                <span onClick={handleCloseForm} className="customerModelCross">
                    <i className="quick-search-close ki ki-close icon-sm text-muted" ></i>
                </span>
            </Navbar>
            <NewServiceForm
                values={formik.values}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                handleRanks={handleRanks}
                handleTaxes={handleTaxes}
                handleSubmit={formik.handleSubmit}
                touched={formik.touched}
                errors={formik.errors}
                features={features}
                setFeatures={setFeatures}
                handleFeatureChange={handleFeatureChange}
                setFieldValue={formik.setFieldValue}
                validate={formik.validateForm}
                handleCloseForm ={handleCloseForm}
                getAllInputData={()=>getAllInputData(currentSalonuuid)}
                
            />

            <Snackbar open={snackBar} autoHideDuration={4000} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }} onClose={() => setSnackBar(false)}>
                <Alert severity="success">
                    success
                </Alert>
            </Snackbar>

        </div>
    )

}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });

export default connect(mapStateToProps)(NewService);