import React from 'react';

const Step2 = (props) => {
  const { currentWizardState, formik2,handleCategoryChange,personnelCategories,ranks,days,handleWorkingDays } = props;
  return (
    <form>
      {/*begin: Wizard Step 2*/}
      <div
        className="pb-5"
        data-wizard-type="step-content"
        data-wizard-state={
          currentWizardState === 2
            ? "current"
            : "notCurrent"
        }
      >
        <h4 className="mb-10 font-weight-bold text-dark">
          Rank Details
        </h4>
        <div className="row">
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>Select Category</label>
              <select className="form-control" name="category" id="category"
                value={formik2.values.category}
                onChange={handleCategoryChange}>
                <option value="" disabled>Select</option>
                {
                  personnelCategories.map(category => (
                    <option key={category.value} value={category.value}>{category.label}</option>
                  ))
                }
              </select>
              {formik2.touched.category && formik2.errors.category ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik2.errors.category}</div>
                </div>
              ) : null}
              {/* <span className="form-text text-muted">joining date</span> */}
            </div>
            {/*end::Input*/}
          </div>
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>Select Rank</label>
              <select className="form-control" name="rank" id="rank"
                value={formik2.values.rank}
                onChange={formik2.handleChange}>
                <option value="" disabled>Select</option>
                {
                  ranks.map(rank => (
                    <option
                      key={rank.uuid} value={rank.uuid}>
                      {rank.basic.name}
                    </option>
                  ))
                }

              </select>
              {formik2.touched.rank && formik2.errors.rank ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik2.errors.rank}</div>
                </div>
              ) : null}

              {/* <span className="form-text text-muted">(optional)</span> */}
            </div>
            {/*end::Input*/}
          </div>
        </div>
        <h4 className="mb-10 font-weight-bold text-dark">
          Shift Details
        </h4>
        <div className="row">
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>Start Time</label>
              <input
                type="time"
                className="form-control form-control-solid form-control-lg"
                name="shiftstarttime"
                id="shiftstarttime"
                value={formik2.values.shiftstarttime}
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
              />
              {formik2.touched.shiftstarttime && formik2.errors.shiftstarttime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik2.errors.shiftstarttime}</div>
                </div>
              ) : null}
              <span className="form-text text-muted">
                {`Salon opening time - ${formik2.values.saloonstarttime}`}
              </span>
            </div>
            {/*end::Input*/}
          </div>
          <div className="col-xl-6">
            {/*begin::Input*/}
            <div className="form-group">
              <label>End Time</label>
              <input
                type="time"
                className="form-control form-control-solid form-control-lg"
                name="shiftendtime"
                id="shiftendtime"
                value={formik2.values.shiftendtime}
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
              />
              {formik2.touched.shiftendtime && formik2.errors.shiftendtime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik2.errors.shiftendtime}</div>
                </div>
              ) : null}
              <span className="form-text text-muted">
                {`Salon closing time - ${formik2.values.saloonendtime}`}
              </span>
            </div>
            {/*end::Input*/}
          </div>
        </div>
        <h4 className="mb-10 font-weight-bold text-dark">
          Working Days
        </h4>
        {days.map((day) => (
          <div
            key={day.value}
            className="form-check form-check-inline"
          >
            <input
              name="workingdays"
              className="form-check-input"
              type="checkbox"
              checked={day.checked}
              disabled={day.disabled}
              value={day.value}
              onChange={(e) => { handleWorkingDays(e) }}
            />
            <label
              className="form-check-label ml-3 mr-5"
              htmlFor="inlineCheckbox1"
            >
              {day.label}
            </label>
          </div>

        ))}
        {formik2.touched.workingdays && formik2.errors.workingdays ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik2.errors.workingdays}</div>
          </div>
        ) : null}
      </div>
      {/*end: Wizard Step 2*/}
    </form>

  )
}

export default Step2;
