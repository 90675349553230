import axios from 'axios';

export function getAllInputData(salonuuid){
  return axios.get('salons/' + salonuuid + '/taxes');
}

export function createNewTax(payLoad,salonuuid){
  return axios.put('salons/' + salonuuid + '/taxes',payLoad);
}

export function updateTax(payLoad,salonuuid){
  return axios.post('salons/' + salonuuid + '/taxes',payLoad);
}