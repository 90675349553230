import React, { useEffect } from "react";
import { useFormik } from "formik";
import SelectInput from "../../../commonComponents/selectInput";

function Step3Form({
  isCurrent,
  possibleValues,
  initialValues,
  formSchema,
  setFormValidity,
  getFormValues,
}) {
  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    setFormValidity(formik.isValid);
  }, [formik]);

  useEffect(() => {
    getFormValues(formik.values);
    console.log(formik.values);
  }, [formik.isValid]);

  function onUserInput(value) {
    formik.handleBlur(value);
    formik.handleChange(value);
  }

  return (
    <div
      className="pb-5"
      data-wizard-type="step-content"
      data-wizard-state={isCurrent ? "current" : ""}
    >
      <div className="h4 mb-10 font-weight-bold text-dark">
        Enter Tax Details
      </div>
      <div className="form-group">
        <label>Registered Business Name</label>
        <input
          type="text"
          className="form-control form-control-lg"
          name="registeredBusinessName"
          value={formik.values.registeredBusinessName}
          onChange={onUserInput}
        />
        {formik.touched.registeredBusinessName &&
        formik.errors.registeredBusinessName ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              {formik.errors.registeredBusinessName}
            </div>
          </div>
        ) : null}
      </div>

      <div className="form-group">
        <label>Address</label>
        <input
          type="text"
          className="form-control form-control-lg"
          name="registeredBusinessAddress"
          value={formik.values.registeredBusinessAddress}
          onChange={onUserInput}
        />
        {formik.touched.registeredBusinessAddress &&
        formik.errors.registeredBusinessAddress ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              {formik.errors.registeredBusinessAddress}
            </div>
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label>State</label>
            <SelectInput
              value={formik.values.registeredBusinessLocState}
              placeHolder="Select State"
              name="registeredBusinessLocState"
              data={possibleValues.locStates?.map((i) => {
                return { name: i.name, value: i.uuid };
              })}
              onChange={onUserInput}
            />
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group">
            <label>City</label>
            <SelectInput
              value={formik.values.registeredBusinessLocCity?.name || ""}
              placeHolder="Select City"
              name="registeredBusinessLocCity"
              data={possibleValues.locStates
                ?.find(
                  (i) =>
                    i.uuid === formik.values.registeredBusinessLocState.uuid
                )
                ?.loc_cities.map((i) => {
                  return { name: i.name, value: i.uuid };
                })}
              disabled={!formik.values.registeredBusinessLocState}
              onChange={onUserInput}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label>PAN</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="pan"
              value={formik.values.pan}
              onChange={onUserInput}
            />
            {formik.touched.pan && formik.errors.pan ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.pan}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group">
            <label>GST</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="gst"
              value={formik.values.gst}
              onChange={onUserInput}
            />
            {formik.touched.gst && formik.errors.gst ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.gst}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step3Form;
