import React from 'react';

import {Row,Col} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import Graph from './Graph';


const RecentSales = () =>{
  return(
    <Paper className="RecentSales">
      <div className="pt-3 pb-5 px-5">
      <div className="text-right">
        <i className="fas fa-ellipsis-h"></i>
      </div>
      
      <h5>Recent Sales</h5>
      <p className="text-muted">Last 30 days</p>
      <h4>Rs 67,250</h4>

      <Row>
        <Col>
        <p className="text-muted m-0">Appointments</p>
        <h6>105</h6>
        </Col>
        <Col>
        <p className="text-muted m-0">Deals</p>
        <h6>45</h6>
        </Col>
      </Row>
      <Row>
        <Col>
        <p className="text-muted m-0">Appointments value</p>
        <h6>Rs 48,550</h6>
        </Col>
        <Col>
        <p className="text-muted m-0">Deals value</p>
        <h6>Rs 19,700</h6>
        </Col>
      </Row>
      </div>

      

      <div className="RecentSales-graph">
        <Graph/>
      </div>

    </Paper>
  )
}

export default RecentSales;