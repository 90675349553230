import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './RankListing.css';
import BootstrapTable from 'react-bootstrap-table-next';

import {connect} from 'react-redux';

import TableTopBar from './Components/TableTopBar/TableTopBar';
import EditRankModal from './Components/EditRankModal/EditRankModal';

import Paper from '@material-ui/core/Paper';

import { getAllInputData, createNewRank, updateRank } from './crud.js';
import { getTableRowsData, getRankCategories } from './helpers/helpers';
import { categoryFormatter, actionFormatter } from './helpers/formatters';



const Ranks = (props) => {
  const {currentSalon:{salon:{uuid:currentSalonuuid}}} = props.auth

  const [loading, setLoading] = useState(true);
  const [TableRowData, setTableRowData] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState([]);

  const [editShow,setEditShow] = useState(false);
  const [selecteduuid,setSelecteduuid] = useState("");

  async function getData() {
    try {
      setLoading(true);
      const response = await getAllInputData(currentSalonuuid);
      const ranks = getTableRowsData(response.data.data);
      const categories = getRankCategories(response.data.data_for_input);
      setTableRowData(ranks);
      setCategoryTypes(categories);
      setLoading(false);
      console.log(ranks);

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, [currentSalonuuid]);

  

  const handleCreateNewRank = async (payLoad) => {
    try{
      let response = await createNewRank(payLoad,currentSalonuuid);
      getData();
      console.log(response);
    }catch(err){
      console.log(err);
    }
    console.log("hiiii");
    console.log(payLoad);

  }

  const handleEditOpen=(rankuuid)=>{
    console.log(rankuuid)
    setSelecteduuid(rankuuid);
    setEditShow(true);
  }

  const handleEditSubmit= async (payLoad)=>{
    try{
      let response = await updateRank(payLoad,currentSalonuuid);
      getData();
      console.log(response);
    }catch(err){
      console.log(err);
    }
    console.log("edit");
    console.log(payLoad);
  }

  const columns = [{
    dataField: 'rank',
    text: 'Rank Name',
    headerClasses: () => "RanksListing-width-50"
  }, {
    dataField: 'category',
    text: 'Category',
    sort: true,
    formatter: categoryFormatter
  },
  {
    dataField: 'actions',
    text: 'Actions',
    headerClasses: () => "RanksListing-width-20",
    formatter: actionFormatter,
    formatExtraData: {
      openEditModal: handleEditOpen,
    }
  }];


  if (loading) return <h1>Loading</h1>
  return (
    <div className="RanksListing">
      <Paper className="RanksListing-top-container">
        <TableTopBar categories={categoryTypes} handleNewSubmit={handleCreateNewRank} />

        <div className="RanksListing-table-container">
          <BootstrapTable
            bordered={false}
            bootstrap4
            classes="RanksListing-table"
            rowClasses="RanksListing-rows"
            keyField='uuid'
            data={TableRowData}
            columns={columns} />
        </div>
      </Paper>

      <EditRankModal editShow={editShow} setEditShow={setEditShow} handleEditSubmit={handleEditSubmit} selecteduuid={selecteduuid} allRanks = {TableRowData} setSelecteduuid={setSelecteduuid}/>
      
    </div>

  )
}

const mapStateToProps =(state)=>({
  auth:state.auth
})

export default connect(mapStateToProps)(Ranks);