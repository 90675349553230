import axios from "axios";

// don't use this one for POST or PUT requests
const salonId = "839bec35-8989-459d-9733-b463c8cc4dba";

export function getAppointmentList(date) {
  // const salonId = JSON.parse(localStorage.getItem('loginInfo')).salons[0].salon.uuid;
  return axios.get("salons/" + salonId + "/appointments?date=" + date);
}

export function getServiceList() {
  // const salonId = JSON.parse(localStorage.getItem('loginInfo')).salons[0].salon.uuid;
  return axios.get(
    "salons/" + salonId + "/appointments/create/data?date=2020-06-08"
  );
}

export function checkoutRequiredData({ salonUUID }) {
  return axios.get(
    `salons/${salonId}/appointments/create/data`
  );
}

export function createIMDtoken() {
  return axios.post("orders");
}

export function addService({ orderId, payload }) {
  return axios.put(`orders/${orderId}/appointments/add`, payload);
}

export function removeService({ orderId, payload }) {
  return axios.post(`orders/${orderId}/appointments/remove`, payload);
}

export function availablePaymentOptions() {
  return axios.get("/payments/options/specialized");
}

export function orderDetails({ orderId }) {
  return axios.get(`/orders/${orderId}`);
}

export function getIMDtokenForPayment({ orderId }) {
  return axios.post(`orders/${orderId}/transactions/tokens`);
}

export function enterPaymentInfo({ payload }) {
  return axios.put("payments/options/simple-ref/create/order", payload);
}

export function completeOrder({ appointmentId }) {
  return axios.post(`/appointments/${appointmentId}/complete`);
}

export function generateOrder(payload){
  return axios.put("/orders/custom",payload)
}