import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SalonChange: "SalonChange",
  IsAuthCheckDone: "IsAuthCheckDone",
  IsAuthorized: "IsAuthorized",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  currentSalon: undefined,
  isAuthCheckDone: false,
  isAuthorized: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: "gobony-auth",
    whitelist: ["user", "authToken", "currentSalon"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.SalonChange: {
        const { currentSalon } = action.payload;

        return { ...state, currentSalon };
      }

      case actionTypes.Login: {
        const { authToken, user, currentSalon } = action.payload;

        return { authToken, user, currentSalon };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.IsAuthCheckDone: {
        const { status } = action.payload;
        return { ...state, isAuthCheckDone: status };
      }

      case actionTypes.IsAuthorized: {
        const { status } = action.payload;
        return { ...state, isAuthorized: status };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  salonChange: (currentSalon) => ({
    type: actionTypes.SalonChange,
    payload: { currentSalon },
  }),
  loginInfo: (authToken, user, currentSalon) => ({
    type: actionTypes.Login,
    payload: { authToken, user, currentSalon },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setIsAuthCheckDone: (status) => ({
    type: actionTypes.IsAuthCheckDone,
    payload: { status },
  }),
  setIsAuthorized: (status) => ({
    type: actionTypes.IsAuthorized,
    payload: { status },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });
}
