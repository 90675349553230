import axios from 'axios';


// reading from Service add addforinput
export function getAllInputData(salonuuid){
  return axios.get('salons/' + salonuuid + '/personnels');
}


// export function createNewRank(payLoad,saloonuuid){
//   return axios.put('salons/' + salonId + '/personnels/ranks',payLoad);
// }

// export function updateRank(payLoad,saloonuuid){
//   return axios.post('salons/' + salonId + '/personnels/ranks',payLoad);
// }