import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import Appointments from "./pages/Appointments";
import Services from "./pages/Services";
import NewService from "./pages/NewService";
import Users from "./pages/Users";
import UserProfile from "./pages/UserProfile";
import StylistOnBoarding from "./pages/StylistOnBoarding";
import StylistListing from "./pages/StylistListing";
import RanksListing from "./pages/RanksListing";
import TaxesListing from "./pages/TaxesListing";
import FaqsPage from "./pages/Faqs";
import NewDealPage from "./pages/NewDeal";
import DealsListingPage from "./pages/DealsListing";
import CreateAppointments from "./modules/Appointments/createAppointment";
// import Checkout from "./modules/Appointments/checkout";
import Checkout from "./modules/appointment/checkout2";
import SalesListing from "./pages/SalesListing";
import SalesDealsPage from "./pages/SalesDeals";
import SalesTransactions from "./pages/SalesTransactions";
import BannersPage from './pages/Banners';
import SingleBannerPage from './pages/SingleBanner';
import BannerPreviewPage from './pages/BannerPreview';
import Templates from "./pages/SMS-Templates";
import Campaigns from "./pages/SMS-Campaigns";
import SalonOnboarding from "./pages/SalonOnboarding";
import OnBoarding from "./modules/OnBoarding";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* <Redirect exact from={/^\/auth.+/} to="/appointments" /> */}
        <Redirect exact from="/" to="/appointments" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute
          exact
          path="/appointments/create-appointments"
          component={CreateAppointments}
        />
        <ContentRoute
          exact
          path="/appointments/checkout"
          component={Checkout}
        />
        <ContentRoute
          exact
          path="/appointments/create-sale"
          component={Checkout}
        />
        <ContentRoute exact path="/appointments" component={Appointments} />
        <ContentRoute
          exact
          path="/details/services"
          render={(routeProps) => <Services {...routeProps} />}
        />
        <ContentRoute
          path="/new-service"
          render={(routeProps) => <NewService {...routeProps} />}
        />
        <ContentRoute exact path="/users" component={Users} />
        <ContentRoute exact path="/singleuser/:userId" component={UserProfile} />
        <ContentRoute path="/stylistOnBoarding" component={StylistOnBoarding} />
        <ContentRoute path="/salon-onboarding/forms" component={OnBoarding} />
        <ContentRoute path="/salon-onboarding" component={SalonOnboarding} />
        <ContentRoute path="/stylists" component={StylistListing} />
        <ContentRoute exact path="/details/ranks" component={RanksListing} />
        <ContentRoute exact path="/details/taxes" component={TaxesListing} />
        <ContentRoute path="/support" component={FaqsPage} />
        <ContentRoute path="/newDeal" component={NewDealPage} />
        <ContentRoute path="/deals" component={DealsListingPage} />
        <ContentRoute path="/sales/sappointments" component={SalesListing} />
        <ContentRoute path="/sales/sdeals" component={SalesDealsPage} />
        <ContentRoute path="/sales/transactions" component={SalesTransactions} />
        <ContentRoute path="/sms/templates" component={Templates} />
        <ContentRoute path="/sms/campaigns" component={Campaigns} />
        
        <ContentRoute exact path="/banners" component={BannersPage} />
        <ContentRoute exact path="/banners/:type" component={SingleBannerPage} />
        <ContentRoute exact path="/banners/:type/:img" component={BannerPreviewPage} />

        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
      </Switch>
    </Suspense>
  );
}
