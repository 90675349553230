import React, { useState,useEffect } from 'react';

import { Modal, Button, Form, InputGroup } from 'react-bootstrap';



const EditRankModal = ({editShow,setEditShow, handleEditSubmit, selecteduuid,allTaxes,setSelecteduuid }) => {
  const [taxuuid,setTaxuuid] = useState(selecteduuid);
  const [newName,setNewName] = useState("");
  const [newValue,setNewValue] = useState("");

  useEffect(()=>{
    setTaxuuid(selecteduuid);
  },[selecteduuid])

  const handleClose = () =>{
    setTaxuuid("");
    setNewName("");
    setNewValue("");
    setSelecteduuid("")
    setEditShow(false);
  } 

  const handleEditTax = (e) =>{
    e.preventDefault();
    const payLoad = {
      items:[
        {
          uuid:taxuuid,
          name:newName,
          value:newValue
        }
      ]
    }
    handleEditSubmit(payLoad);
    setTaxuuid("");
    setNewName("");
    setNewValue("");
    setSelecteduuid("");
    setEditShow(false);
  }

  const options = allTaxes.map(tax=>(
    <option key={tax.uuid} value={tax.uuid}>{tax.tax}</option>
  ))


  return (

      <Modal backdrop="static" centered show={editShow} onHide={handleClose}>
        <Form onSubmit={handleEditTax}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Tax</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group>
              <Form.Label>Old Tax</Form.Label>
              <Form.Control as="select" value={taxuuid} required onChange={(e)=>setTaxuuid(e.target.value)}>
                <option value="" disabled>Select</option>
                {options}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>New Tax Name</Form.Label>
              <Form.Control type="text" value={newName} required onChange={(e)=>setNewName(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>New Tax Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control min="1" max="100" type="number" value={newValue} required onChange={(e) => setNewValue(e.target.value)}>
                </Form.Control>
              </InputGroup>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
          </Button>
            <Button className="TableTopBar-submit" type="submit" variant="primary">
              Save
          </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    
  )
}

export default EditRankModal;