import React from 'react';
import SingleCategoryItem from '../SingleCategoryItem/SingleCategoryItem'

const CategoryList = ({categoryData,handleModal,openAll}) =>{
  return(
    categoryData.map(singleCategoryData=>
      <SingleCategoryItem handleModal={handleModal} key={singleCategoryData.uuid} singleCategoryData={singleCategoryData} openAll={openAll}/>
    )
  )
}

export default CategoryList;