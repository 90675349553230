import React, { useState, useEffect } from "react";
import {
  InputBase,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Avatar,
  Box,
  makeStyles,
  colors,
  Container,
  IconButton,
  Modal,
  Toolbar,
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import {
  Search as SearchIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { saveConsumer } from "../appointmentsCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";
import { useFormik } from "formik";
import customerFormSchema from "./createCustomerSchema";
import CubeGridLoader from "../../../commonComponents/cubeGridLoader";

const { blue, orange } = colors;

const useStyles = makeStyles((theme) => ({
  saveCta: {
    color: orange[800],
    backgroundColor: orange[50],
    "&:hover": {
      backgroundColor: orange[50],
    },
    padding: "1em",
  },
  saveCtaDisabled: {
    backgroundColor: orange[200],
  },
  customerCard: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
  },
  blueColor: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  appointmentCardDivider: {
    width: "4px",
    borderRadius: "2px",
    backgroundColor: blue[500],
  },
  saveActivityIndicator: {
    color: orange[500],
    marginRight: "5px",
  },
  checkoutBtn: {
    backgroundColor: "rgb(4, 30, 57)",
    borderColor: "rgb(4, 30, 57)",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "rgb(4, 30, 57, 0.7)",
      color: "#FFF",
    },
    padding: "1em",
  },
  checkoutBtnDisabled: {
    backgroundColor: "rgb(4, 30, 57, 0.7)",
    color: "#FFF",
  },
}));

function AddCustomer({
  open,
  handleCancel,
  createCustomer,
  onCustomerDataInput,
  customerData,
  customerFormik,
  creatingCustomer,
}) {
  const classes = makeStyles((theme) => ({
    createBtn: {
      width: "135px",
    },
  }))();

  return creatingCustomer ? (
    <Dialog open={open}>
      <CubeGridLoader />
    </Dialog>
  ) : (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      onBackdropClick={handleCancel}
      onEscapeKeyDown={handleCancel}
      open={open}
    >
      <DialogTitle>Add a new customer</DialogTitle>
      <DialogContent>
        <div className="form-group row">
          <div className="col-lg-6">
            <div>
              <label className="form-label">First Name</label>
              <input
                placeholder="Enter First Name"
                onChange={onCustomerDataInput}
                id="firstName"
                name="firstName"
                className="form-control"
                type="text"
                value={customerData.firstName}
              />
            </div>
            <div>
              {customerFormik.touched.firstName &&
              customerFormik.errors.firstName ? (
                <div className="fv-plugins-message-container">
                  <p className="fv-help-block">
                    {customerFormik.errors.firstName}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <label className="form-label">Last Name</label>
              <input
                placeholder="Enter Last name"
                onChange={onCustomerDataInput}
                id="lastname"
                name="lastName"
                type="text"
                className="form-control"
                value={customerData.lastName}
              />
            </div>
            <div>
              {customerFormik.touched.lastName &&
              customerFormik.errors.lastName ? (
                <div className="fv-plugins-message-container">
                  <p className="fv-help-block">
                    {customerFormik.errors.lastName}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12">
            <div>
              <label className="form-label">Phone</label>
              <input
                placeholder="Enter Phone number"
                onChange={onCustomerDataInput}
                id="phone"
                name="phone"
                className="form-control"
                type="tel"
                value={customerData.phone}
              />
            </div>
            <div>
              {customerFormik.touched.phone && customerFormik.errors.phone ? (
                <div className="fv-plugins-message-container">
                  <p className="fv-help-block">{customerFormik.errors.phone}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12">
            <div>
              <label className="form-label">Email</label>
              <input
                placeholder="Enter email ID"
                onChange={onCustomerDataInput}
                id="email"
                name="email"
                className="form-control"
                type="email"
                value={customerData.email}
              />
            </div>
            <div>
              {customerFormik.touched.email && customerFormik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <p className="fv-help-block">{customerFormik.errors.email}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={handleCancel} variant="contained">
          Cancel
        </Button>
        <Button
          classes={{
            root: classes.createBtn,
          }}
          disableElevation
          onClick={createCustomer}
          disabled={!customerFormik.isValid}
          className="bg-primary text-white"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AppointmentCard({ appointment, onClickOfDelete }) {
  const { service, stylist, time, appointmentDate } = appointment;
  const classes = useStyles();
  return (
    <Card elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={2} className="d-flex flex-column" justify="flex-end">
          <Typography>{time.label}</Typography>
          <Typography variant="body2">
            {moment(appointmentDate).format("MMM Do, YYYY")}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className={classes.appointmentCardDivider}
        />
        <Grid item xs={6}>
          <Typography>{service.name}</Typography>
          <Typography className="text-muted">
            {service.duration} mins
          </Typography>
          <Typography className="text-muted">with {stylist.name}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          className="d-flex flex-column"
          justify="space-between"
          alignItems="flex-end"
        >
          <Box>
            <Typography className="text-right">
              <span className="ff-helvetica-neue">&#8377;</span> {service.price}
            </Typography>
            <Typography variant="body2" className="text-muted">
              (incl.taxes)
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

function CustomerCard({ customer }) {
  const { basic } = customer;
  const classes = useStyles();
  return (
    <Card variant="outlined" className="bg-light cursor-pointer">
      <CardContent className="d-flex">
        <div className="d-flex">
          <Avatar
            variant="rounded"
            className={`mr-5 text-capitalize ${classes.blueColor}`}
          >
            <span>{basic.name_first[0]}</span>
            <span>{basic.name_last[0]}</span>
          </Avatar>
        </div>
        <div className="flex-grow-1 ml-5">
          <div>
            <Typography className="text-capitalize">
              <span>{basic.name_first}</span> <span>{basic.name_last}</span>
            </Typography>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <Typography className="flex-grow-1">
              <PhoneIcon /> {basic.mobile}
            </Typography>
            <Typography className="flex-grow-1">
              <EmailIcon /> {basic.email}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function Cart({
  onEnteringSearch,
  onClickingSearch,
  searchQuery,
  appointments,
  searchedCustomers,
  saveAppointment,
  savingAppointment,
  onCreatingCustomer,
  deleteAppointment,
  selectedCustomer,
  setSelectedCustomer,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [showCreateCutomerForm, setShowCreateCutomerForm] = useState(false);
  const [showCustomerSearch, setShowCustomerSearch] = useState(false);
  const [creatingCustomer, setCreatingCustomer] = useState(false);

  const customerFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    validationSchema: customerFormSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  async function createCustomer() {
    setCreatingCustomer(true);
    const payload = {
      name_first: customerFormik.values.firstName,
      name_last: customerFormik.values.lastName,
      mobile: customerFormik.values.phone,
      email: customerFormik.values.email,
    };
    try {
      const { data } = await saveConsumer({ payload });
      payload.uuid = data.uuid;
      onCreatingCustomer(payload);
      setShowCreateCutomerForm(false);
      setShowCustomerSearch(false);
    } catch (e) {
      setShowCreateCutomerForm(true);
    }
    setCreatingCustomer(false);
  }

  function onCustomerDataInput(value, field) {
    customerFormik.handleBlur(value);
    customerFormik.handleChange(value);
  }

  function disableSaveCta() {
    return !appointments.length || !selectedCustomer;
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="d-flex flex-column flex-grow-1 position-relative">
        <div className="quick-search quick-search-dropdown bg-light">
          <Paper
            elevation={0}
            variant="outlined"
            className="d-flex justify-content-between align-items-center px-5 flex-grow-1 quick-search-form"
          >
            <SearchIcon className="mr-3" />
            <InputBase
              placeholder="Search Customer using Phone Number"
              inputProps={{
                "aria-label": "search customer using phone number",
              }}
              value={searchQuery}
              onChange={(e) => onEnteringSearch(e.target.value)}
              onClick={() => setShowCustomerSearch(true)}
              className="flex-grow-1 py-2"
            />
          </Paper>
        </div>
        <Box className="h-100 d-flex flex-column">
          <Box
            component="div"
            className="overflow-auto p-0 mt-10 px-4 d-flex flex-column flex-grow-1"
          >
            {appointments.length ? (
              <>
                <Typography className="w-100 mb-5 text-center">Cart</Typography>
                <Container
                  className="flex-grow-1 overflow-auto"
                  style={{ height: "100px" }}
                >
                  {appointments.map((item, index) => (
                    <Box className="mt-3">
                      <AppointmentCard
                        appointment={item}
                        key={item.timeStamp}
                        onClickOfDelete={() => deleteAppointment(index)}
                      />
                    </Box>
                  ))}
                </Container>
              </>
            ) : (
              <Box className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                <img
                  stylist={{ width: "68px", height: "84px" }}
                  src={toAbsoluteUrl("/media/shopping-bag.svg")}
                  alt="image"
                />
                <Typography variant="h6" className="mt-2">
                  Add services to the cart
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {selectedCustomer ? (
          <Box component="div" className={`${classes.customerCard} p-0`}>
            <CustomerCard customer={selectedCustomer} />
          </Box>
        ) : null}
      </div>
      <div className="border-1 border-top py-5 d-flex justify-content-around align-items-center position-relative">
        <Button
          className="btn btn-dark p-3 footer-btn"
          disabled={disableSaveCta() || savingAppointment}
          onClick={() => history.push("/appointments/checkout")}
        >
          Express Checkout
        </Button>
        <Button
          className="btn btn-primary p-3 footer-btn"
          variant="contained"
          disableElevation
          disabled={disableSaveCta() || savingAppointment}
          onClick={saveAppointment}
        >
          Save Appointment
        </Button>
      </div>
      <Modal
        open={showCustomerSearch}
        onBackdropClick={() => setShowCustomerSearch(false)}
        className="d-flex justify-content-end"
      >
        <Col sm={5} className="bg-white d-flex flex-column h-100 p-0">
          <Toolbar className="d-flex justify-content-end mb-3">
            <IconButton onClick={() => history.goBack()}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <div className="quick-search quick-search-dropdown bg-light">
            <Paper
              elevation={0}
              variant="outlined"
              className="d-flex justify-content-between align-items-center px-5 flex-grow-1 quick-search-form"
            >
              <SearchIcon className="mr-3" />
              <InputBase
                placeholder="Search Customer using Phone Number"
                inputProps={{
                  "aria-label": "search customer using phone number",
                }}
                value={searchQuery}
                onChange={(e) => onEnteringSearch(e.target.value)}
                onFocus={() => setShowCustomerSearch(true)}
                className="flex-grow-1 py-2"
                autoFocus={true}
              />
            </Paper>
          </div>
          <Box className="d-flex align-items-center justify-content-center">
            <Button
              className="my-2"
              onClick={() => setShowCreateCutomerForm(true)}
            >
              <AddIcon /> <Typography>Add Customer</Typography>
            </Button>
          </Box>
          {searchedCustomers.length
            ? searchedCustomers.map((customer) => (
                <Box
                  className={`p-0 mb-2`}
                  onClick={() => {
                    setShowCustomerSearch(false);
                    setSelectedCustomer(customer);
                  }}
                  key={customer.basic.name_first}
                >
                  <CustomerCard customer={customer} />
                </Box>
              ))
            : null}
        </Col>
      </Modal>
      <AddCustomer
        open={showCreateCutomerForm}
        handleCancel={() => setShowCreateCutomerForm(false)}
        createCustomer={createCustomer}
        onCustomerDataInput={onCustomerDataInput}
        customerData={customerFormik.values}
        customerFormik={customerFormik}
        creatingCustomer={creatingCustomer}
      />
    </div>
  );
}

export default Cart;
