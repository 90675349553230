import React from 'react';
import './SingleAppointment.css';


const SingleAppointment = (props) => {
  const {time,date,service,duration,stylist,price,completed,cancelled} = props.data
  return (
    <div className="SingleAppointment py-3">
      <div className="SingleAppointment-left">

        <div className="SingleAppointment-time">
          <p className="m-0">{time}</p>
          <p className="text-muted m-0">{date}</p>
        </div>

        <div className="SingleAppointment-divider"></div>

        <div>
          <h5>{service}</h5>
          <p className="m-0">{duration} mins</p>
          <p className="m-0">with {stylist}</p>
        </div>

      </div>

      <div className="SingleAppointment-right text-right">
        <h6>Rs {price}</h6>
        {completed &&
          <div className="SingleAppointment-active">
            Completed
        </div>
        }

        {cancelled &&
          <div className="SingleAppointment-unactive">
            Cancelled
        </div>
        }

      </div>
    </div>
  )
}

export default SingleAppointment;