import React from 'react';

const days = [
  { value: "MON", label: "Monday", disabled: false, checked: false },
  { value: "TUE", label: "Tuesday", disabled: false, checked: false },
  { value: "WED", label: "Wednesday", disabled: false, checked: false },
  { value: "THU", label: "Thursday", disabled: false, checked: false },
  { value: "FRI", label: "Friday", disabled: false, checked: false },
  { value: "SAT", label: "Saturday", disabled: false, checked: false },
  { value: "SUN", label: "Sunday", disabled: false, checked: false },
]

export function getDealPayload(values, services, requiredData, salonuuid, imdToken, step) {
  let payLoad;
  
  if (step) {
    payLoad = {}
  } else {
    payLoad = {
      salon_uuid: salonuuid,
      imd_token: imdToken
    }
  }

  let startTime = requiredData.startTime
  let endTime = requiredData.endTime

  if (values.specificTime) {
    startTime = values.startTime
    endTime = values.endTime
  }


  payLoad.basic = {}
  payLoad.basic.name = values.title
  payLoad.basic.description = values.description
  payLoad.basic.tnc = values.terms.split("\n").filter(line => (line !== ""))
  payLoad.basic.served_gender = values.availableFor.value
  payLoad.basic.tags = values.applicableTags.map(tag => tag.value)

  payLoad.services = services.map(service => ({
    service_uuid: service.service,
    variation_uuid: service.variation ? service.variation : null,
    session_count: service.sessions
  }))

  payLoad.pricing = {}
  payLoad.pricing.quantity_total = values.totalDeals
  payLoad.pricing.price_base = values.price
  payLoad.pricing.taxes = values.taxes.map(tax => tax.value)

  payLoad.redeem = {}
  payLoad.redeem.day_timings = {}
  if (values.specificDay) {
    let tempDays = values.specificDays.map(day => day.value)
    for (let day of requiredData.days) {
      payLoad.redeem.day_timings[day.value] = {
        allowed: tempDays.includes(day.value) ? true : false,
        time: {
          start: startTime,
          end: endTime
        }
      }
    }
  } else {
    for (let day of requiredData.days) {
      payLoad.redeem.day_timings[day.value] = {
        allowed: day.allowed,
        time: {
          start: startTime,
          end: endTime
        }
      }
    }
  }

  payLoad.redeem.per_consumer_purchase_limit = values.limitPurchase ? values.limitOfPurchase : 0

  payLoad.redeem.goes_live_at = {
    date: values.publishOn,
    time: null
  }

  payLoad.redeem.expires_at = {
    date: values.expireOn,
    time: null
  }

  payLoad.redeem.post_purchase_validity_hours = values.validity * 24

  return payLoad;



}

export function formatOptionLabel(val) {
  return (
    <div>
      <p>{val.label}</p>
      <div className="d-flex justify-content-between">
        <p>{val.duration} mins</p>
        <p>Rs {val.price}</p>
      </div>

    </div>
  )
}

export function structureDataForForm(data) {
  const newData = {}

  newData.servedGenders = data.basic.served_genders.map(ob => ({
    value: ob.name,
    label: ob.display_name
  }))

  newData.dealTags = data.basic.deal_tags.map(ob => ({
    value: ob.uuid,
    label: ob.display_name
  }))

  newData.services = []
  for (let service of data.services) {
    for (let item of service.items) {
      if (item.variations.length > 0) {
        for (let variation of item.variations) {
          newData.services.push({
            value: item.uuid + variation.uuid,
            service_uuid: item.uuid,
            label: item.name + "-" + variation.name,
            variation_uuid: variation.uuid,
            price: variation.price.price_net,
            duration: item.duration_minutes

          })
        }
      } else {
        newData.services.push({
          value: item.uuid,
          service_uuid: item.uuid,
          label: item.name,
          variation_uuid: null,
          price: item.price.price_net,
          duration: item.duration_minutes
        })
      }
    }
  }

  newData.inheritedTaxes = data.pricing.taxes.inherited.map(tax => ({
    value: tax.uuid,
    label: tax.basic.name + " " + tax.basic.value + "%"
  }))

  newData.remainingTaxes = data.pricing.taxes.remaining.map(tax => ({
    value: tax.uuid,
    label: tax.basic.name + " " + tax.basic.value + "%"
  }))

  newData.operationalDays = data.redeem.salon_operational_days;

  newData.days = days.map(day => ({
    value: day.value,
    label: day.label,
    disabled: !newData.operationalDays[day.value],
    allowed: newData.operationalDays[day.value],
    time: {
      start: data.redeem.salon_operational_time.start,
      end: data.redeem.salon_operational_time.end
    }
  }))

  newData.startTime = data.redeem.salon_operational_time.start
  newData.endTime = data.redeem.salon_operational_time.end





  console.log(newData)
  return newData;
}