import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './TaxesListing.css';

import {connect} from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';

import TableTopBar from './Components/TableTopBar/TableTopBar';
import EditTaxModal from './Components/EditTaxModal/EditTaxModal';

import Paper from '@material-ui/core/Paper';

import { getAllInputData, createNewTax, updateTax } from './crud.js';
import { getTableRowsData } from './helpers/helpers';
import {  actionFormatter, valueFormatter } from './helpers/formatters';



const Taxes = (props) => {
  const {currentSalon:{salon:{uuid:currentSalonuuid}}} = props.auth;

  const [loading, setLoading] = useState(true);
  const [TableRowData, setTableRowData] = useState([]);

  const [editShow,setEditShow] = useState(false);
  const [selecteduuid,setSelecteduuid] = useState("");

  async function getData() {
    try {
      setLoading(true);
      const response = await getAllInputData(currentSalonuuid);
      const taxes = getTableRowsData(response.data.data);
      setTableRowData(taxes);
      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, [currentSalonuuid]);

  

  const handleCreateNewTax = async (payLoad) => {
    try{
      let response = await createNewTax(payLoad,currentSalonuuid);
      getData();
      console.log(response);
    }catch(err){
      console.log(err);
    }
    console.log("hiiii");
    console.log(payLoad);

  }

  const handleEditSubmit= async (payLoad)=>{
    try{
      let response = await updateTax(payLoad,currentSalonuuid);
      getData();
      console.log(response);
    }catch(err){
      console.log(err);
    }
    console.log("edit");
    console.log(payLoad);
  }

  const handleEditOpen=(taxuuid)=>{
    console.log(taxuuid)
    setSelecteduuid(taxuuid);
    setEditShow(true);
  }

  

  const columns = [{
    dataField: 'tax',
    text: 'Tax Name',
    headerClasses: () => "TaxesListing-width-50"
  }, {
    dataField: 'value',
    text: 'Value',
    formatter: valueFormatter,
    sort: true,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    headerClasses: () => "TaxesListing-width-20",
    formatter: actionFormatter,
    formatExtraData: {
      openEditModal: handleEditOpen,
    }
  }];


  if (loading) return <h1>Loading</h1>
  return (
    <div className="TaxesListing">
      <Paper className="TaxesListing-top-container">
        <TableTopBar handleNewSubmit={handleCreateNewTax} />

        <div className="TaxesListing-table-container">
          <BootstrapTable
            bordered={false}
            bootstrap4
            classes="TaxesListing-table"
            rowClasses="TaxesListing-rows"
            keyField='uuid'
            data={TableRowData}
            columns={columns} />
            <p className="text-muted">Note- Something will come here in future</p>
        </div>
        
      </Paper>

      <EditTaxModal editShow={editShow} setEditShow={setEditShow} handleEditSubmit={handleEditSubmit} selecteduuid={selecteduuid} allTaxes = {TableRowData} setSelecteduuid={setSelecteduuid}/>
      
    </div>

  )
}

const mapStateToProps = (state)=>({
  auth:state.auth
})

export default connect(mapStateToProps)(Taxes);