import React from 'react';
import './formatters.css';
import { Avatar } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import { Link } from 'react-router-dom';

export function nameFormatter(cell, row) {
  return (
    <Link to={`singleuser/${row.uuid}`}>
      <div className="d-flex align-items-center U-nameFormatter">
        <Avatar className="U-nameInitials">{row.fname.slice(0, 1)}{row.lname.slice(0,1) }</Avatar>
        <div className="d-flex flex-column ml-3">
          <span style={{ color: "black" }}>{row.fname} {row.lname}</span>
        </div>
      </div>
    </Link>

  );
};

export function typeFormatter(cell, row) {
  if (cell === "Retail") {
    return (
      <>
        <span className="U-typeFormatter-r"></span>
        <span className="">{cell}</span>
      </>
    )
  } else {
    return (
      <>
        <span className="U-typeFormatter-c"></span>
        <span className="">{cell}</span>
      </>
    )
  }
}

export function statusFormatter(cell, row) {
  if(cell){
    return (
      <span className="label label-lg label-light-success gb-label label-inline">
        Installed
      </span>
    );
  }
  
  return (
    <span className="label label-lg label-light-danger gb-label label-inline">
      Not installed
    </span>
  );
  
};

export function actionFormatter(cell, row) {
  return (
    <div>
      <i className="fas fa-edit mr-2  actionFormatter-action-blue blue"></i>
      <i className="fas fa-trash  actionFormatter-action-red red"></i>
    </div>
  )
}

export function emailFormatter(cell, row) {
  return (
    <p style={{overflowWrap:"break-word"}}>{cell}</p>
  )
}


export const sortCaret = (order, column) => {
  if (!order) return (
    <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")}/>
    </span>
  );
  else if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")}/>
      </span>
    );
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")}/>
      </span>
    );
  return null;
};