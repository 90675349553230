import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import './TableTopBar.css';


const TableTopBar = () => {

  return (
    <div className="TableTopBar">
      <p className="TableTopBar-heading">Stylists</p>
      <Link to="/stylistOnBoarding" className="Stylists-TableTopBar-add">
        Add Stylist
      </Link>

      {/* new rank Modal */}
    </div>
  )
}

export default TableTopBar;