import React, { useState, useEffect } from "react";
import './styles.css';

import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';

import {Form,Col} from 'react-bootstrap';

import BootstrapTable from "react-bootstrap-table-next";

import { connect } from 'react-redux';

import TableTopBar from './Components/TableTopBar/TableTopBar';
import SearchCustomer from "../../commonComponents/SearchCustomer/SearchCustomer"

import { columns } from './tableOptions';
import { getTableRowData } from './helpers/helpers';
import { getInitalCustomers, getPageWiseCustomers, saveConsumer } from './crud';

import swal from 'sweetalert';


const UserListing = (props) => {
  const { currentSalon: { salon: { uuid: currentSalonuuid } } } = props.auth;

  const [loading, setLoading] = useState(true);
  const [tableRowData, setTableRowData] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [touched, setTouched] = useState(false)

  async function getData(num) {
    try {
      setTableRowData([])
      const response = await getInitalCustomers(currentSalonuuid, num);
      console.log(response)
      const customers = getTableRowData(response.data.value);

      setPaginationData({
        pageSize: response.data.page_size,
        totalPages: response.data.total_pages,
        totalRecords: response.data.total_records
      })

      setTableRowData(customers);
      if (customers.length > 0) {
        setTouched(true)
      } else {
        setTouched(false)
      }
      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, [currentSalonuuid]);

  async function getPageData(page) {
    try {
      setTableRowData([])
      const response = await getPageWiseCustomers(currentSalonuuid, page);
      console.log(response)
      const customers = getTableRowData(response.data.value);

      setTableRowData(customers);


    } catch (err) {
      console.log(err);
    }
  }

  const handlePageChange = (e, v) => {
    if (v === 1) {
      getData()
    } else {
      getPageData(v - 1)
    }
    setCurrentPage(v)
  }

  async function createCustomer(values) {
    const payload = {
      name_first: values.firstName,
      name_last: values.lastName,
      mobile: values.phone,
      email: values.email,
      salon_uuid: currentSalonuuid,
      read: true
    };
    try {
      const response = await saveConsumer({ payload });
      console.log(response)
      swal("Success!", "New Customer created!", "success");
      getData()
    } catch (e) {
      swal("Error!", "Please try again!", "error");
      console.log(e)
    }
  }

  if (loading) return <h1>Loading</h1>
  return (
    <div className="UserListing">
      <Paper className="UserListing-top-container">
        <TableTopBar createCustomer={createCustomer} />

        <Form.Row>
          <Col md={6} className="px-5">
            <SearchCustomer searchCustomer={getData} length={4} />
          </Col>
        </Form.Row>

        <div className="UserListing-table-container">

          <BootstrapTable
            keyField="uuid"
            data={tableRowData}
            columns={columns}
            classes="UsersTable"
            bordered={false}
            bootstrap4={true}
            noDataIndication={() => <h1>{touched ? "Loading" : "No Data"}</h1>}
          />
          <div className="d-flex justify-content-between">
            <p>Showing {tableRowData.length ? (currentPage * 10) - 9 : "0"} to  {((currentPage - 1) * 10) + tableRowData.length} of {paginationData.totalRecords}
            </p>
            <Pagination
              count={paginationData.totalPages}
              shape="rounded"
              color="secondary"
              page={currentPage}
              onChange={handlePageChange}
            />

          </div>
        </div>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(UserListing);
