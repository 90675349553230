import React from 'react'
import { Modal, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import SearchBox from '../../../commonComponents/searchBox'

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
	},
	closeIcon: {
		position: 'absolute',
		right: '10px',
		fontSize: '20px',
		border: 'none',
		color: '#c6c6c8',
	},
	title: {
		fontSize: '20px',
		fontWeight: 500,
		letterSpacing: 0,
		lineHeight: '30px',
		padding: theme.spacing(2),
	}
}));


function SelectItem({ open, handleClose }) {
	const classes = useStyles();
	return <Modal open={open} onClose={handleClose} onEscapeKeyDown={handleClose} className="d-flex align-items-center justify-content-center">
		<Paper className={classes.paper}>
			<div className="d-flex align-items-center justify-content-center">
				<Typography className={classes.title} align="center">Select Item</Typography>
				<button className={classes.closeIcon} aria-label="Close" onClick={handleClose}>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="w-100">
				<SearchBox placeholder="Search Service" />
			</div>
		</Paper>
	</Modal>
}

export default SelectItem