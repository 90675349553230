import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './StylistListing.css';

import {connect} from "react-redux";

import BootstrapTable from 'react-bootstrap-table-next';
import Paper from '@material-ui/core/Paper';

import TableTopBar from './Components/TableTopBar/TableTopBar';
import SelectOperations from './Components/SelectOperations/SelectOperations';

import { getTableRowData,sortCaret } from './helpers/helpers';
import { actionFormatter, categoryFormatter, statusFormatter, nameFormatter } from './helpers/formatters';
import { getAllInputData } from './crud.js';

const StylistListing = (props) => {

  const {currentSalon:{salon:{uuid:currentSalonuuid}}} = props.auth;
  
  const [loading, setLoading] = useState(true);
  const [TableRowData, setTableRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([])

  async function getData() {
    try {
      setLoading(true);
      const response = await getAllInputData(currentSalonuuid);
      const stylists = getTableRowData(response.data.data);

      setTableRowData(stylists);
      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, [currentSalonuuid]);

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row.uuid]);
    } else {
      const selected = selectedRows.filter(x => x !== row.uuid);
      setSelectedRows(selected);
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const selected = rows.map(r => r.uuid);
    if (isSelect) {
      setSelectedRows(selected);
    } else {
      setSelectedRows([]);
    }
  }

  const columns = [{
    dataField: 'name',
    text: 'Name',
    sort: true,
    sortCaret:sortCaret,
    formatter:nameFormatter,
    headerClasses: () => "StylistListingName-width"
  }, {
    dataField: 'phone',
    text: 'Phone no.'
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    sortCaret:sortCaret,
    formatter: statusFormatter,
    headerClasses: () => "StylistListingStatus-width",
  },
  // {
  //   dataField: 'genderServed',
  //   text: 'Gender Served',
  //   sort: true,
  // },
  {
    dataField: 'rank',
    text: 'Rank',
    sort: true,
    sortCaret:sortCaret,
  },
  {
    dataField: 'personnelCategory',
    text: 'Category',
    sort: true,
    sortCaret:sortCaret,
    formatter: categoryFormatter,
    headerClasses: () => "StylistListingCategory-width",
  },
  // {
  //   dataField: 'avgRating',
  //   text: 'Rating',
  //   sort: true,
  // },
  {
    dataField: 'timeIn',
    text: 'Time In',
    sort: true,
    sortCaret:sortCaret,
  },
  {
    dataField: 'timeOut',
    text: 'Time Out',
    sort: true,
    sortCaret:sortCaret,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    formatter: actionFormatter,
  }];

  const selectRow = {
    mode: 'checkbox',
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };


  if (loading) return <h1>Loading</h1>

  return (
    <div className="StylistListing">
      <Paper className="StylistListing-top-container">
        <TableTopBar />

        <div className="StylistListing-table-container">

          {selectedRows.length > 0 &&
            <SelectOperations selectedRows={selectedRows} />
          }

          <BootstrapTable
            bordered={false}
            bootstrap4
            keyField='uuid'
            classes="StylistsTable"
            data={TableRowData}
            columns={columns}
            selectRow={selectRow}
          />
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(StylistListing);