import React, { useState, useEffect } from 'react';
import './NewDeal.css';

import { connect } from 'react-redux';

import { useFormik } from 'formik';
import { initialValues, validationSchema } from './formSchema';
import { getDealPayload, structureDataForForm } from './helpers';

import Editor from './Components/Editor/Editor';
import DealForm from './Components/form';

import { Button } from "react-bootstrap";

import { getRequiredData, getToken, createDraftDeal, updateDeal } from './crud';

import swal from 'sweetalert';



const NewDeal = (props) => {
  const { currentSalon: { salon: { uuid: currentSalonuuid } } } = props.auth

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [services, setServices] = useState([{ id: 1234, service: "", variation: "", sessions: "", fullVal: "" }])
  const [requiredData, setRequiredData] = useState();
  const [dealuuid, setDealuuid] = useState(null)
  const [imdToken, setImdToken] = useState(null)


  useEffect(() => {
    async function GetRequiredData() {
      try {
        setLoading(true);
        const response = await getRequiredData(currentSalonuuid);
        const data = structureDataForForm(response.data.data);
        setRequiredData(data);
        setLoading(false);
      } catch (err) {
        console.log(err)
      }
    }
    GetRequiredData();
  }, [])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let payload
      let response
      try {
        if (step === "update") {
          payload = getDealPayload(values, services, requiredData, currentSalonuuid, imdToken, step);
        }
        else if (imdToken) {
          payload = getDealPayload(values, services, requiredData, currentSalonuuid, imdToken);
        } else {
          const token = await getToken();
          setImdToken(token.data.data.imd_token)
          payload = getDealPayload(values, services, requiredData, currentSalonuuid, token.data.data.imd_token);
        }
        console.log(payload)

        if (step === "update") {
          response = await updateDeal(dealuuid, payload)
          swal("Success!", "Deal Updated!", "success");
        } else {
          response = await createDraftDeal(payload)
          setDealuuid(response.data.data.uuid)
          swal("Success!", "Draft deal created!", "success");
        }

        setStep(2);
      } catch (err) {
        console.log(err);
        swal("Error!", "Please try again!", "error");
      }
    },
  });

  const handleStep = async (val) => {
    if (val === 2) {
      let isEmpty = services.some(service => (service.service === "" || service.sessions === ""));
      isEmpty = services.length === 0 || isEmpty;
      if (isEmpty) {
        await formik.setFieldValue("isEmpty", "");

        formik.handleSubmit();
      }
      else {
        await formik.setFieldValue("isEmpty", "hey");
        formik.handleSubmit();
      }

    }
    else {
      setStep(val);
    }

  }

  if (loading) {
    return <h1>Loading</h1>
  }

  return (
    <div className="NewDeal">
      <div className="NewDeal-header">
        <p>New Deal</p>
        <span className="NewDeal-cross" onClick={() => props.history.push('./deals')}>
          <i className="quick-search-close ki ki-close icon-sm text-muted" ></i>
        </span>

      </div>

      <div className="NewDeal-body">
        {
          (step === 1 || step === "update") &&
          <DealForm values={formik.values} errors={formik.errors} touched={formik.touched} handleChange={formik.handleChange} handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue} services={services} setServices={setServices}
            requiredData={requiredData}
          />
        }
        {
          (step === 2 && dealuuid) &&
          <Editor heading={formik.values.title} description={formik.values.description} price={formik.values.price} tags={formik.values.applicableTags} dealuuid={dealuuid} handleStep={handleStep} />
        }
      </div>

      {
        (step === 1 || step === "update") &&
        <div className="NewDeal-footer">
          <Button onClick={() => handleStep(2)}>Next</Button>
        </div>
      }

    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps)(NewDeal);