import * as Yup from "yup";
import moment from "moment";

export const formSchema = {
  1: Yup.object().shape({
    name: Yup.string().required("Please enter your Brand name"),
    businessType: Yup.string().required(),
    servedGender: Yup.string().required(),
    managerName: Yup.string().required("Please enter the Manager name"),
    managerPhone: Yup.string()
      .matches(/\+?\d[\d -]{8,12}\d/, "Must be a valid phone number")
      .required("Must be a valid phone number"),
    managerEmail: Yup.string()
      .email("Must be a valid email address")
      .required("Must be a valid email address"),
    address: Yup.string().required("Must be a valid address"),
    locState: Yup.string().required(),
    locCity: Yup.string().required(),
    locMicroMarket: Yup.string().required(),
  }),
  2: Yup.object().shape({
    openingTime: Yup.date()
      .required()
      .test(
        "multiples-of-five",
        "Minutes should be multiples of 5",
        function() {
          const { openingTime } = this.parent;
          return moment(openingTime).minutes() % 5 === 0;
        }
      ),
    closingTime: Yup.date()
      .required()
      .test(
        "is-greater",
        "Closing time must be greater than Opening time",
        function() {
          const { openingTime, closingTime } = this.parent;
          return moment(closingTime).isSameOrAfter(moment(openingTime));
        }
      )
      .test(
        "multiples-of-five",
        "Minutes should be multiples of 5",
        function() {
          const { closingTime } = this.parent;
          return moment(closingTime).minutes() % 5 === 0;
        }
      ),
    operationalDays: Yup.array()
      .min(1)
      .required(),
    amenities: Yup.array()
      .min(1)
      .required(),
  }),
  3: Yup.object().shape({
    registeredBusinessName: Yup.string().required(
      "Please enter your Registered Business name"
    ),
    registeredBusinessAddress: Yup.string().required(
      "Please enter your Registered Business address"
    ),
    registeredBusinessLocState: Yup.string().required(),
    registeredBusinessLocCity: Yup.string().required(),
    pan: Yup.string()
      .length(10, "PAN must be exactly 10 characters")
      .required("Please enter your Registered Business PAN"),
    gst: Yup.string()
      .length(15, "GST must be exactly 15 characters")
      .required("Please enter your Registered Business GST"),
  }),
  4: Yup.object().shape({
    businessLogo: Yup.array()
      .min(1)
      .max(1)
      .required(),
    salonInteriorImages: Yup.array()
      .min(10)
      .max(10)
      .required(),
  }),
};
