import React from "react";
import {
  Grid,
  Typography,
  Divider,
  styled,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";

const Seperator = styled(Divider)({
  backgroundColor: "#5072DF",
  width: "4px",
});

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    color: "#868686",
  },
}));

function ServiceDetailCard({ item }) {
  const styles = useStyles();
  return (
    <Grid container className="d-flex justify-content-between">
      <Grid item xs={2} className="d-flex flex-column justify-content-center">
        <Typography variant="h6" className="font-weight-bold">
          {moment(item.basic.instant.start).format("hh:mm A")}
        </Typography>
        <Typography variant="body2" className="text-muted">
          {moment(item.basic.date).format("D MMMM YYYY")}
        </Typography>
      </Grid>
      <Seperator orientation="vertical" flexItem className="mx-2" />
      <Grid item xs={7}>
        <Typography variant="h6">{item.service.name}</Typography>
        <Typography variant="body1" className={styles.secondaryText}>
          {" "}
          {item.service.duration_minutes} mins
        </Typography>
        <Typography variant="body1" className={styles.secondaryText}>
          with {item.personnel.basic.name_first}{" "}
          {item.personnel.basic.name_last}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className="d-flex flex-column justify-content-center align-items-end"
      >
        <Typography variant="body1">
          &#x20B9; {item.service.price.price_net}
        </Typography>
        <Typography variant="body2" className="text-muted">
          (incl.taxes)
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ServiceDetailCard;
