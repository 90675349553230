import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Toolbar,
  makeStyles,
  Modal,
  Box,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PaymentSection from "./paymentSection";
import CheckoutCart from "./checkout-cart";
import {
  checkoutRequiredData,
  availablePaymentOptions,
  generateOrder,
  orderDetails,
  getIMDtokenForPayment,
  createIMDtoken,
  enterPaymentInfo,
  completeOrder,
  addService as addMoreService,
  removeService as removeMoreService,
} from "../Appointment.service";
import PaymentConfirmation from "./components/paymentConfirmation";
import CubeGridLoader from "../../../commonComponents/cubeGridLoader";
import swal from 'sweetalert';

import { getOrderPayload } from "./helpers";

const useStyles = makeStyles((theme) => ({
  overlayPaymentConfirmation: {
    backgroundColor: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentContainer: {
    height: "calc(100% - 64px)",
  },
}));

function CheckoutComponent({ closeSale, auth }) {
  const [selectedConsumer, setselectedConsumer] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentToken, setPaymentToken] = useState(null)
  const [orderInfo, setOrderInfo] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [payingAmount, setPayingAmount] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [showActivityIndicator, setShowActivityIndicator] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [price, setPrice] = useState({
    base: 0,
    tax: 0,
    paid: 0,
  });
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();

  const { currentSalon } = auth;

  const {
    salon: { uuid: salonUUID },
  } = currentSalon;

  useEffect(() => {
    getRequiredData({ salonUUID });
    getPaymentOptions();
  }, []);

  useEffect(() => {
    console.log(location, "this is location")
    if (location.pathname.includes("/checkout")) {
      const {
        state: { appointmentDetails = {} },
      } = location;
      getOrderDetails({ orderId: appointmentDetails.uuid });
      setAppointmentDetails(appointmentDetails);
      setselectedConsumer(appointmentDetails.consumer)
    }
  }, [location]);

  // useEffect(() => {
  //   if (updateOrders) {
  //     getOrderDetails();
  //   }
  // }, [updateOrders]);

  function handleClose(isSuccess) {
    setSelectedServices([]);
    setselectedConsumer(null);
    setOrderInfo({});
    setPrice({
      base: 0,
      tax: 0,
      paid: 0,
    });
    if (isSuccess) {
      history.replace("/appointments");
    }
  }

  async function getPaymentOptions() {
    try {
      const {
        data: { data },
      } = await availablePaymentOptions();
      setPaymentOptions(data);
      console.log(data);
    } catch (e) {
      setPaymentOptions([]);
    }
  }

  async function getRequiredData({ salonUUID }) {
    const {
      data: { services },
    } = await checkoutRequiredData({ salonUUID });
    setServices(services);
  }

  async function getOrderDetails({ orderId }) {
    setShowActivityIndicator(true);
    try {
      const { data } = await orderDetails({ orderId });
      setSelectedServices(
        data.items.filter((item) => item.state !== "CANCELLED")
      );
      const orderItems = data.items.map((item) => {
        item.stylist = item.appointment;
        item.stylist = item.appointment.personnel;
        return item;
      });
      // setOrderItems(orderItems);
      setOrderInfo(data);
      // setUpdateOrders(false);
    } catch (e) {
      return {};
    }
    setShowActivityIndicator(false);
  }

  function getApiToUpdateOrders(type) {
    const isLocationCheckout = location.pathname.includes("checkout");
    if (isLocationCheckout) {
      return type === "remove" ? removeMoreService : createOrder;
    }
    return type === "add" ? removeMoreService : createOrder;
  }

  async function removeService(service) {
    if (location.pathname.includes("checkout")) {
      const { uuid: orderId } = appointmentDetails;
      const payload = {
        service_uuid: service.service.uuid,
        variation_uuid: service.service.variations.length
          ? service.service.variations[0].uuid
          : null,
        read: true,
      };
      try {
        const {
          data: { data },
        } = await removeMoreService({ payload, orderId });
        setOrderInfo(data);
        setSelectedServices(
          data.items.filter((item) => item.state !== "CANCELLED")
        );
      } catch (e) { }
    } else {

      setSelectedServices(selectedServices.filter(s => s.id !== service.id));
    }
  }

  async function addService(service) {
    if (location.pathname.includes("checkout")) {
      const { uuid: orderId } = appointmentDetails;
      const payload = {
        service_uuid: service.service.uuid,
        variation_uuid: service.variation?.uuid || null,
        personnel_uuid: service.appointment.personnel.uuid,
        platform: "WEB_DASHBOARD",
        read: true,
      };
      try {
        const {
          data: { data },
        } = await addMoreService({ payload, orderId });
        setOrderInfo(data);
        setSelectedServices(
          data.items.filter((item) => item.state !== "CANCELLED")
        );
      } catch (e) {
        console.log(e)
       }
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  }

  async function createOrder(token, consumeruuid) {
    console.log(salonUUID);
    const payload = getOrderPayload(
      token,
      consumeruuid,
      "839bec35-8989-459d-9733-b463c8cc4dba",
      selectedServices
    );
    try {
      const response = await generateOrder(payload);
      const orderResponse = await orderDetails({ orderId: response.data.uuid });
      setOrderInfo(orderResponse.data);
      setPrice({
        base: orderResponse.data.basic.price.price_base,
        tax: orderResponse.data.basic.price.price_gst,
        paid: orderResponse.data.basic.paid_amount,
      });

      history.replace("/appointments/checkout", {
        appointmentDetails: orderResponse.data,
      })
    } catch (err) {
      console.log(err);
    }
  }

  async function getIMDToken() {
    const orderId = orderInfo.uuid;
    try {
      const {
        data: {
          data: { imd_token },
        },
      } = await getIMDtokenForPayment({ orderId });
      return imd_token;
    } catch (e) {
      return null;
    }
  }

  async function onPayment(amount, paymentOption) {
    setPayingAmount(amount);
    setPaymentOption(paymentOption);
    setShowConfirmation(true);
  }

  async function confirmPayment() {
    setShowConfirmation(false)
    setShowActivityIndicator(true);
    let IMDtoken;
    if (paymentToken !== null) {
      IMDtoken = paymentToken;
      console.log("using previous token")
    } else {
      IMDtoken = await getIMDToken();
      console.log("getting new token")
      setPaymentToken(IMDtoken)
    }

    const payload = {
      imd_token: IMDtoken,
      specialized_payment_option: paymentOption.code,
      order_uuid: orderInfo.uuid,
      amount: payingAmount,
      reference_id: "1",
      read: true,
    };
    console.log(payload, "on payment");
    try {
      await updatePayment(payload);
      setPaymentToken(null);
      swal("Success!", "Payment Successfull!", "success");
    } catch (err) {
      swal("Error!", "Please try again!", "error");
      setShowConfirmation(true)
    }

    setShowActivityIndicator(false);
  }

  async function updatePayment(payload) {
    try {
      const response = await enterPaymentInfo({ payload });
      console.log(response, "updatePayment");
      setOrderInfo(response.data.data);
      setPrice({
        base: response.data.data.basic.price.price_base,
        tax: response.data.data.basic.price.price_gst,
        paid: response.data.data.basic.paid_amount,
      });
      setselectedConsumer(response.data.data.consumer);
    } catch (e) {
      throw e
    }
  }

  async function confirmSale() {
    console.log(orderInfo.items, "these are order items");
    try {
      for (let service of orderInfo.items) {
        const { uuid: appointmentId } = service.appointment;
        console.log(appointmentId);
        const response = await completeOrder({ appointmentId });
      }
      handleClose("Success");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      open={
        location.pathname.includes("create-sale") ||
        location.pathname.includes("checkout")
      }
    >
      <Box className="bg-white h-100 d-flex flex-column">
        <Toolbar className="d-flex justify-content-between border-bottom">
          <Typography variant="h6" className="flex-grow-1 text-center">
            Checkout
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent
          className={`d-flex p-0 overflow-hidden ${classes.contentContainer}`}
        >
          {showActivityIndicator ? (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <CubeGridLoader />
            </div>
          ) : (
              <Row className="flex-grow-1 h-100" noGutters>
                <Col sm={7} className="d-flex flex-row flex-grow-1 h-100">
                  <CheckoutCart
                    services={services}
                    selectedServices={selectedServices}
                    addService={addService}
                    removeService={removeService}
                    price={orderInfo.basic?.price}
                    orderInfo={orderInfo}
                  />
                </Col>
                <Col
                  sm={5}
                  className="flex-grow-1 border-left p-2 d-flex"
                  style={{
                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  <PaymentSection
                    selectedServices={selectedServices}
                    paymentOptions={paymentOptions}
                    createOrder={createOrder}
                    onPayment={onPayment}
                    selectedConsumer={selectedConsumer}
                    setselectedConsumer={setselectedConsumer}
                    price={orderInfo.basic?.price}
                    onConfirmSale={confirmSale}
                    // selectedOrder={selectedOrder}
                    orderInfo={orderInfo}
                  // isPaymentComplete={
                  //   orderInfo ? orderInfo.basic.payment_state === "PAID" : false
                  // }
                  />
                </Col>
              </Row>
            )}
        </DialogContent>
        {showConfirmation ? (
          <div>
            <PaymentConfirmation
              toClose={() => setShowConfirmation(false)}
              show={showConfirmation}
              onConfirmPress={confirmPayment}
              payingAmount={payingAmount}
              paymentOption={paymentOption}
            />
          </div>
        ) : null}
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(CheckoutComponent);
