import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Divider } from "@material-ui/core";

import moment from 'moment'

import { Close as CloseIcon, Add as AddIcon, Ru } from "@material-ui/icons";

import SelectItem from "./selectItem";
import SelectedServices from "./components/SelectedServices";

import { convertToRupee } from "../../../../_metronic/_helpers";

const useStyles = makeStyles({
  card: {
    width: "100%",
    border: "1px solid #E7E9F5",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 4px 0 #E7E9F5 !important",
    marginBottom: "15px",
  },
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "10px",
  },
  addCTA: {
    borderRadius: "4px",
    backgroundColor: "#FDE4CE",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "15px",
    color: "#EE750F",
    width: "155px",
  },
  closeButton: {
    minWidth: "auto",
  },
  rupeeSymbolInput: {
    boxSizing: "border-box",
    height: "39px",
    width: "39px",
    border: "1px solid #E7E9F5",
    borderRadius: "4px",
    backgroundColor: "#F7F8FA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  priceTableRow: {
    display: "flex",
  },
  date:{
    alignSelf: "flex-start",
    backgroundColor: "#F2F3F7",
    padding: "10px 15px",
    borderRadius: "5px",
    fontWeight: 500
  }
});

function CheckoutCart({
  services,
  addService,
  selectedServices,
  removeService,
  price,
  orderInfo,
}) {
  
  const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [orderPrice, setOrderPrice] = useState({});
  const classes = useStyles();

  function onClickOfAdd() {
    setOpenModal(true);
  }

  useEffect(() => {
    setItems(selectedServices);
  }, [selectedServices]);

  // useEffect(() => {
  //   if (orderInfo) {
  //     setOrderPrice({...orderInfo.basic.price,paid:orderInfo.basic.paid_amount});
  //   }
  // }, [orderInfo]);

  return (
    <div className="w-100 p-8 d-flex align-items-center flex-column h-100">
      <div className={classes.date}>
        
        {
        moment(new Date()).format(
        "dddd D MMMM, YYYY"
      )}

      </div>
      <SelectedServices items={items} removeService={removeService} />

      { items.length === 0 &&
        <div>
          <img src="/media/shopping-bag.svg" height="150" className="mt-10 mb-5" />
        </div>
      }


      <div className={classes.footer}>
        <div className={`col p-0 d-flex`}>
          <div className={`col ${items.length === 0 ? "text-center" : "text-right"}`}>
            <Button
              onClick={onClickOfAdd}
              startIcon={<AddIcon />}
              className={classes.addCTA}
            >
              <Typography variant="button">
                {items.length ? "Add more services" : "Add services"}
              </Typography>
            </Button>
          </div>
          {items.length && orderInfo.basic?.price ? (
            <div className="col">
              <Typography className="mb-5 d-flex justify-content-between priceTableRow">
                <span className="col d-flex justify-content-end">Base:</span>{" "}
                <span className="col d-flex justify-content-end">
                  {convertToRupee(orderInfo.basic?.price.price_base)}
                </span>
              </Typography>
              <Typography className="mb-5 d-flex justify-content-between priceTableRow">
                <span className="col d-flex justify-content-end">Tax:</span>{" "}
                <span className="col d-flex justify-content-end">
                  {convertToRupee(orderInfo.basic?.price.price_gst)}
                </span>
              </Typography>
              <Divider className="mb-5" />
              <Typography className="mb-5 d-flex justify-content-between priceTableRow">
                <span className="col d-flex justify-content-end">Total:</span>{" "}
                <span className="col d-flex justify-content-end">
                  {convertToRupee(orderInfo.basic?.price.price_net)}
                </span>
              </Typography>
              <Typography className="mb-5 d-flex justify-content-between priceTableRow">
                <span className="col d-flex justify-content-end">
                  <b>Paid:</b>
                </span>{" "}
                <span className="col d-flex justify-content-end">
                  {convertToRupee(orderInfo.basic?.paid_amount)}
                </span>
              </Typography>
              <Divider className="mb-5" />
              <Typography className="mb-5 d-flex justify-content-between priceTableRow">
                <span className="col d-flex justify-content-end">
                  <b>Balance:</b>
                </span>{" "}
                <span className="col d-flex justify-content-end">
                  {convertToRupee(
                    orderInfo.basic?.price.price_base +
                      orderInfo.basic?.price.price_gst -
                      orderInfo.basic?.paid_amount
                  )}
                </span>
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
      <SelectItem
        open={openModal}
        handleClose={() => setOpenModal(false)}
        services={services}
        addService={addService}
      />
    </div>
  );
}

export default CheckoutCart;
