import React from 'react';
import './ProfileCard.css';
import { Row, Col, Media, Button } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';

import moment from "moment"

const ProfileCard = ({ user, customerSince, app }) => {
  console.log(user)
  const { name_first, name_last, email, mobile } = user
  return (
    <Paper className="ProfileCard pt-3 pb-5 px-5">

      <div className="text-right">
        <i className="fas fa-ellipsis-h"></i>
      </div>

      <div className="ProfileCard-body">
        <Media>
          <img
            width={80}
            height={80}
            className="mr-3 rounded"
            src="/media/img-1.jpg"
            alt="Generic placeholder"
          />
          <Media.Body >
            <h5>{name_first} {name_last}</h5>
            {
              app ?
                <i className="fas fa-check-circle fa-2x" style={{ color: "#60DAA8" }}></i>
                : null
            }

            {/* <p className="text-muted">Male</p> */}
          </Media.Body>
        </Media>

        <div className="ProfileCard-details py-5">
          <div className="d-flex justify-content-between">
            <span>Email:</span>
            <p className="ProfileCard-email">{email}</p>
          </div>
          <div className="d-flex justify-content-between">
            <span>Phone:</span>
            <span>{mobile}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Customer since:</span>
            <span>
              {customerSince ?
                moment(customerSince.slice(0, 10)).format('DD MMM, YYYY')
                : "No data"}
            </span>
          </div>
        </div>
        <Button variant="primary" size="lg" block>Create Appointment</Button>
      </div>

    </Paper>
  )
}

export default ProfileCard;